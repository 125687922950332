import { ReactNode, ReactElement } from "react";
import package_json from "../../package.json";
import Intercom from "helpers/Intercom";

export const VERSION = package_json.version.split(".").splice(0, 2).join(".");

//TODO: Add DotProps from recharts types
export type DotProps = any;

export type MarketingCampaignType = {
  title: string;
  key: string;
  description: string;
  icon: string;
  link: string;
  helperContent?: JSX.Element;
  comingSoon?: boolean;
  category?: 'releases' | 'fanbase' | 'traffic'
  newTag?: boolean;
  underMaintenance?: boolean;
  statusMessage?: string;
  isConversionsCampaign?: boolean;
  backgroundColor?: string;
  walkthroughVideoUrl?: string;
  requiresPro: boolean;
}

// TypeScript type to represent the selected post data structure
export type SelectedTikTokSparkAd = {
  auth_info: {
    auth_end_time: string;
    auth_start_time: string;
  };
  user_info: {
    tiktok_name: string;
    identity_type: string;
    identity_id: string;
  };
  item_info: {
    text: string;
    anchor_list: null | any; // replace 'any' with a more specific type if known
    stitch_original_item_id: string;
    auth_code: string;
    duet_original_item_id: string;
    item_id: string;
    is_multi_duet_stitch: boolean;
    mentioned_item_ids: null | any; // replace 'any' with a more specific type if known
  };
  video_info: {
    file_name: string;
    poster_url: string;
    bit_rate: number;
    size: number;
    width: number;
    height: number;
    signature: string;
    duration: number;
    preview_url: string;
    video_id: string;
  };
};

export interface SpotifyArtistsOption {
  id: string;
  name: string;
  followers: number | null;
  genres: string[] | null;
  thumbnail_url: string | null;
}

export type FBLocation = {
  key: string
  name: string
  type: string
  country_code: string
  country_name: string
  region: string
  region_id: number
  supports_city: boolean
}

export type LocationsResponse = {
  data: FBLocation[]
}

export type Location = {
  value: string
  label: string
  type: string
  country_code: string | null
  region_id: string | null
}

export type GeographicTargetingType = {
  id?: string
  name: string
  budget: number
  description?: string
  locations: { [key: string]: Location }
}

export interface SpotifyArtistsOption {
  id: string;
  name: string;
  followers: number | null;
  genres: string[] | null;
  thumbnail_url: string | null;
}

export type AreaDot =
  | ReactElement<SVGElement>
  | ((props: any) => ReactElement<SVGElement>)
  | ((props: any) => ReactElement<SVGElement>)
  | DotProps
  | boolean;

export type IObjectKeys = { [key: string]: any };
export type TooltipType = "none";
export type ValueType = number | string | Array<number | string>;
export type NameType = number | string;

export type Formatter<TValue extends ValueType, TName extends NameType> = (
  value: TValue,
  name: TName,
  item: Payload<TValue, TName>,
  index: number,
  payload: Array<Payload<TValue, TName>>
) => [ReactNode, ReactNode] | ReactNode;
export interface Payload<TValue extends ValueType, TName extends NameType> {
  type?: TooltipType;
  color?: string;
  formatter?: Formatter<TValue, TName>;
  name?: TName;
  value?: TValue;
  unit?: ReactNode;
  dataKey?: string | number;
  payload?: IObjectKeys;
  chartType?: string;
}

export interface BrandContent {
  brand_id: number;
  primary_customization?: {
    bgType: string;
    bgColor: string;
    buttonConfig: string;
    primaryColor: string;
    secondaryColor: string;
  };
  brand_preset?: {
    enableSocialIcons?: boolean;
    socialIcons: WebsiteSocialMediaIcon[];
    removeSymphonyBrand?: boolean;
    addAffiliateLinkToLogo?: boolean;
  }
  campaign_id?: number | string;
  content_metadata: ContentMetadata;
  id: number;
  name: string;
  slug: string;
  type: string;
  url: string;
  updatedAt: string;
  createdAt: string;
}

export interface ContentMetadata {
  publishReason?: string;
  published?: boolean;
  explicit: boolean;
  external_ids: Record<string, unknown>;
  external_links: ExternalLink[];
  name: string;
  preview_url: string;
  primary_artist: string;
  record_url: string;
  release_date: string;
  spotify_id: string;
  spotify_release_type: string | null;
  spotify_uri: string;
  thumbnail_url: string;
  type: string;
  updateCount: number;
  total_tracks?: number;
  tracks?: Track[]
  website?: {
    removeSymphonyBrand?: boolean;
    style?: any; // Replace 'any' with the actual style type if available
    fields?: Field[];
    tourDates?: TourDate[];
    hidePastTourDates?: boolean;
  };
  presave?: {
    saved_at?: string;
    style: {
      bgType: string;
      bgColor: string;
      buttonConfig: string;
      primaryColor: string;
      secondaryColor: string;
    };
    boosts: {
      auto_presaves: boolean;
      boost_follows: boolean;
      collect_emails: boolean;
    };
    options: {
      timezone: string;
      redirect_url: string;
      redirect_to_custom_webpage: boolean;
    };
    subtitle: string;
    platforms: string[];
    release_url_upc_isrc: string;
  };
};

export interface ExternalLink {
  url: string;
  name: string;
  enabled: boolean;
  type: string;
}

export interface Field {
  id: string,
  label: string,
  priority: number,
  required: boolean,
  reserved: boolean,
}

export interface TourDate {
  city: string;
  country: string;
  date: string;
  displayName?: string;
  isEnableEmailNumberCollection?: boolean;
  isPreSalePeriod?: boolean;
  preSaleAction?: 'collect' | 'link';
  isSoldOut?: boolean;
  region: string;
  ticketUrl: string;
  venue: string;
}

export interface SpotifyGrowth {
  growthData: GrowthData;
}

export interface EmailGrowth {
  viewsGrowth: GrowthData;
}

export enum ConnectorIndicatorTypes {
  SPOTIFY_FOLLOWERS = "SPOTIFY_FOLLOWERS",
  SPOTIFY_PLAYLIST_FOLLOWERS = "SPOTIFY_PLAYLIST_FOLLOWERS",
  SPOTIFY_STREAMS = "SPOTIFY_STREAMS",
  INSTAGRAM_FOLLOWERS = "INSTAGRAM_FOLLOWERS",
  SHAZAMS = "SHAZAMS",
  INSTAGRAM_POST_REACH = "INSTAGRAM_POST_REACH",
  INSTAGRAM_POST_TOTAL_INTERACTIONS = "INSTAGRAM_POST_TOTAL_INTERACTIONS",
  INSTAGRAM_PROFILE_REACH = "INSTAGRAM_PROFILE_REACH",
  INSTAGRAM_PROFILE_VISITS = "INSTAGRAM_PROFILE_VISITS",
  NONE = "NONE",
}

export enum Platforms {
  followers = "followers",
  streams = "streams",
}

export enum RecordType {
  record = "record",
  project = "project",
}

export type ShazamConnectionData = {
  url: string;
  name: string;
};

export type FollowersChartData = {
  datetime: string;
  fanbase_count: string;
  platform: string;
  platform_identifier: string;
  percentageGrowth: number;
};

export type StreamsChartData = {
  datetime: string;
  platform: string;
  platform_identifier: string;
  views_stats: number | null;
  comments_stats: number | null;
  likes_stats: number | null;
  views_change: number | null;
};

export type GrowthData = {
  currentGrowthValue: string;
  difference: number;
  percentage: number;
};

export type ViewsGrowth = GrowthData;

/**
 * Used when referring to RecordSelector objects (either a single record, a project, or a playlist)
 */
export type RecordSelectorType = 'single' | 'project' | 'playlist'

export type FollowersData = {
  data: {
    platform: string;
    stat: string;
    chartData: FollowersChartData[];
    growthData: GrowthData;
  };
  isError: boolean;
  message: string | null;
};

export type StreamsData = {
  data: {
    data: StreamsChartData[];
    viewsGrowth: ViewsGrowth;
    platform: string;
    stat: string;
  };
  isError: boolean;
  message: string | null;
};

export enum TooltipSubtitles {
  Followers = "Followers",
  Streams = "Streams",
  Shazams = "Shazams",
}

export interface ImpactChartData {
  type: ConnectorIndicatorTypes;
  day: string;
  count: number;
  date: string;
  campaignStartDate: string;
  valueOf: number;
}

export enum AutoCompleteCloseReasons {
  toggleInput = "toggleInput",
  escape = "escape",
  selectOption = "select-option",
  blur = "blur",
  removeOption = "remove-option",
}

export enum AutoCompleteChangeReasons {
  createOption = "create-option",
  selectOption = "select-option",
  removeOption = "remove-option",
  clear = "clear",
  blur = "blur",
}

export interface WebsiteSocialMediaIcon {
  enabled: boolean;
  rounded: boolean;
  backgroundColor: string;
  iconColor?: string;
  height: string;
  width: string;
  url: string;
  key: string;
  placeholder: string;
}


export type Website = {
  bgType: string;
  primaryColor: string;
  buttonConfig: string;
  secondaryColor: string;
  bgColor: string;
  removeSymphonyBrand?: boolean;
  enableSocialIcons?: boolean;
  socialIcons?: WebsiteSocialMediaIcon[]
  titleAlignment?: TitleAlignments;
} | null;


// Types for Connections
export interface FacebookPageType {
  access_token: string;
  category: string;
  followers_count: number;
  id: string;
  name: string;
  picture: { data: { url: string } };
}

export interface InstagramPageType {
  id: string;
  name: string;
  businessAccount: InstagramBusinessAccountType | null;
  instagramAccount: {
    follow_count: number;
    followed_by_count: number;
    id: string;
    profile_pic: string;
    username: string;
  };
  connected_instagram_account?: ConnectedInstagramAccountType | null
}

export interface AdAccountPageType {
  account_status: number;
  amount_spent: number;
  id: string;
  name: string;
  business: {
    id: string;
    name: string;
    permitted_roles: string[];
    profile_picture_uri: string;
  }
  userHasAccess?: boolean;
  currency: string;
}

export interface FBPixel {
  id: string;
  is_unavailable: boolean;
  name: string;
  google_analytics: IObjectKeys | null;
  owner_ad_account?: {
    id: string;
    name: string;
    account_id: string;
  }
}

export interface TikTokAdAccount {
  advertiser_id: string;
  advertiser_account_type: string;
  name: string;
  address: string;
  company: string;
  contacter: string;
  country: string;
  currency: string;
  description: string;
  email: string;
  industry: string;
  language: string;
  license_no: string;
  license_url: string;
  cellphone_number: string;
  rejection_reason: string;
  role: string;
  status: string;
  telephone_number: string;
  timezone: string;
  display_timezone: string;
  balance: number;
  create_time: Date;
  owner_bc_id: string;
  request_id: string;

  // optional
  accountStatusValid?: boolean,
  userHasAccess?: boolean,
  amount_spent?: number,
}

export interface Connections {
  bandsintown?: {
    id: string;
    url: string;
    name: string;
    image_url: string;
    thumb_url: string;
    tracker_count: number;
    upcoming_event_count: number;
    links: Array<{
      url: string;
      type: string;
    }>;
  };
  soundcloud?: Record<string, unknown>;
  chartmetric: { id: number };
  deezer?: DeezerArtistProfile;
  tiktok_business?: {
    connected: boolean;
    access_token?: string;
  }
  tiktok_ad_account?: TikTokAdAccount;
  facebook_ad_account: AdAccountPageType;
  facebook_business_manager: {
    id: string;
    name: string;
    permitted_roles: string[];
    profile_picture_uri: string;
  };
  facebook_page: FacebookPageType;
  facebook_pixel: FBPixel;
  instagram_page: InstagramPageType;
  business_discovery_instagram: {
    id: string;
    username: string;
  };
  reauthenticateFbUser: boolean;
  spotify_artist_page: {
    external_urls: {
      spotify: string;
    };
    followers: {
      href: string | null;
      total: number;
    };
    //TODO: Check this genres array
    genres: any[];
    href: string | null;
    id: string;
    images: {
      height: number;
      width: number;
      url: string;
    }[];
    name: string;
    popularity: number;
    type: string;
    uri: string;
  };
  //TODO: Check tiktok object
  tiktok: IObjectKeys | null;
  tiktok_pixel?: string;
  google_analytics?: string;
  youtube_channel: YouTubeChannel;
  audiomack: {
    url?: string;
    username?: string;
    id?: string;
    name?: string;
    genres?: string[];
    followers?: number;
    thumbnail_url?: string;
  }
  twitter: {
    url: string;
    name: string;
    screen_name: string;
  };
  soundcloud_artist_page: SoundcloudArtistProfile;
  logged_in_fb_user?: LoggedUser
  facebook_pixel_conversions_api_access_token?: string | null
}

export interface LoggedUser {
  id?: string;
  name?: string;
  picture?: { data: { url: string }};
  access_token: string;
  expires_at?: string | null;
  fbTokenValid: boolean;
}

export type FacebookAdAccount = {
  id: string;
  name: string;
  account_status: AdAccountStatusCodes;
  amount_spent: number;
  user_tasks?: string[];
  userHasAccess?: boolean;
  business?: {
    id: string,
    name: string
  },
  currency: string;
}

export type FacebookBusinessManager = {
  id: string;
  name: string;
  permitted_roles: string[];
  profile_picture_uri: string;
}

export interface BrandSubscriptionDetails {
  freeTrialOver: boolean;
  payment_method: string;
  recurring_interval: string;
  tier: string;
  type: string;
  status: string;
  onOldPlan?: boolean;
  allowCustomerPortal?: boolean;
  // stripe customer id
  cid?: string;

  // this is set to true when a subscription goes from past_due -> unpaid
  //  we hide the payment banner at this point in time
  retriesFailed?: boolean;
  details: {
    subscribed: boolean,
    forced: boolean,
    trialDaysLeft: number | null,
    trialOver: boolean | null
    viewSubscriptionUrl?: string | null
  } | null;
}

export type FacebookError = {
  code: string;
  name: string;
  details: string | { url: string; };
}

// Primitives
export interface CurrentBrand {
  connections: Connections;
  subscription?: BrandSubscriptionDetails
  id: number;
  content_initialized: boolean;
  dashboard_initialized: boolean;
  image: string | null;
  name: string | null;
  slug: string | null;
  website: Website;
  tier: string;
  freeTrialOver?: boolean | null;
  hideFansTab?: boolean | null;
  conversionsStatus?: ConversionsStatus;
  affiliate_details?: AffiliateDetails;
  currency?: BrandCurrency;
  showRecommendations?: boolean;
  permissionLevel: ArtistPermission;
}

export enum ConversionsTask {
  AD_ACCOUNT_CONNECTED = 'AD_ACCOUNT_CONNECTED',
  FB_PIXEL_CONNECTED = 'FB_PIXEL_CONNECTED',
  CONVERSIONS_ACCESS_TOKEN = 'CONVERSIONS_ACCESS_TOKEN',
}

export interface ConversionsStatus {
  conversions_tasks: ConversionsTask[],
  conversions_tasks_completed: boolean,
  isEventRegistered: boolean,
  isError?: boolean,
  timesVerified?: number,
  showFinishedModal?: boolean,
}

export interface PreSave {
  platforms: string[];
  style: Website,
  boosts: {
    auto_presaves: boolean;
    boost_follows: boolean;
    collect_emails: boolean;
  };
  options: {
    redirect_url: string;
    redirect_to_custom_webpage: boolean;
    timezone?: string;
  };
  release_url_upc_isrc: string;
  created_with_upc?: boolean;
  subtitle?: string | null;
  local_release?: boolean;
  hideReleaseDate?: boolean;
}

export interface CampaignMetadata {
  link: string;
  budget: string;
  campaign_platforms: string[];
  campaign_type: string;
  presave?: PreSave;
  content: {
    name: string;
    slug: string;
    url: string;
    thumbnail_url: string;
    type: string;
  };
  creative: {
    all: { url: string }[];
    apple: any;
    spotify: any;
    youtube: any;
  };
  endDate: string;
  platform_details: {
    ad_account: string;
    ad_types: {
      name: string;
      ad_ids: string[];
    }[];
    campaigns: string[];
    campaignsMapping: CampaignMapping[];
  };
  startDate: string;
  status: string;
  status_notes: any;
  campaign_state?: {
    campaign?: {
      conversions?: {
        conversionsApiAccessToken: string;
        conversionsDomain: string;
        conversionsEventName: string;
        conversionsEnabled: boolean;
      }
    },
    brand?: {
      connections?: Connections
    }
  }
}

export interface CampaignMapping {
  countries: string[];
  excluded_countries: string[];
  fbCampaign: {
    id: string;
    adsets: unknown[];
    fields: {
      bid_strategy: string;
      lifetime_budget: number;
      name: string;
      objective: string;
      pacing_type: string[];
      special_ad_categories: string;
      status: string;
    };
  };
  id: string;
  fraction: number;
  name: string;
}

//TODO: Check for creative object brands types, adsets and status_notes
export interface CampaignDetails {
  brand_id: number;
  createdAt: string;
  id: number;
  totalOfEmails?: number;
  totalOfPresaves?: number;
  emailsData?: {
    new: number;
    total: number;
  }
  presavesData?: {
    new: number;
    total: number;
  }
  foreverSavesData?: {
    new: number;
    total: number;
  }
  newFollowersData?: {
    new?: number;
    total: number;
  }

  status: string;
  campaign_metadata: CampaignMetadata;
  totalOfForeverPresaves?: number;
}

export interface CreatedCampaign {
  brand_id: number;
  campaign_metadata: Record<string, unknown>;
  createdAt: string;
  id: number;
  status: string;
  updatedAt: string;
}

export type Template = {
  name: string;
  templateId: string;
  previewUrl: string;
  timeToRenderSecs: number;
};

export type ColorPalette = {
  color1: string;
  color2: string;
  color3: string;
};

export interface JobDetails {
  jobId: string;
  status: string;
  referenceId: string;
  progress?: number | null;
  estimatedTimeToComplete?: number | null;
  links: Array<{ key: string; type: string }>;
}

export interface CreativeCampaign {
  id: number;
  status: string;
  campaign_type: string;
  content: {
    name: string;
    slug: string;
    url: string;
    thumbnail_url: string;
  };
  payment_method?: string | null;
  creativeData: {
    template: Template;
    colorPalette: ColorPalette;
  };
  jobDetails: JobDetails;
}

export type PlaylistData = {
  playlistName: string;
  playlist_followers: number;
  playlist_id: string;
};

export type PlaylistDatav2 = {
  playlistName: string;
  playlistFollowers: number;

  playlistStatus?: string;
  playlistUrl?: string;
  playlistPosition?: number;
  totalTracks?: number;
  playlistImages?: {
    url: string;
    width: string;
    height: string;
  }
  addedAt?: string;
  remainingDays?: number;
  estimatedRemovalDate?: string;
};

export enum CampaignCreationErrorCodes {
  FB_ACCESS_TOKEN_INVALID = "FB_ACCESS_TOKEN_INVALID",
  FB_ASSETS_NOT_FOUND = "FB_ASSETS_NOT_FOUND",
  FB_VIDEO_STILL_PROCESSING = "FB_VIDEO_STILL_PROCESSING",
  FB_BUDGET_TOO_LOW = "FB_BUDGET_TOO_LOW",
  FB_ADACCOUNT_ISSUE = "FB_ADACCOUNT_ISSUE",
  FB_UNKNOWN_ERROR = "FB_UNKNOWN_ERROR",
  FB_TOO_MANY_CALLS = "FB_TOO_MANY_CALLS",
  FB_RECORD_NOT_FOUND = "FB_RECORD_NOT_FOUND",
}

export enum CampaingCreationErrorSuggestions {
  REFRESH_PAGE = "REFRESH_PAGE",
  RETURN_TO_CREATIVES_PAGE = "RETURN_TO_CREATIVES_PAGE",
  WAIT = "WAIT",
  RETURN_TO_BUDGET_PAGE = "RETURN_TO_BUDGET_PAGE",
  FIX_ON_FACEBOOK = "FIX_ON_FACEBOOK",
  CONTACT_SUPPORT = "CONTACT_SUPPORT",
}

export type CampaignCreationError = {
  data?: {
    error: {
      title?: string;
      code?: string;
      message?: string;
      suggestion?: string;
      canBeRetried?: boolean;
      link?: string;
    };
  } | null;
  message?: string | null;
  isError?: boolean | null;
};

export type FBCustomAudience = {
  name: string,
  id: string,
  rule: string,
  rule_aggregation: string,
  description: string,
  delivery_status: {
    code: number,
    description: string
  },
  lookalike_spec: Object,
  data_source: Object,
  subtype: string,
  approximate_count_lower_bound: number,
  approximate_count_upper_bound: number,
  is_value_based: boolean,
  opt_out_link: string
};

export type FbSuggestion = {
  id: string;
  name: string;
  audience_size: number | null;
  country: string | null;
  country_access: string | null;
  description: string | null;
  partner: string | null;
  path: string[] | null;
  source: string | null;
  topic: string | null;
  type: string | null;
  artistId: string | null;
};

export type LanguageChip = {
  key: number;
  name: string;
  selected: boolean;
}

export type AgeRange = {
  min: number,
  max: number,
}

export type DemographicsTargetingType = {
  age: AgeRange,
  gender: string,
  languages: Array<LanguageChip>
}

export type FbSuggestionsResponse =
  | {
    data: FbSuggestion[];
    paging: {
      cursors: {
        after: string;
        before: string;
      };
    };
  }
  | null
  | undefined;

export type SuggestedAudience = {
  name: string;
  fbId: string;
};

export type DataFBQueryAdInterest = {
  id: string;
  name: string;
  audience_size_lower_bound?: number | null;
  audience_size_upper_bound?: number | null;
  audience_size?: number | null;
  path: string[] | null;
  description: string | null;
  topic: string | null;
  valid?: boolean | null;
}[];

export type OverallCampaignStatusType = {
  status: OverallCampaignStatus;
  note: JSX.Element | string | null;
}

export type StreamingPlatformsData = {
  serviceName: string;
  result: number;
}[]

export enum OverallCampaignStatus {
  ERROR = "error",
  TO_RETRY = "to_retry",
  LIVE_WITH_ISSUES = "live_with_issues",
  ACTIVE = "active",
  LIVE_WITH_NO_STATS = "live_with_no_stats",
  IN_REVIEW = "in_review",
  IN_REVIEW_AFTER_ACTIVE = "in_review_after_active",
  DENIED = "denied",
  PAUSED = "paused",
  PENDING = "pending",
  ANTICIPATED_PAUSE = 'anticipated_puase',
  DEFAULT_OVERALL_CAMPAIGN_STATUS = PENDING,
}

export const OverallCampaignStatusKeys = {
  ERROR: 'ERROR',
  TO_RETRY: 'TO_RETRY',
  LIVE_WITH_ISSUES: 'LIVE_WITH_ISSUES',
  ACTIVE: 'ACTIVE',
  LIVE_WITH_NO_STATS: 'LIVE_WITH_NO_STATS',
  IN_REVIEW: 'IN_REVIEW',
  IN_REVIEW_AFTER_ACTIVE: 'IN_REVIEW_AFTER_ACTIVE',
  DENIED: 'DENIED',
  PAUSED: 'PAUSED',
  PENDING: 'PENDING',
  ANTICIPATED_PAUSE: 'ANTICIPATED_PAUSE',
  DEFAULT_OVERALL_CAMPAIGN_STATUS: 'DEFAULT_OVERALL_CAMPAIGN_STATUS',
} as const;

export type OverallCampaignStatusKeys = keyof typeof OverallCampaignStatusKeys;

export type AdCampaignStatus = {
  id: string;
  name: string;
  effective_status: string;
};

export interface RecordSelected {
  id: number;
  preview_url: string;
  explicit: boolean;
  external_links: { url: string; name: string }[];
  full_info: boolean;
  name: string;
  primary_artist: string;
  record_url: string;
  release_date: string;
  slug: string;
  url: string;
  spotify_id: string;
  spotify_release_type: string;
  spotify_uri: string;
  thumbnail_url: string;
  total_tracks: number;
  tracks?: Track[];
  type: string;
}

export type Release = {
  brand_id: number
  id: number
  name: string
  slug: string
  type: string
  url: string
  content_metadata: {
    explicit: boolean | null
    external_ids: Record<string, unknown>[]
    record_url: string
    spotify_id: string
    spotify_uri: string
    release_date: string
    thumbnail_url: string
    external_links: { name: string; url: string }[]
    primary_artist: string
    spotify_release_type: string
    name: string
    type: string
    tracks: Track[]
    presave?: Record<string, unknown>
    total_tracks?: number
    // related to website
    published?: boolean
  }
}

export interface Track {
  explicit: boolean;
  name: string;
  record_url: string;
  spotify_id: string;
  spotify_uri: string;
  preview_url?: string;

  added_at?: string;
  artist_names?: string[];
  album_name?: string;
  id?: string;
  uri?: string;
  is_playable?: boolean;

}

export interface RecordPreviewData {
  options: { url?: string; name: string; show: boolean }[];
  name: string;
  artworkUrl: string;
  subtitle?: string;
}

export enum BoxContrasts {
  LIGHT = "light",
  DARK = "dark",
}

export enum BackgroundTypes {
  ARTWORK = "artwork",
  COLOR = "color",
}

export enum StyleTypes {
  BOXES = "boxes",
  STACKED = "stacked",
  TRADITIONAL = "traditional",
}

export enum TitleAlignments {
  LEFT = "left",
  CENTER = "center",
  RIGHT = "right",
}

export interface CampaignPreSaveCreationData {
  id: number;
  brand_id: number;
  status: string;
  campaign_metadata: {
    link: string;
    status: string;
    content: {
      name: string;
      slug: string;
      url: string;
      type: string;
      thumbnail_url: string;
    };
    endDate: string;
    presave: {
      style: {
        bgType: string;
        bgColor: string;
        buttonConfig: string;
        primaryColor: string;
        secondaryColor: string;
      };
      boosts: {
        auto_presaves: boolean;
        boost_follows: boolean;
        collect_emails: boolean;
      };
      options: {
        redirect_url: string;
        redirect_to_custom_webpage: boolean;
        timezone?: string;
      };
      platforms: string[];
      local_release?: boolean;
    };
    startDate: string;
    campaign_type: string;
  };
  updatedAt: string;
  createdAt: string;
}

export interface PreSaveCampaignDetails extends CampaignPreSaveCreationData {
  totalOfEmails?: number;
  totalOfPresaves?: number;
  emailsData?: {
    new: number;
    total: number;
  }
  presavesData?: {
    new: number;
    total: number;
  }
  foreverSavesData?: {
    new: number;
    total: number;
  }
}

export interface BrandContentExistsData {
  alreadyExists: boolean;
  brandContent: BrandContent;
  link: string;
}

export interface PresaveCampaignExistsData {
  alreadyExists: boolean;
  campaign: CampaignPreSaveCreationData;
}

export enum CampaignStatus {
  ACTIVE = "ACTIVE",
  FINISHED = "FINISHED",
  IN_PROGRESS = "IN_PROGRESS",
  NOT_FOUND = "NOT_FOUND",
}

export type FanbaseItem = {
  id: number;
  email: string;
  name: string;
  profile_url: string;
  lastname?: string | null;
  city?: string | null;
  state?: string | null;
  country?: string | null;
  platform?: string | null;
  tier?: string | null;
  color?: string;
  origin: string;
  song_name?: string | null;
  createdAt?: string | null;
};

export enum EmailCampaignStatus {
  SUBMITTED = "SUBMITTED",
  SCHEDULED = "SCHEDULED",
}

export enum MessageDataContentKeys {
  TARGETS = "targets",
  OPENED_BY = "openedBy",
  OPENING_RATE = "openingRate",
  SENT_TO = "sentTo",
}

export interface EmailCampaignData {
  brand_id: number;
  campaign_metadata: {
    campaign_type: string;
    content: {
      subject_line: string;
    };
    email_fanbase: {
      openedBy: number;
      openingRate: number;
      sentTo: number;
    };
    release_date: string;
    timezone: string;
    status: string;
  };
  createdAt: string;
  id: number;
  status: string;
}

//TODO: Check if genre, follows and saves are an array of strings
export interface EmailCampaignDetailsData {
  brand_id: number;
  campaign_metadata: {
    campaign_type: string;
    content: {
      subject_line: string;
      html_content: string;
    };
    email_fanbase: {
      openAverage: number;
      openedBy: number;
      openingRate: number;
      sentTo: number;
      clicks: number;
      sentList: {
        id: number;
        name: string;
        lastname: string;
        genre: string[];
        email: string;
        cellphone: string | null;
        city: string;
        state: string;
        country: string;
        gender: string | null;
        profile_url?: string;
        ip_address: string | null;
        follows: string[];
        saves: string[];
        spotify_user_id: string | null;
        apple_music_user_id: string | null;
        deezer_user_id: string | null;
        spotify_user_refresh_token: string | null;
        apple_music_user_token: string | null;
        deezer_user_access_token: string | null;
      }[];
      openedList: {
        email: string;
        name: string;
        lastname: string;
        city: string;
        state: string;
        country: string;
        profile_url?: string | null;
      }[];
      clicksList: {
        email: string;
        name: string;
        lastname: string;
        city: string;
        state: string;
        country: string;
        profile_url?: string | null;
      }[];
    };
    release_date: string;
    status: string;
  };
  createdAt: string;
  id: number;
  status: string;
}

export interface SongAdsRecord {
  explicit: boolean;
  external_ids: Record<string, string>;
  external_links: { url: string; name: string }[];
  full_info: boolean;
  name: string;
  preview_url: string;
  primary_artist: string;
  record_url: string;
  release_date: string;
  slug: string;
  spotify_id: string;
  spotify_release_type: string;
  spotify_uri: string;
  thumbnail_url: string;
  type: string;
  updateCount: number;
  url: string | null;

  // this is only used if the user pastes in a spotify-provided
  // special URL. if so, then spotify provides a personalized playlist to the user.
  prid?: string

  // this is the primary track for a playlist campaign
  // to redirect to
  mainTrack?: Track


}

export interface DuplicateSongAdsRecord {
  full_info: boolean;
  external_links: { url: string; name: string }[] | null;
  primary_artist: string;
  name: string;
  thumbnail_url: string;
  parentName: string | null;
  explicit: boolean | null;
  record_url: string;
  spotify_id: string;
  spotify_uri: string;
  release_date: string;
  slug: string | null;
  url: string | null;
  type?: string;
  spotify_release_type: string;
  spotify_release_note: string;
  tracks?: {
    explicit: boolean;
    name: string;
    record_url: string;
    spotify_id: string;
    spotify_uri: string;
  }[]
  trackLength?: number
}

export interface PlaylistsRecord {
  id?: string
  explicit: boolean;
  external_ids: Record<string, string>;
  external_links: { url: string; name: string }[];
  full_info: boolean;
  name: string;
  preview_url: string;
  primary_artist: string;
  record_url: string;
  release_date: string;
  slug: string;
  spotify_id: string;
  spotify_release_type: string;
  spotify_uri: string;
  thumbnail_url: string;
  type: string;
  url: string;
}

export interface VideoAdsRecord {
  channelId: string;
  channelTitle: string;
  description: string;
  id: string;
  liveBroadcastContent: string;
  name: string;
  publishTime: string;
  publishedAt: string;
  thumbnails: {
    defaults: {
      height: number;
      width: number;
      url: string;
    };
    medium: {
      height: number;
      width: number;
      url: string;
    };
    hight: {
      height: number;
      width: number;
      url: string;
    };
  };
  title: string;
  primaryThumbnail: string;
}

export interface UserData {
  id: number;
  userid: string;
  email: string;
  emailVerified: string;
  name: string;
  hasBrands: boolean;
  metadata?: UserMetadata; // The '?' denotes that metadata is optional
  organization?: OrganizationData | null
}

export interface OrganizationData {
  status: "not_started" | "waiting_for_details" | "ready"
  name: string,
  brands: CurrentBrand[]
  totalProBrands: number;
  role: string;
}

export interface OrganizationBillingData {
  totalUsage: number;
  spentLimit: number;
  feeToDate: number;
  status: string;
  nextBillingDate: string;
  scheduledCancellationDate?: string | null;
  viewCustomerUrl: string;
  viewSubscriptionUrl: string;
}
export interface AffiliateDetails {
  discountAmount: number;
  partnerId: string;
  partnerName: string;
  cookieExpirationFromSignup: number;
  affiliate_referrer: string;
}

export interface AttributionDetails {
  utm_source?: string;
  utm_medium?: string;
  utm_campaign?: string;
  utm_term?: string;
  utm_content?: string;
}

export interface RewardfulDetails {
  rewardful_affiliate_id?: string;
  rewardful_via?: string;
}

export interface OrganizationSubscriptionStatus {
  status: string;
  stripe_session_id: string;
  stripe_customer_id: string;
  stripe_subscription_id: string;
  initialOnboardingCompleted: boolean;
}

export interface UserMetadata {
  // used to track partners who drive to symphony
  affiliate_referrer: string;
  affiliate_details?: AffiliateDetails;
  affiliate_initiated?: string;
  affiliate_expiration?: string;

  // used to track referral attribution
  attribution?: AttributionDetails
  
  // used to track Last Touch Atrribution 
  last_touch_attribution?: AttributionDetails

  // used to track when users are driven by a rewardful signup
  rewardful?: RewardfulDetails

  // used to track subscriptino organization status
  organization_subscription?: OrganizationSubscriptionStatus;
  // used to track when users have completed onboarding so we dont show it again
  onboarding_answers?: any

  // used to track when a user has explcitily created a free trial on their end
  free_trial_redeemed?: boolean

  // the last visited time based on accessed
  last_visited?: string;

  // rewardful details
  rewardful_affiliate_id?: string;
  rewardful_affiliate_link?: string;

  // organization
  hasTeamPlanAccess?: boolean;
}

export enum Breakpoints {
  sm = "sm",
  md = "md",
  lg = "lg",
  xl = "xl",
  xxl = "xxl",
}

export enum PreSaveErrorTypes {
  PRE_SAVE_CAMPAIGN_EXISTS = "PRE_SAVE_CAMPAIGN_EXISTS",
  BRAND_CONTENT_EXISTS = "BRAND_CONTENT_EXISTS",
  ERROR_ON_PRE_SAVE_CREATION = "ERROR_ON_PRE_SAVE_CREATION",
}

export enum FansMainGoals {
  PRE_SAVE = "PreSave",
  UPLOAD_EMAILS_BY_CSV = "UploadEmailsByCsv",
  UPLOAD_EMAILS_BY_PASTE = "UploadEmailsByPaste",
  MESSAGE_YOUR_FANS = "MessageYourFans",
}

export enum ScheduleOptions {
  ASAP = "ASAP",
  SCHEDULED = "SCHEDULED",
}

export enum ViewTypes {
  DESKTOP = "DESKTOP",
  MOBILE = "MOBILE",
}

export enum MessageYourFansTabs {
  DESIGN = "DESIGN",
  DETAILS = "DETAILS",
  FINALIZE = "FINALIZE",
}

export enum UploadEmailsByCsvTabs {
  UPLOAD_FILE = "UPLOAD_FILE",
  MATCH_FILE = "MATCH_FILE",
  FINALIZE = "FINALIZE",
}

export enum UploadEmailsByPasteTabs {
  PASTE_EMAILS = "PASTE_EMAILS",
  SOURCE = "SOURCE",
  FINALIZE = "FINALIZE",
}

export enum ImportFansStatus {
  IN_PROCESS = "IN_PROCESS",
  FINISHED = "FINISHED",
  DISRUPTED = "DISRUPTED",
}

export enum PaymentMethods {
  STRIPE = "stripe",
  PAYPAL = "paypal",
}

export enum Tiers {
  PRO = "pro",
  FREE = "free",
}

export type OnboardingArtist = {
  chartmetric: boolean
  cm_artist_score: unknown
  id: number | null
  slug: string | null
  image_url: string
  isni: unknown
  name: string
  sp_followers: number
  images: { url: string }[]
  followers: { total: number }
}

export type SpotifyArtist = {
  externalUrls: { spotify: string }
  followers: {
    href: string | null
    total: number
  }
  genres: string[]
  href: string
  id: string
  images: { height: number; url: string; width: number }[]
  name: string
  popularity: number
  type: string
  uri: string
}

export type AudiomackArtistProfile = {
  id: number | string;
  name: string;
  url_slug: string;
  thumbnail_url: string;
  bio: string;
  followers: number;
}

export type SoundcloudArtistProfile = {
  id: number | string;
  name: string;
  url_slug: string;
  thumbnail_url: string;
  tracks: number;
  permalink_url: string;
  followers: number;
  username: string;

  // old parameters - when it was just URL-based linking
  url: string;
  urlStructure?: string;
}


export type DeezerArtistProfile = {
  id: number | string;
  name: string;
  thumbnail_url: string;
  permalink_url: string;

}

export type YouTubeChannel = {
  searchUrl?: string
  id: {
    channelId: string
    kind: string
  }
  etag: string
  kind: string
  snippet: {
    customUrl?: string
    channelId: string
    channelTitle: string
    title: string
    description: string
    liveBroadcastContent: string
    publishTime: string
    thumbnails: {
      default: {
        url: string
      }
      medium: {
        url: string
      }
      high: {
        url: string
      }
    }
  }
}

export enum CreativeTabConnectTypes {
  FACEBOOK_BUSINESS_MANAGER_CONNECT = "facebook-biz-mgr",
  FACEBOOK_AD_ACCOUNT_CONNECT = "facebook-ad-account",
  FACEBOOK_PIXEL_CONNECT = "facebook-pixel",
  FACEBOOK_CONNECT = "facebook",
  INSTAGRAM_CONNECT = "instagram",
}

export interface InstagramBusinessAccountType {
  id: string;
  username: string;
  followers_count: number;
  profile_picture_url: string;
}

export interface ConnectedInstagramAccountType {
  id: string;
  username: string;
  followers_count: number;
  profile_picture_url: string;
}

export interface FacebookPageResponse {
  name: string,
  id: string,
  category: string,
  access_token: string,
  followers_count: number,
  picture: {
    data: {
      url: string
    }
  },
  is_published: boolean,
  connected_instagram_account?: ConnectedInstagramAccountType,
  instagram_business_account?: InstagramBusinessAccountType,
}

export interface ConnectorInstagramPagesType {
  connected_instagram_account: ConnectedInstagramAccountType | undefined,
  instagram_business_account: InstagramBusinessAccountType,
  facebookPage?: {
    name: string,
    id: string,
    category: string,
    access_token: string,
    followers_count: number,
    picture: string,
    is_published: boolean
  }
}

export enum SYMPHONY_TASKS {
  FANBASE_COMPLETED = "FANBASE_COMPLETED",
  CAMPAIGN_STARTED = "CAMPAIGN_STARTED",
  TEAM_INVITED = "TEAM_INVITED",
  WEBSITE_CUSTOMIZED = "WEBSITE_CUSTOMIZED",
  GROW_YOUTUBE_ENGAGEMENT = "GROW_YOUTUBE_ENGAGEMENT",
  INSTAGRAM_ENGAGEMENT = "INSTAGRAM_ENGAGEMENT",
}

export enum FANBASE_TASKS {
  PRESAVE_CREATED = "PRESAVE_CREATED",
}

export interface SymphonyTasks {
  symphony_tasks: SYMPHONY_TASKS[] | [],
  fanbase_tasks: FANBASE_TASKS[] | [],
  getting_started_completed: boolean,
  fanbase_tasks_completed: boolean,
}

export enum SavingDraftStatus {
  INITIAL_SAVING_STATE = 0,
  FIRST_TIME_DRAFT_DATA_LOAD = 1,
  SAVING_NEXT_DRAFT = 2
}

export interface Fan {
  id: number,
  name?: string,
  cellphone?: string,
  lastname?: string,
  email?: string,
  profile_url: string,
  city?: string,
  state?: string,
  country?: string,
  platform?: string,
  origin?: string,
  tier?: Tiers,
  presavedSongs: string[],
  createdAt: string,
  isNew: boolean,
  presaveFutureReleases: boolean,
  firstPresave?: string,
  newFollower?: boolean,
  updateSubscriptionLink?: string
}

export const THEME_BREAKPOINTS = {
  mobile: 701,
  small: 768,
  large: 1200,
}

export const MEDIA_BREAKPOINTS = {
  mobileView: `@media (max-width: ${THEME_BREAKPOINTS.mobile - 1}px)`,
  smallView: `@media (max-width: ${THEME_BREAKPOINTS.small - 1}px)`,
  mediumView: `@media (max-width: ${THEME_BREAKPOINTS.large - 1}px)`,
  largeView: `@media (min-width: ${THEME_BREAKPOINTS.large}px)`,
  strictSmall: `@media (min-width: ${THEME_BREAKPOINTS.mobile}px) and (max-width: ${THEME_BREAKPOINTS.small - 1}px)`,
  strictMedium: `@media (min-width: ${THEME_BREAKPOINTS.small}px) and (max-width: ${THEME_BREAKPOINTS.large - 1}px)`,
  mobileOnwards: `@media (min-width: ${THEME_BREAKPOINTS.mobile}px)`,
  smallOnwards: `@media (min-width: ${THEME_BREAKPOINTS.small}px)`,
}

export interface VideoData {
  videoUrl: string;
  thumbnail: string;
  title: string;
  description?: string;
  titleModalOpened?: string;
  descriptionModalOpened?: string;
  intercomArticle?: () => void;
}

interface ConversionsVideos {
  onboardingVideo: VideoData
  stepVideos: {
    CONVERSIONS_ONBOARDING: VideoData
    AD_ACCOUNT_CONNECTED: VideoData
    FB_PIXEL_CONNECTED: VideoData
    CONVERSIONS_ACCESS_TOKEN: VideoData
    FB_PIXEL_NOT_CONNECTED: VideoData
    GETTING_STARTED: VideoData
    FB_PAGE: VideoData
    AD_ACCOUNT: VideoData
  }
}

//TODO: change to final conversions video urls
export const CONVERSIONS_VIDEOS: ConversionsVideos = {
  onboardingVideo: {
    videoUrl: "https://symphonyos-public-assets.s3.us-east-2.amazonaws.com/videos/conversions.mp4",
    thumbnail: "https://symphonyos-public-assets.s3.us-east-2.amazonaws.com/images/thumbnails/conversions.jpg",
    title: "Activate Symphony Conversions for 4-6x better results",
    description: "Setup Symphony's AI with Facebook's Conversions API to drive more cost-effective results.",
  },
  stepVideos: {
    CONVERSIONS_ONBOARDING: {
      videoUrl: "https://symphonyos-public-assets.s3.us-east-2.amazonaws.com/videos/conversions.mp4",
      thumbnail: "https://symphonyos-public-assets.s3.us-east-2.amazonaws.com/images/thumbnails/conversions.jpg",
      title: "Activate Symphony Conversions for 4-6x better results",
      description: "Setup Symphony's AI with Facebook's Conversions API to drive more cost-effective results.",
    },
    AD_ACCOUNT_CONNECTED: {
      videoUrl: "https://symphonyos-public-assets.s3.us-east-2.amazonaws.com/videos/conversions.mp4",
      thumbnail: "https://symphonyos-public-assets.s3.us-east-2.amazonaws.com/images/thumbnails/conversions.jpg",
      title: "How to grow faster with Symphony Conversions",
      intercomArticle: Intercom.OpenGrowConversionsArticle,
    },
    FB_PIXEL_CONNECTED: {
      videoUrl: "https://symphonyos-public-assets.s3.us-east-2.amazonaws.com/videos/conversions.mp4",
      thumbnail: "https://symphonyos-public-assets.s3.us-east-2.amazonaws.com/images/thumbnails/conversions.jpg",
      title: "What is a Pixel?",
      intercomArticle: Intercom.openWhatIsAPixelArticle,
    },
    CONVERSIONS_ACCESS_TOKEN: {
      videoUrl: "https://symphonyos-public-assets.s3.us-east-2.amazonaws.com/videos/conversions_access_token.mp4",
      thumbnail: "https://symphonyos-public-assets.s3.us-east-2.amazonaws.com/images/thumbnails/conversions-access-token.jpg",
      title: "Where to find your Conversion API Access Token",
      intercomArticle: Intercom.openWhereIsMyConversionsTokenArticle,
    },
    FB_PIXEL_NOT_CONNECTED: {
      videoUrl: "https://symphonyos-public-assets.s3.us-east-2.amazonaws.com/videos/connect_pixel_to_ad_account.mp4",
      thumbnail: "https://symphonyos-public-assets.s3.us-east-2.amazonaws.com/images/thumbnails/connect-pixel-to-ad-account.jpg",
      title: "How to connect your Pixel to your Ad Account",
      intercomArticle: Intercom.openConnectYourPixelToAdAccountArticle,
    },
    // TODO: replace this videos and copies with the final ones
    GETTING_STARTED: {
      videoUrl: "https://res.cloudinary.com/symphonyos/video/upload/w_800/v1729079713/internal-assets/Why_Connect_FB_Acct_ahymel.mp4",
      thumbnail: "https://symphonyos-public-assets.s3.us-east-2.amazonaws.com/images/thumbnails/conversions.jpg",
      title: "Getting started",
      description: "Why link your Facebook and Instagram accounts and how it works?",
      titleModalOpened: "Getting started",
      descriptionModalOpened: "In this setup, we'll explain why linking your Facebook and Instagram accounts is crucial for running successful marketing campaigns on our platform. By the end of this setup, you'll understand the benefits of linking your accounts and be ready to proceed to run ads."
    },
    FB_PAGE: {
      // TODO: replace this video and thumbnail with the final ones
      videoUrl: "https://symphonyos-public-assets.s3.us-east-2.amazonaws.com/videos/conversions.mp4",
      thumbnail: "https://symphonyos-public-assets.s3.us-east-2.amazonaws.com/images/thumbnails/conversions.jpg",
      title: "Facebook page",
      description: "Why link your Facebook and Instagram accounts and how it works?",
      titleModalOpened: "Facebook page",
      descriptionModalOpened: "In this setup, we'll explain why linking your Facebook and Instagram accounts is crucial for running successful marketing campaigns on our platform. By the end of this setup, you'll understand the benefits of linking your accounts and be ready to proceed to run ads."
    },
    AD_ACCOUNT: {
      // TODO: replace this video and thumbnail with the final ones
      videoUrl: "https://symphonyos-public-assets.s3.us-east-2.amazonaws.com/videos/conversions.mp4",
      thumbnail: "https://symphonyos-public-assets.s3.us-east-2.amazonaws.com/images/thumbnails/conversions.jpg",
      title: "Ad Account",
      description: "Why link your Facebook and Instagram accounts and how it works?",
      titleModalOpened: "Ad Account",
      descriptionModalOpened: "In this setup, we'll explain why linking your Facebook and Instagram accounts is crucial for running successful marketing campaigns on our platform. By the end of this setup, you'll understand the benefits of linking your accounts and be ready to proceed to run ads."
    },
  }
}

export type AdAccountStatusCodes = 1 | 2 | 3 | 7 | 9 | 101 | 100

export type AdAccountStatus = {
  name: string;
  valid: boolean;
}

export type AdAccountStatuses = {
  [key in AdAccountStatusCodes]: AdAccountStatus;
};

export interface SpendBudgetData {
  status: "healthy" | "warning" | "exceeded" | "exact"
  spent: number
  spentLimit: number
  spentToDate: number
  spentLeft: number
  newSpent: number
  feePrice: number
  feeUnit: number
  feeTotal: number
  total: number
  newSpentLimit: number
  disableSpendLimit?: boolean
  firstTimeSpending?: boolean
  currencyCode?: string;
  isOrganization?: boolean;
}

export const CurrencyCodesTypeMapped = {
  AED: 'AED',
  AFN: 'AFN',
  ALL: 'ALL',
  AMD: 'AMD',
  ANG: 'ANG',
  AOA: 'AOA',
  ARS: 'ARS',
  AUD: 'AUD',
  AWG: 'AWG',
  AZN: 'AZN',
  BAM: 'BAM',
  BBD: 'BBD',
  BDT: 'BDT',
  BGN: 'BGN',
  BHD: 'BHD',
  BIF: 'BIF',
  BMD: 'BMD',
  BND: 'BND',
  BOB: 'BOB',
  BOV: 'BOV',
  BRL: 'BRL',
  BSD: 'BSD',
  BTC: 'BTC',
  BTN: 'BTN',
  BWP: 'BWP',
  BYN: 'BYN',
  BYR: 'BYR',
  BZD: 'BZD',
  CAD: 'CAD',
  CDF: 'CDF',
  CHE: 'CHE',
  CHF: 'CHF',
  CHW: 'CHW',
  CLF: 'CLF',
  CLP: 'CLP',
  CNH: 'CNH',
  CNY: 'CNY',
  COP: 'COP',
  COU: 'COU',
  CRC: 'CRC',
  CUC: 'CUC',
  CUP: 'CUP',
  CVE: 'CVE',
  CZK: 'CZK',
  DJF: 'DJF',
  DKK: 'DKK',
  DOP: 'DOP',
  DZD: 'DZD',
  EEK: 'EEK',
  EGP: 'EGP',
  ERN: 'ERN',
  ETB: 'ETB',
  ETH: 'ETH',
  EUR: 'EUR',
  FJD: 'FJD',
  FKP: 'FKP',
  GBP: 'GBP',
  GEL: 'GEL',
  GGP: 'GGP',
  GHC: 'GHC',
  GHS: 'GHS',
  GIP: 'GIP',
  GMD: 'GMD',
  GNF: 'GNF',
  GTQ: 'GTQ',
  GYD: 'GYD',
  HKD: 'HKD',
  HNL: 'HNL',
  HRK: 'HRK',
  HTG: 'HTG',
  HUF: 'HUF',
  IDR: 'IDR',
  ILS: 'ILS',
  IMP: 'IMP',
  INR: 'INR',
  IQD: 'IQD',
  IRR: 'IRR',
  ISK: 'ISK',
  JEP: 'JEP',
  JMD: 'JMD',
  JOD: 'JOD',
  JPY: 'JPY',
  KES: 'KES',
  KGS: 'KGS',
  KHR: 'KHR',
  KMF: 'KMF',
  KPW: 'KPW',
  KRW: 'KRW',
  KWD: 'KWD',
  KYD: 'KYD',
  KZT: 'KZT',
  LAK: 'LAK',
  LBP: 'LBP',
  LKR: 'LKR',
  LRD: 'LRD',
  LSL: 'LSL',
  LTC: 'LTC',
  LTL: 'LTL',
  LVL: 'LVL',
  LYD: 'LYD',
  MAD: 'MAD',
  MDL: 'MDL',
  MGA: 'MGA',
  MKD: 'MKD',
  MMK: 'MMK',
  MNT: 'MNT',
  MOP: 'MOP',
  MRO: 'MRO',
  MRU: 'MRU',
  MUR: 'MUR',
  MVR: 'MVR',
  MWK: 'MWK',
  MXN: 'MXN',
  MXV: 'MXV',
  MYR: 'MYR',
  MZN: 'MZN',
  NAD: 'NAD',
  NGN: 'NGN',
  NIO: 'NIO',
  NOK: 'NOK',
  NPR: 'NPR',
  NZD: 'NZD',
  OMR: 'OMR',
  PAB: 'PAB',
  PEN: 'PEN',
  PGK: 'PGK',
  PHP: 'PHP',
  PKR: 'PKR',
  PLN: 'PLN',
  PYG: 'PYG',
  QAR: 'QAR',
  RMB: 'RMB',
  RON: 'RON',
  RSD: 'RSD',
  RUB: 'RUB',
  RWF: 'RWF',
  SAR: 'SAR',
  SBD: 'SBD',
  SCR: 'SCR',
  SDG: 'SDG',
  SEK: 'SEK',
  SGD: 'SGD',
  SHP: 'SHP',
  SLL: 'SLL',
  SOS: 'SOS',
  SRD: 'SRD',
  SSP: 'SSP',
  STD: 'STD',
  STN: 'STN',
  SVC: 'SVC',
  SYP: 'SYP',
  SZL: 'SZL',
  THB: 'THB',
  TJS: 'TJS',
  TMT: 'TMT',
  TND: 'TND',
  TOP: 'TOP',
  TRL: 'TRL',
  TRY: 'TRY',
  TTD: 'TTD',
  TVD: 'TVD',
  TWD: 'TWD',
  TZS: 'TZS',
  UAH: 'UAH',
  UGX: 'UGX',
  USD: 'USD',
  UYI: 'UYI',
  UYU: 'UYU',
  UYW: 'UYW',
  UZS: 'UZS',
  VEF: 'VEF',
  VES: 'VES',
  VND: 'VND',
  VUV: 'VUV',
  WST: 'WST',
  XAF: 'XAF',
  XBT: 'XBT',
  XCD: 'XCD',
  XOF: 'XOF',
  XPF: 'XPF',
  XSU: 'XSU',
  XUA: 'XUA',
  YER: 'YER',
  ZAR: 'ZAR',
  ZMW: 'ZMW',
  ZWD: 'ZWD',
  ZWL: 'ZWL',
} as const

type ObjectValues<T> = T[keyof T]

export type CurrencyCodesType = ObjectValues<typeof CurrencyCodesTypeMapped>

export type BrandCurrency = {
  code: CurrencyCodesType
  symbol: string
}

export type CurrencyPrices = {
  annualConverted: number,
  monthlyConverted: number,
}

// used to pull conversion events form backend
export interface PixelConversionEvent {
  name: string;
  description?: string | null;
  category?: string | null;
  count: number;
  source?: string | null;
  time: string;
  type: "custom" | "symphony" | "fb-default"
}

export const MemberRoles = {
  OWNER: 'owner',
  ADMIN: 'administrator',
  MEMBER: 'member',
} as const

export const ArtistPermissions = {
  VIEW_ONLY: 'view_only',
  ADMINISTRATOR: 'administrator',
} as const

export type MemberRole = typeof MemberRoles[keyof typeof MemberRoles]
export type ArtistPermission = typeof ArtistPermissions[keyof typeof ArtistPermissions]

export interface Artist {
  id: number
  name: string
  isPro: boolean
  profile_picture: string
  permissionsType?: ArtistPermission
}

export interface TeamMember {
  id: number
  name?: string
  lastName?: string
  email: string
  thumbnailUrl: string
  role: MemberRole
  artists: Artist[]
}

export interface TeamPricesData {
  priceSeat: number
  priceSeatRemaining: number
  remainingDays: number
}

export const CAMPAIGN_STATUSES = {
  draft: 'DRAFT',
  published: 'PUBLISHED',
  submitted: 'SUBMITTED',
  active: 'ACTIVE',
  stopped: 'STOPPED',
  paused: 'PAUSED',
  error: 'ERROR',
  in_review: 'IN_REVIEW',
  with_issues: 'WITH_ISSUES',
  denied: 'DENIED',
  to_retry: 'TO_RETRY',
  finished: 'FINISHED',
  scheduled: 'SCHEDULED',
  anticipated_pause: 'ANTICIPATED_PAUSE',
  not_found: 'NOT_FOUND',
  in_progress: 'IN_PROGRESS',
} as const;

export const CAMPAIGN_TYPES = {
  record_streams: 'record_streams',
  get_playlisted: 'get_playlisted',
  increase_video_views: 'increase_video_views',
  pre_save: 'pre_save',
  email_fanbase: 'email_fanbase',
  link_clicks: 'link_clicks',
  creative_generator: 'creative_generator',
  boost_instagram: 'boost_instagram',
  grow_playlist_followers: 'grow_playlist_followers',
} as const;

export const FB_CAMPAIGN_TYPES = [
  CAMPAIGN_TYPES.record_streams,
  CAMPAIGN_TYPES.link_clicks,
  CAMPAIGN_TYPES.boost_instagram
];

export type CAMPAIGN_TYPE = typeof CAMPAIGN_TYPES[keyof typeof CAMPAIGN_TYPES]
export type CAMPAIGN_STATUS = typeof CAMPAIGN_STATUSES[keyof typeof CAMPAIGN_STATUSES]

export interface OrganizationCampaign {
  status: CAMPAIGN_STATUS
  campaignName: string
  type: CAMPAIGN_TYPE
  spent: number
  CPC?: number
  CPV?: number
  playlistingCampaigns?: {
    pitchedPlaylists?: any
    followersAcrossPlaylists?: any
  }
  presaves?: {
    totalPresaves: number
    emailsCollected: number
  }
  budget: number
  endDate: string
  startDate: string
  conversionsEnabled: boolean
  brandId: number
  brandName: string
  brandImage: string
}

export interface PlatformsPreview {
  [key: string]: PlatformPreview
}

export interface PlatformPreview {
  name: string
  key: string
  goal: string
  selected: boolean
  subtitle: any
  uploadingAssets: boolean
}

export const WEBSITE_TYPES = {
  RECORD_STREAMS: "record_streams",
  PRESAVE: "presave",
  DATA_COLLECTOR: "data_collector",
  TOUR: "tour",
} as const;

export type WebsiteType = typeof WEBSITE_TYPES[keyof typeof WEBSITE_TYPES];
export interface FacebookMeResponse {
  id: string;
  name: string;
  picture: { data: {  url: string } };
  access_token: string;
  expires_at?: string | null;
  fbTokenValid: boolean;
}
