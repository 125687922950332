import Axios from "helpers/Interceptor";
import {
  CUSTOM_AUDIENCES_TOS_URL,
  VALUE_BASED_CUSTOM_AUDIENCES_TOS_URL,
  USER_TOS_ACCEPTED_URL
} from "./constants";
import { PreConnectionConfigs } from "./utils/fbCampaigns";
import { SHARED_TOAST_OPTIONS } from "./Components/Modals/utils";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import { AD_ACCOUNT_STATUS } from "helpers/FB";
import { isValidURL } from "utils"
import { AdAccountPageType, Release, Track } from "types/global"
import { getTosStatus as getTosStatusApi } from '../MarketingPage/api'

export async function getTosConfiguration(adAccountDetails: Record<string, unknown> | false, selectedFBAdAccount?: AdAccountPageType, access_token?: string) {
  if (!adAccountDetails || !selectedFBAdAccount) return null;

  try {
    const termsOfService = await getTosStatusApi(selectedFBAdAccount.id, access_token);
    const toDoAccountStatus = getAccountStatus(selectedFBAdAccount, adAccountDetails);

    if (termsOfService.status && !toDoAccountStatus.status) termsOfService.status = false;

    termsOfService.account_status = toDoAccountStatus.account_status;
    return termsOfService;
  } catch (error) {
    console.error('Error getting TOS configuration: ', error);
    return null;
  }
}

export const getTosStatus = (selectedFBAdAccount: AdAccountPageType, results: Record<string, any>) => {
  const [, account_id] = selectedFBAdAccount.id.split('act_')
  const toDo = {
    status: false,
    custom_audience_accepted: {
      status: false,
      action: `${CUSTOM_AUDIENCES_TOS_URL}?act=${account_id}`,
      name: "Custom Audience Terms of Service"
    },
    value_based_custom_audience_tos: {
      status: false,
      action: `${VALUE_BASED_CUSTOM_AUDIENCES_TOS_URL}?act=${account_id}`,
      name: "Value-Based Audience Terms of Service"
    },
    user_tos_accepted: {
      status: false,
      action: `${USER_TOS_ACCEPTED_URL}?act=${account_id}`,
      name: "User Custom Audience Terms of Service"
    }
  } as PreConnectionConfigs

  const { tos_accepted, business, user_tos_accepted } = results
  if (tos_accepted) {
    toDo.custom_audience_accepted.status =
      tos_accepted.custom_audience_tos === 1 || tos_accepted.web_custom_audience_tos === 1
    toDo.value_based_custom_audience_tos.status = tos_accepted.value_based_custom_audience_tos === 1
  } else {
    toDo.custom_audience_accepted.status = !business
    toDo.value_based_custom_audience_tos.status = !business
  }

  toDo.user_tos_accepted.status = Boolean(user_tos_accepted);

  toDo.status = (
    toDo.custom_audience_accepted.status &&
    toDo.value_based_custom_audience_tos.status &&
    toDo.user_tos_accepted.status
  );

  return toDo
}

export const getAccountStatus = (selectedFBAdAccount: AdAccountPageType, results: Record<string, unknown>) => {
  const accountStatus = results.account_status
  const [, account_id] = selectedFBAdAccount.id.split('act_')
  const status = accountStatus === 1
  const toDo = {
    status,
    account_status: {
      status,
      action: `https://business.facebook.com/accountquality/${account_id}`,
      name: getAccountStatusName(accountStatus as number)
    }
  } as unknown as PreConnectionConfigs

  return toDo;
}

const getAccountStatusName = (status: number) => {
  const statuses = (AD_ACCOUNT_STATUS as Record<number, ({ name: string, valid: boolean })>)[status]
  return statuses ? statuses.name : "Unavailable"
}

export async function reportDownloader({
  brandId,
  campaignId,
  campaignName,
  campaignType
}: {
  brandId: string,
  campaignId: string,
  campaignName: string,
  campaignType: string
}) {
  // Note that this is a GET request, so there's no body included.
  let response = await Axios.get(`/campaign/${brandId}/${campaignId}/export-pdf`, {
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/pdf'
    },
    responseType: 'blob', // Required for blob response
    timeout: 120000
  });

  const reportName = `[S] "${campaignName}" ${campaignType} Campaign Report - ${dayjs().format("MM/DD")}`
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');
  link.href = url;
  link.target = "_blank"
  link.setAttribute('download', `${reportName}.pdf`); // or any other extension
  document.body.appendChild(link);


  link.click();

  const options = SHARED_TOAST_OPTIONS || {}

  toast.success('Report downloaded!', {
    ...options
  })

}

export const removeCleanFromRecordName = (name: string, explicit: boolean | null) =>
  !explicit && name.slice(-8) === " (Clean)"
    ? name.slice(0, name.length - 8)
    : name

export const getFormattedRecords = (args: {
  recentReleases: Release[] | null
  releaseType: string
  search: string
}) => {
  const { recentReleases, releaseType, search } = args
  const newRecords = []

  if (recentReleases) {
    for (const release of recentReleases) {
      if (releaseType === "single") {
        let dataTracks: Track[] = []

        if (release.content_metadata?.type === 'record') {
          const formattedTrack = {
            name: release.name,
            explicit: release.content_metadata.explicit ?? false,
            record_url: release.content_metadata.record_url,
            spotify_id: release.content_metadata.spotify_id,
            spotify_uri: release.content_metadata.spotify_uri
          }
          dataTracks = [formattedTrack]
        } else if (release.content_metadata.type !== 'playlist') {
          // we need to add this check so we dont automatically pull int the 
          // playlist tracks to the list
          const formattedTracks = release.content_metadata.tracks?.map(
            (track: Track) => {
              const { name, explicit, record_url, spotify_id, spotify_uri } = track
              return { name, explicit, record_url, spotify_id, spotify_uri }
            }
          )
          dataTracks = [...formattedTracks]
        }

        const formattedTracks = dataTracks.map((track) => {
          const { name, explicit, record_url, spotify_id, spotify_uri } = track
          const {
            thumbnail_url,
            name: parentName,
            release_date,
            primary_artist,
          } = release.content_metadata
          const updatedName = removeCleanFromRecordName(name, explicit)

          return {
            external_links: null,
            primary_artist: primary_artist,
            name: updatedName,
            thumbnail_url,
            parentName: parentName,
            explicit,
            record_url,
            spotify_id,
            spotify_uri,
            release_date,
            slug: spotify_id,
            url: null,
            spotify_release_type: "Single",
            spotify_release_note: `from "${parentName}"`,
          }
        })

        newRecords.push(...formattedTracks)
      } else if (release.content_metadata.type === 'project') {
        const {
          release_date,
          thumbnail_url,
          spotify_id,
          explicit,
          record_url,
          spotify_uri,
          spotify_release_type,
          primary_artist,
          external_links,
          tracks,
        } = release.content_metadata

        if (tracks.length > 0) {
          const { name, slug, url } = release
          const updatedName = removeCleanFromRecordName(name, explicit)
          const noteValue =
            tracks.length >= 6
              ? "Project"
              : spotify_release_type || release.content_metadata.type

          const newRecord = {
            external_links: external_links,
            primary_artist: primary_artist,
            name: updatedName,
            thumbnail_url: thumbnail_url,
            parentName: null,
            explicit: explicit,
            record_url: record_url,
            spotify_id: spotify_id,
            spotify_uri: spotify_uri,
            release_date: release_date,
            slug,
            url,
            tracks: tracks,
            trackLength: tracks.length,
            spotify_release_type: spotify_release_type,
            spotify_release_note: `${noteValue.charAt(0).toUpperCase() + noteValue.slice(1)
              } (${!explicit ? "Clean, " : ""}${tracks.length} Tracks)`,
          }

          newRecords.push(newRecord)
        }
      } else if (release.content_metadata.type === "playlist") {
        const {
          release_date,
          thumbnail_url,
          spotify_id,
          explicit,
          record_url,
          spotify_uri,
          spotify_release_type,
          primary_artist,
          external_links,
          tracks = [],
        } = release.content_metadata

        const { name, slug, url } = release
        const updatedName = removeCleanFromRecordName(name, explicit)
        const newRecord = {
          external_links: external_links,
          primary_artist: primary_artist,
          name: updatedName,
          thumbnail_url: thumbnail_url,
          parentName: null,
          explicit: explicit,
          record_url: record_url,
          spotify_id: spotify_id,
          spotify_uri: spotify_uri,
          release_date: release_date,
          slug,
          url,
          tracks: tracks ? tracks : [],
          trackLength: tracks?.length,
          spotify_release_type: 'Playlist',
          spotify_release_note: '',
        }

        newRecords.push(newRecord)
      }
    }
  }


  return newRecords
    .filter(
      (a: { name: string }) =>
        !(search && search !== "" && !isValidURL(search)) ||
        a.name.toLowerCase().includes(search.toLowerCase())
    )
    .sort((a: { release_date: string }, b: { release_date: string }) => {
      const aDate = a.release_date
      const bDate = b.release_date
      return new Date(bDate).getTime() - new Date(aDate).getTime()
    })
}