import { useEffect, useState, FunctionComponent, Dispatch, SetStateAction, useContext } from "react"
import { TabPanel, useTabs } from "react-headless-tabs"
import MainContentContainer from "components/shareable/MainContentContainer"
import Finalization from "./FinalizationTab"
import RecordTab from "components/shareable/RecordSelectorTab"
import PlaylistingGoalTab from "./PlaylistingGoalTab"
import { track } from "analytics"
import { scrollIntoView, scrollToTop } from "helpers/StyleUtils"
import { useHistory, useLocation } from "react-router-dom"
import Grid from "@material-ui/core/Grid"
import TopBar from "components/shareable/TopBar"
import CustomStepper from "components/shareable/CustomStepper"
import useStyles from "./styles"
import { CurrentBrand, PlaylistsRecord, SavingDraftStatus, UserData } from "types/global"
import some from "lodash/some"
import { useTheme, useMediaQuery } from "@material-ui/core"
import { useDraftDataGetter, useDraftDataSetter } from "./Hooks/DraftsHandlers"
import { STEPS } from "./utils"
import { checkIfIsProTier } from "utils"
import InfoSection from "components/InfoSection"
import SpendBudgetProvider from "Hooks/BudgetTabSpendContext"
import { CAMPAIGN_TYPES } from "../../../../constants"
import { CurrentBrandContext } from "Hooks/CurrentBrandContext"
import { ConnectionsContext } from "../hooks/ConnectionsContext"

const { RECORD, GOAL, FINALIZATION } = STEPS
const { INITIAL_SAVING_STATE } = SavingDraftStatus

interface Props {
  setGoal: Dispatch<SetStateAction<string | null>>
  refreshCampaigns: () => void
  reloadBrands: () => void
  currentUser:UserData;
}

const GetPlaylisted: FunctionComponent<Props> = ({ currentUser }: Props) => {
  const classes = useStyles()
  const history = useHistory()
  const location = useLocation()
  const draft = new URLSearchParams(location.search).get("draft")
  const tabs = [RECORD, GOAL, FINALIZATION]
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down(769))
  const { currentBrand } = useContext(CurrentBrandContext) as { currentBrand: CurrentBrand }
  const { loggedInFbUser } = useContext(ConnectionsContext)
  const [selectedTab, setSelectedTab] = useTabs(tabs, RECORD)

  // Record info
  const [recordSelected, setRecordSelected] = useState<PlaylistsRecord | null>(null)

  const [pitchNotes, setPitchNotes] = useState<string>("")
  // tier details
  const [budgetValue, setBudgetValue] = useState<any>(null)
  const [isSavingDraft, setIsSavingDraft] = useState(false)
  const [campaignDraftData, setCampaignDraftData] = useState<Record<string, unknown> | null>(null)
  const [retrievingDraftData, setRetrievingDraftData] = useState(!!draft)
  const [requiredDataChanged, setRequiredDataChanged] = useState(false)
  const [savingDraftStatus, setSavingDraftStatus] = useState(INITIAL_SAVING_STATE)

  const isPro = checkIfIsProTier(currentBrand)

  const mainTitle = recordSelected
    ? `Promote "${recordSelected.name}"`
    : "Get Playlisted"
  const spotifyConnected =
    currentBrand && currentBrand.connections.spotify_artist_page
      ? currentBrand.connections.spotify_artist_page
      : null
  const steps = [
    { description: "Record", name: RECORD },
    { description: "Goal", name: GOAL },
    { description: "Review", name: FINALIZATION },
  ]
  const [recordTabNextButtonData, setSaveNextButtonData] = useState({
    areLinksValid: false,
    isLoadingButton: false,
  })
  const { areLinksValid } = recordTabNextButtonData
  const dataToSaveDraft = [recordSelected, budgetValue, selectedTab, pitchNotes]

  const handleClickCloseTopBar = () => {
    history.push("/marketing")
  }

  const handleClickBackButton = () => {
    if (selectedTab === RECORD) {
      if (recordSelected) {
        setRecordSelected(null)
      } else {
        history.push("/marketing")
      }
    } else if (selectedTab === GOAL) {
      setSelectedTab(RECORD)
    } else {
      setSelectedTab(GOAL)
    }
  }

  const handleClickNextButton = () => {
    if (selectedTab === RECORD) {
      setSelectedTab(GOAL)
    } else if (selectedTab === FINALIZATION && recordSelected) {
      setSelectedTab(selectedTab)
    }
  }

  const handleClickStep = (step: string) => () => {
    const goToFinalizationStep = step === FINALIZATION && recordSelected && budgetValue
    const goToGoalStep = step === GOAL && recordSelected
    const goToRecordStep = step === RECORD

    if (some([goToFinalizationStep, goToGoalStep, goToRecordStep])) setSelectedTab(step as STEPS)
  }

  const saveNextButtonData = (data: {
    areLinksValid: boolean
    isLoadingButton: boolean
  }) => {
    setSaveNextButtonData(data)
  }

  const handleRecordTabNextButton = () => {
    track("Completed Campaign Step - Content Selection", {
      type: "get_playlisted",
      step: 1,
      name: "select_song",
      brand_id: currentBrand.id,
      brand_name: currentBrand.name,
      brand_slug: currentBrand.slug,
      content_name: recordSelected?.name,
      content_slug: recordSelected?.slug,
      content_id: recordSelected?.id,
    })

    setSelectedTab(GOAL)
  }

  useEffect(() => {
    scrollToTop()
    scrollIntoView()
  }, [selectedTab])

  useDraftDataGetter({
    setters: {
      setRecordSelected,
      setBudgetValue,
      setSelectedTab,
      setPitchNotes,
      setCampaignDraftData,
      setRetrievingDraftData,
      setIsSavingDraft,
    } as Record<string, Dispatch<unknown>>,
    draft,
  })

  const { cancelSavingDraft } = useDraftDataSetter({
    setters: {
      setRequiredDataChanged,
      setIsSavingDraft,
      setCampaignDraftData,
      setSavingDraftStatus
    } as Record<string, Dispatch<unknown>>,
    campaignDraftData,
    requiredDataChanged,
    recordSelected,
    retrievingDraftData,
    dataToSaveDraft: dataToSaveDraft as unknown as Record<string, unknown>[],
    draft,
    currentBrand,
    savingDraftStatus
  })


  function handlePitchNotesChange(e: any) {
    if ((e.target.value.length <= 200
      || e.target.value === '')) {
      setPitchNotes(e.target.value)

    }
  }
  return (
    <Grid className="mb-32">
      <TopBar
        title={mainTitle}
        showSaveDraftChip={!!draft || !!recordSelected}
        showCloseIcon
        savingChanges={isSavingDraft}
        handleClose={handleClickCloseTopBar}
      />
      <CustomStepper
        activeStep={steps.map(({ name }) => name).indexOf(selectedTab as STEPS)}
        steps={steps.map((data) => ({
          ...data,
          onClickHandler: handleClickStep,
        }))}
        stepButtonsProps={{
          handleClickBackButton,
          handleClickNextButton,
          showNextButton: selectedTab !== FINALIZATION,
          disableNextButton:
            selectedTab === FINALIZATION ||
            selectedTab === GOAL ||
            (selectedTab === RECORD && !areLinksValid) ||
            !recordSelected,
        }}
      >
        <MainContentContainer className={classes.mainContainer}>
          <Grid
            className={`bg-white w-full mb-8 rounded-${isMobile ? "none" : "xl mt-6"
              }`}
          >
            <TabPanel hidden={selectedTab !== "record"}>
              <RecordTab
                filter="single"
                subtype={'get_playlisted'}
                spotifyConnected={spotifyConnected}
                setRecordSelected={setRecordSelected}
                recordSelected={recordSelected}
                setPitchNotes={setPitchNotes}
                pitchNotes={pitchNotes}
                nextTab={handleRecordTabNextButton}
                brand={currentBrand}
                columns={["artist", "genre", "type"]}
                showNextButton={false}
                saveNextButtonData={saveNextButtonData}
                fullInfoContainerClassName="w-full"
              />
              {recordSelected && recordSelected.full_info && (
                <div className={" "}>

                  <InfoSection title={"Pitch Notes (Optional)"}
                    description={(<div>Enter any info you’d like our pitching team to know as they reach out to curators. We recommend including song genres and details about related artists.<br /><br />NOTE: Our pitching team may broaden their search outside these guidelines if necessary (e.g. if there aren't as many well-engaged playlists within your defined niche), while still trying to stay true to the song. (optional)</div>)} />
                      <div className="sy-card px-5 lg:px-10 h-28 relative">
                        <textarea onChange={handlePitchNotesChange}
                          placeholder="I’d like to focus on alternative rap playlists..."
                          value={pitchNotes}
                          maxLength={200} className="resize-none	p-2 w-full rounded-md border h-full"></textarea>
                        <p className=" px-5 lg:px-10 absolute bottom-5 right-2.5 text-xs text-gray-600">{pitchNotes ? `${pitchNotes.length}/200` : '0/200'}</p>
                      </div>
                </div>
              )}

            </TabPanel>
            <TabPanel hidden={selectedTab !== "goal"}>
              <PlaylistingGoalTab
                isPro={isPro}
                record={recordSelected}
                setTier={(value: { tier: number; cost: number }) => {
                  track("Completed Campaign Step - Tier Selection", {
                    type: "get_playlisted",
                    step: 2,
                    name: "tier_selection",
                    brand_id: currentBrand.id,
                    brand_name: currentBrand.name,
                    brand_slug: currentBrand.slug,
                    content_name: recordSelected?.name,
                    content_slug: recordSelected?.slug,
                    content_id: recordSelected?.id,
                    tier: value,
                  })

                  setBudgetValue(value)
                  if (recordSelected) setSelectedTab(FINALIZATION)
                }}
                currentBrand={currentBrand}
              />
            </TabPanel>
            <TabPanel hidden={selectedTab !== FINALIZATION}>
              <SpendBudgetProvider
                {...{ budgetValue }}
                currentUser={currentUser}
                step={selectedTab as string}
                campaignType={CAMPAIGN_TYPES.get_playlisted}
                access_token={loggedInFbUser?.access_token}
              >
                <Finalization
                  pitchNotes={pitchNotes}
                  campaignId={draft}
                  tier={budgetValue}
                  record={recordSelected}
                  brand={currentBrand}
                  cancelSaving={cancelSavingDraft}
                />
              </SpendBudgetProvider>
            </TabPanel>
          </Grid>
        </MainContentContainer>
      </CustomStepper>
    </Grid>
  )
}

export default GetPlaylisted
