import { SystemColors } from "types/globalStyles";

export type StreamingService = 'spotify' | 'apple_music' | 'amazon_music' | 'youtube' | 'deezer' | 'tidal' | 'soundcloud' | 'audiomack' | 'yandex' | 'tiktok';

export const SocialMedia = {
    FACEBOOK: 'facebook',
    INSTAGRAM: 'instagram',
    TIKTOK: 'tiktok',
    TWITTER: 'twitter',
    YOUTUBE: 'youtube',
} as const;

export type SocialMediaType = typeof SocialMedia[keyof typeof SocialMedia];

export enum WEBSITE_TABS {
    SETUP = 'setup',
    STYLE = 'style',
    CONNECT = 'connect',
    ANALYTICS = 'analytics',
}

export enum WEBSITE_TABS_VIEW_ONLY {
    ANALYTICS = 'analytics',
}

export const TABS_VIEW_ONLY = [
    {
        value: WEBSITE_TABS.ANALYTICS,
        label: 'Analytics',
        isProOnly: false,
    },
];

export const TABS = [
    {
        value: WEBSITE_TABS.SETUP,
        label: 'Setup',
        isProOnly: false,
    },
    {
        value: WEBSITE_TABS.STYLE,
        label: 'Style',
        isProOnly: false,
    },
    {
        value: WEBSITE_TABS.CONNECT,
        label: 'Connect',
        isProOnly: true,
    },
    {
        value: WEBSITE_TABS.ANALYTICS,
        label: 'Analytics',
        isProOnly: false,
    },
]

export interface ScrollPosition {
    customize: number;
    preview: number;
}

export interface bgValueType {
    color: string;
    artwork: number;
}

export interface DefaultStyles {
    removeSymphonyBrand: boolean,
    enableSocialIcons: boolean,
    socialIcons: WebsiteLink[]
    subtitle: string;
    titleText: string;
    mainColor: string;
    secondaryColor: string;
    bgValue: bgValueType;
    bgType: string;
    styleType: string;
    youtubeEmbed: {
        id: string;
        url: string;
    };
}

export interface WebsiteLinks {
    [key: string]: WebsiteLink;
}

export interface StyleType {
    title: string;
    name: string;
    order: number;
}

export type TitleAlignmentThemeStyleType = {
    title: string;
    name: string;
    order: number;
};

export interface ThemeStyle {
    title: string;
    name: ThemeStyleKey;
    order: number;
    isProOnly: boolean;
    defaultTitleAlignment?: TitleAlignmentThemeStyleType;
}

export interface FontThemeStyle {
    title: string;
    name: string;
    order: number;
    isProOnly?: boolean;
}

export const musicThemeStyles: {
    classic: ThemeStyle
    art: ThemeStyle,
    full_image: ThemeStyle,
    vinyl?: ThemeStyle,
    full_video: ThemeStyle,
} = {
    classic: {
        title: "Classic",
        name: "classic",
        order: 0,
        isProOnly: false,
        defaultTitleAlignment: { title: "Left", name: "left", order: 1 },
    },
    art: {
        title: "Art",
        name: "art",
        order: 1,
        isProOnly: false,
        defaultTitleAlignment: { title: "Center", name: "center", order: 2 },
    },
    full_image: {
        title: "Full image",
        name: "full_image",
        order: 2,
        isProOnly: true,
        defaultTitleAlignment: { title: "Center", name: "center", order: 2 },
    },
    vinyl: {
        title: "Vinyl",
        name: "vinyl",
        order: 3,
        isProOnly: true,
        defaultTitleAlignment: { title: "Center", name: "center", order: 2 },
    },
    full_video: {
        title: "Full video",
        name: "full_video",
        order: 4,
        isProOnly: true,
        defaultTitleAlignment: { title: "Center", name: "center", order: 2 },
    },
}

export const THEME_STYLES = {
    CLASSIC: "classic",
    ART: "art",
    VINYL: "vinyl",
    BASIC: "basic",
    SIMPLE: "simple",
    FULL_IMAGE: "full_image",
    FULL_VIDEO: "full_video",
} as const

export type ThemeStyleKey = typeof THEME_STYLES[keyof typeof THEME_STYLES];

export const dataCollectorThemeStyles: {
    basic: ThemeStyle,
    simple: ThemeStyle,
    full_image: ThemeStyle,
    full_video: ThemeStyle,
} = {
    basic: {
        title: "Basic",
        name: "basic",
        order: 1,
        isProOnly: false,
        defaultTitleAlignment: { title: "Left", name: "left", order: 1 },
    },
    simple: {
        title: "Simple",
        name: "simple",
        order: 2,
        isProOnly: false,
        defaultTitleAlignment: { title: "Center", name: "center", order: 2 },
    },
    full_image: {
        title: "Full image",
        name: "full_image",
        order: 3,
        isProOnly: true,
        defaultTitleAlignment: { title: "Center", name: "center", order: 2 },
    },
    full_video: {
        title: "Full video",
        name: "full_video",
        order: 4,
        isProOnly: true,
        defaultTitleAlignment: { title: "Center", name: "center", order: 2 },
    },
}

export const tourThemeStyles: {
    basic: ThemeStyle,
    simple: ThemeStyle,
    full_image: ThemeStyle,
    full_video: ThemeStyle,
} = {
    basic: {
        title: "Basic",
        name: "basic",
        order: 1,
        isProOnly: false,
        defaultTitleAlignment: { title: "Left", name: "left", order: 1 },
    },
    simple: {
        title: "Simple",
        name: "simple",
        order: 2,
        isProOnly: false,
        defaultTitleAlignment: { title: "Center", name: "center", order: 2 },
    },
    full_image: {
        title: "Full image",
        name: "full_image",
        order: 3,
        isProOnly: true,
        defaultTitleAlignment: { title: "Center", name: "center", order: 2 },
    },
    full_video: {
        title: "Full video",
        name: "full_video",
        order: 4,
        isProOnly: true,
        defaultTitleAlignment: { title: "Center", name: "center", order: 2 },
    },
}

export const tourButtonStyles: {
    basic: ThemeStyle,
    simple: ThemeStyle,
    full_image: ThemeStyle,
    full_video: ThemeStyle,
} = {
    basic: {
        title: "Corners",
        name: "basic",
        order: 1,
        isProOnly: false,
    },
    simple: {
        title: "Soft corners",
        name: "simple",
        order: 2,
        isProOnly: false,
    },
    full_image: {
        title: "Ovals",
        name: "full_image",
        order: 3,
        isProOnly: true,
    },
    full_video: {
        title: "Cutted",
        name: "full_video",
        order: 4,
        isProOnly: true,
    },
}

export interface AlbumTrack {
    name: string;
    explicit: boolean;
    record_url: string;
    spotify_id: string;
    preview_url?: string;
}

export interface WebsiteLink {
    enabled: boolean;
    rounded: boolean;
    backgroundColor: string;
    iconColor?: string;
    height: string;
    width: string;
    url: string;
    key: string;
    title: string;
    type?: string;
    placeholder: string;
}

export const streamLinkOrientations = {
    boxes: {
        title: "Boxes",
        name: "boxes",
        order: 1,
    },
    stacked: {
        title: "Stacked",
        name: "stacked",
        order: 2,
    },
    traditional: {
        title: "Traditional",
        name: "traditional",
        order: 3,
    },
}


export const titleAlignmentThemeStyles: Record<string, TitleAlignmentThemeStyleType> = {
    left: {
        title: "Left",
        name: "left",
        order: 1,
    },
    center: {
        title: "Center",
        name: "center",
        order: 2,
    },
    right: {
        title: "Right",
        name: "right",
        order: 3,
    },
} as const;

export const fontThemeStyles = {
    font1: {
        title: "DIN",
        name: "font1",
        order: 1,
        isProOnly: false,
    },
    font2: {
        title: "Times New Roman",
        name: "font2",
        order: 2,
        isProOnly: false,
    },
    font3: {
        title: "Helvetica",
        name: "font3",
        order: 3,
        isProOnly: false,
    },
    font4: {
        title: "Baskerville",
        name: "font4",
        order: 4,
        isProOnly: true,
    },
    font5: {
        title: "Kode Mono",
        name: "font5",
        order: 5,
        isProOnly: true,
    },
    font6: {
        title: "Lato",
        name: "font6",
        order: 6,
        isProOnly: true,
    },
    font7: {
        title: "DM Serif Display",
        name: "font7",
        order: 7,
        isProOnly: true,
    },
    font8: {
        title: "Lobster",
        name: "font8",
        order: 8,
        isProOnly: true,
    },
    font9: {
        title: "Dosis",
        name: "font9",
        order: 9,
        isProOnly: true,
    },
    font10: {
        title: "Comfortaa",
        name: "font10",
        order: 10,
        isProOnly: true,
    },
    font11: {
        title: "Jacquarda Bastarda 9",
        name: "font11",
        order: 11,
        isProOnly: true,
    },
    font12: {
        title: "Slackside One",
        name: "font12",
        order: 12,
        isProOnly: true,
    },
} as const;

export const websiteSubSectionContainer = {
    backgroundColor: '#F0F0EF',
    borderRadius: 8,
    padding: 24,
    flexDirection: 'column',
    display: 'flex',
    gap: 16,
    justifyContent: 'center',
    alignItems: 'center',

} as const;

export const tourDateListContainer = {
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
} as const;

export const websiteInputRow = {
    display: 'flex',
    gap: 8,
    alignItems: 'center',
} as const;

export const tourDateRow = {
    display: 'flex',
    flexDirection: 'row',
    gap: 16,
    border: `1px solid ${SystemColors.PAPER_DIVIDER_CONTAINER}`,
    borderRadius: 12,
    padding: 12,
    alignItems: 'center',
} as const;

export const tourDateDetail = {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: SystemColors.ACCENT_COLOR_LIGHT,
    height: 56,
    width: 56,
    borderRadius: 8,
    justifyContent: 'center',
    alignItems: 'center',
    flexShrink: 0,
} as const;

export const tourDateDay = {
    fontSize: 18,
    color: SystemColors.PRIMARY_TEXT_COLOR,
    marginBottom: -4,
} as const;

export const tourDateMonth = {
    fontSize: 16,
    color: SystemColors.PRIMARY_TEXT_COLOR,
} as const;

export const tourDateAddUploadContainer = {
    display: 'flex',
    flexDirection: 'row',
    gap: 16,
    alignItems: 'center',
} as const;

export type FontThemeStyleType = keyof typeof fontThemeStyles;

export const defaultIconProps: WebsiteLink = {
    enabled: true,
    rounded: true,
    backgroundColor: "",
    height: "30",
    width: "30",
    url: "",
    key: "",
    title: "",
    placeholder: "",
};

export const websiteLinkInitObj: WebsiteLink = {
    enabled: true,
    rounded: true,
    backgroundColor: "",
    height: "25",
    width: "25",
    url: "",
    key: "",
    title: "",
    placeholder: "",
};

export const StreamingLinkTypeOptions: WebsiteLink[] = [
    {
        ...websiteLinkInitObj,
        ...{ key: 'spotify', title: 'Spotify' },
    },
    {
        ...websiteLinkInitObj,
        ...{ key: 'apple_music', title: 'Apple Music' },
    },
    {
        ...websiteLinkInitObj,
        ...{ key: 'amazon_music', title: 'Amazon Music' },
    },
    {
        ...websiteLinkInitObj,
        ...{ key: 'youtube', title: 'YouTube Music' },
    },
    {
        ...websiteLinkInitObj,
        ...{ key: 'deezer', title: 'Deezer' },
    },
    {
        ...websiteLinkInitObj,
        ...{ key: 'tidal', title: 'Tidal' },
    },
    {
        ...websiteLinkInitObj,
        ...{ key: 'soundcloud', title: 'Soundcloud' },
    },
    {
        ...websiteLinkInitObj,
        ...{ key: 'audiomack', title: 'Audiomack' },
    },
    {
        ...websiteLinkInitObj,
        ...{ key: 'boomplay', title: 'Boomplay' },
    },
    {
        ...websiteLinkInitObj,
        ...{ key: 'anghami', title: 'Anghami' },
    },
    {
        ...websiteLinkInitObj,
        ...{ key: 'bandcamp', title: 'Bandcamp' },
    },
    {
        ...websiteLinkInitObj,
        ...{ key: 'beatport', title: 'Beatport' },
    },
    {
        ...websiteLinkInitObj,
        ...{ key: 'google_play', title: 'Google Play' },
    },
    {
        ...websiteLinkInitObj,
        ...{ key: 'pandora', title: 'Pandora' },
    },
    {
        ...websiteLinkInitObj,
        ...{ key: 'tiktok', title: 'TikTok' },
    },
    {
        ...websiteLinkInitObj,
        ...{ key: 'soundxyz', title: 'Sound.xyz' },
    },
    {
        ...websiteLinkInitObj,
        ...{ key: 'shazam', title: 'Shazam' },
    },
    {
        ...websiteLinkInitObj,
        ...{ key: 'twitter', title: 'Twitter' },
    },
]

export const dataCollectorFieldsInit = [
    {
        id: 'email_number',
        label: "Email / Phone Number",
        required: true,
        priority: 0,
        reserved: false,
    },
    {
        id: 'name',
        label: "What's your name?",
        required: true,
        priority: 0,
        reserved: false,
    },
]

export const socialMediaIconsInit: WebsiteLinks = {
    facebook: {
        ...websiteLinkInitObj,
        ...{
            title: 'Facebook',
            key: 'facebook',
            url: '',
            placeholder: 'Facebook profile URL',
        },
    },
    instagram: {
        ...websiteLinkInitObj,
        ...{
            title: 'Instagram',
            key: 'instagram',
            url: '',
            placeholder: 'Instagram profile URL',
        },
    },
    tiktok: {
        ...websiteLinkInitObj,
        ...{
            title: 'TikTok',
            key: 'tiktok',
            url: '',
            placeholder: 'TikTok profile URL',
        },
    },
    twitter: {
        ...websiteLinkInitObj,
        ...{
            title: 'Twitter',
            key: 'twitter',
            url: '',
            placeholder: 'X profile URL',
        },
    },
    youtube: {
        ...websiteLinkInitObj,
        ...{
            title: 'YouTube',
            key: 'youtube',
            url: '',
            placeholder: 'YouTube profile URL',
        },
    },
};

export const streamingTitleDict: { [key in StreamingService]: string } = {
    spotify: 'Spotify',
    apple_music: 'Apple Music',
    amazon_music: 'Amazon Music',
    youtube: 'YouTube Music',
    deezer: 'Deezer',
    tidal: 'Tidal',
    soundcloud: 'SoundCloud',
    audiomack: 'AudioMack',
    yandex: 'Yandex',
    tiktok: 'TikTok',
};

export const socialMediaTitleDict: Record<SocialMediaType, string> = {
    [SocialMedia.FACEBOOK]: 'Facebook',
    [SocialMedia.INSTAGRAM]: 'Instagram',
    [SocialMedia.TIKTOK]: 'TikTok',
    [SocialMedia.TWITTER]: 'Twitter',
    [SocialMedia.YOUTUBE]: 'YouTube',
}

export const getDefaultStyles = (
    recordSelected: any,
    brandDefaultStyles: DefaultStyles,
) => {
    const styleObject = {
        bgType: 'artwork',
        bgValue: {
            color: '#0B0113',
            artwork: 0,
        },
        mainColor: SystemColors.ACCENT_COLOR,
        secondaryColor: SystemColors.PRIMARY_TEXT_COLOR,
        styleType: 'boxes',
        themeStyle: 'vinyl',
        titleText: 'Listen To',
        subtitle: '',
        youtubeEmbed: {
            id: '',
            url: '',
        },
        removeSymphonyBrand: false,
        enableSocialIcons: false,
        socialIcons: [],
    } as DefaultStyles;

    if (recordSelected) {
        // these are styles set directly on the piece of content,
        // either from the user directly, or from the user's presave styles
        // that they set when creating their presave
        const { website: contentSpecificStyles, presave: presaveStyles } = recordSelected;

        // these are default styles that are set for the brand.

        // start by defaulting to the style saved within the
        // record directly
        let primaryStyle;
        if (contentSpecificStyles && contentSpecificStyles.style) {
            primaryStyle = contentSpecificStyles.style;
        } else if (presaveStyles && presaveStyles.style) {
            primaryStyle = presaveStyles.style;
        } else if (brandDefaultStyles) {
            primaryStyle = brandDefaultStyles;
        }

        if (primaryStyle) {
            // remove symphony brand
            if (brandDefaultStyles && brandDefaultStyles.removeSymphonyBrand) {
                styleObject.removeSymphonyBrand = brandDefaultStyles.removeSymphonyBrand;
            }

            if (brandDefaultStyles && brandDefaultStyles.enableSocialIcons) {
                styleObject.enableSocialIcons = brandDefaultStyles.enableSocialIcons;
            }

            // youtube embed
            if (primaryStyle.youtubeEmbed) {
                styleObject.youtubeEmbed = {
                    id: primaryStyle.youtubeEmbed.id,
                    url: primaryStyle.youtubeEmbed.url,
                };
            }

            styleObject.bgType = primaryStyle.bgType ?? 'artwork';

            if (primaryStyle.bgColor) {
                styleObject.bgValue = {
                    color: primaryStyle.bgColor,
                    artwork: 0,
                };
            }

            styleObject.styleType = primaryStyle.buttonConfig ?? 'boxes';
            styleObject.mainColor = primaryStyle.primaryColor ?? SystemColors.ACCENT_COLOR;
            styleObject.secondaryColor = primaryStyle.secondaryColor ?? SystemColors.PRIMARY_TEXT_COLOR;
            styleObject.titleText = primaryStyle.titleText ?? 'Listen To';
            styleObject.subtitle = primaryStyle.subtitle ?? '';
        }
    }

    return styleObject;
};

export const getIconBackgroundStyle = (sm?: WebsiteLink) => {
    if (!sm) return;
    switch (sm.key) {
        case 'facebook':
            return { backgroundColor: 'rgba(25, 119, 243, 0.16)' };
        case 'instagram':
            return { background: 'radial-gradient(122.91% 144.6% at 1.81% 90.83%, rgba(254, 213, 118, 0.16) 0%, rgba(244, 113, 51, 0.16) 26.34%, rgba(188, 48, 129, 0.16) 60.91%, rgba(76, 99, 210, 0.16) 100%)' };
        case 'tiktok':
            return { backgroundColor: 'rgba(0, 0, 0, 0.08)' };
        case 'twitter':
            return { backgroundColor: 'rgba(0, 0, 0, 0.20)' };
        case 'youtube':
            return { backgroundColor: 'rgba(255, 0, 0, 0.08)' };
        case 'spotify':
            return { backgroundColor: 'rgba(25, 119, 243, 0.16)' };
        case 'apple_music':
            return { background: 'radial-gradient(122.91% 144.6% at 1.81% 90.83%, rgba(254, 213, 118, 0.16) 0%, rgba(244, 113, 51, 0.16) 26.34%, rgba(188, 48, 129, 0.16) 60.91%, rgba(76, 99, 210, 0.16) 100%)' };
        case 'amazon_music':
            return { backgroundColor: 'rgba(0, 0, 0, 0.08)' };
        case 'deezer':
            return { backgroundColor: 'rgba(0, 0, 0, 0.20)' };
        case 'tidal':
            return { backgroundColor: 'rgba(0, 0, 0, 0.20)' };
        case 'yandex':
        case 'shazam':
            return { backgroundColor: 'rgba(255, 0, 0, 0.08)' };
        case 'soundcloud':
            return { backgroundColor: 'rgba(25, 119, 243, 0.16)' };
        case 'boomplay':
            return { backgroundColor: 'rgba(25, 119, 243, 0.16)' };
        case 'anghami':
            return { backgroundColor: 'rgba(25, 119, 243, 0.16)' };
        case 'audiomack':
            return { backgroundColor: 'rgba(255, 0, 0, 0.08)' };
        case 'website':
            return { backgroundColor: '#8800ff' };
        default:
            break;
    }
};

export const convertWebsiteLinkArrayToObject = (links: WebsiteLink[]) => {
    const linksObject: WebsiteLinks = {};
    links.forEach((link) => {
        linksObject[link.key] = link;
    });
    return linksObject;
}
