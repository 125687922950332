import { Fragment, useContext, useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import MainContentContainer from "../../../components/shareable/MainContentContainer";
import { pageView } from "../../../analytics";
import { GettingStartedContext } from "Hooks/GettingStartedContext";
import { ArtistPermissions, BrandContent, SYMPHONY_TASKS } from "types/global";
import useStyles, { Container, Footer } from "./styles";
import PaymentFailedBanner from "components/shareable/PaymentFailedBanner";
import { formattedWebsiteUrl, getStatusToShowPaymentFailedBanner, isBrandAdministrator } from "utils";
import { NewLoader } from "components/Loader/ConnectBrandLoader";
import TopBar from "components/shareable/TopBar";
import { WebsiteEditorV2 } from "./components/CustomizeWebsite/CustomizeWebsiteV2";
import getBrandContent from "./controllers/getBrandContent";
import { WebsiteType, WEBSITE_TYPES } from "./types";
import LinkContainer from "components/shareable/LinkContainer";
import { CurrentBrandContext } from "Hooks/CurrentBrandContext";
import PrimaryButton from "components/shareable/PrimaryButton";
import { SystemColors } from "types/globalStyles";
import useMediaBreakpoints from "Hooks/useMediaBreakpoints";
import { EditWebsiteSlugModal } from "../MarketingPage/Components/Modals/EditWebsiteSlugModal";
import { searchSpotifyReleases } from "../MarketingPage/Components/SuperboostModal/Components/shared-music-selector-utils";
import { PublishSiteModal } from "./components/PublishSiteModal";
import { WebsiteProvider } from "./components/CustomizeWebsite/hooks/WebsiteContext";
import { RenderRightSideTopBarContent } from './components/RightSideTopBarContent';
import { Drawer } from "@material-ui/core";
import WebsitePreviewV2 from "./components/WebsitePreview/WebsitePreviewV2";
import PublishedWebsiteModal from "./components/CustomizeWebsite/components/PublishedWebsiteModal";
import { extractSlugsFromFrontfacingLink } from "../MarketingPage/utils/links"

const MobileContent = (props: {
    websiteType: WebsiteType,
    brandContent: BrandContent,
    openDrawer: boolean,
    setOpenDrawer: (value: boolean) => void
    reloadBrands: () => void;
}) => {
    const classes = useStyles();
    const { websiteType, brandContent, openDrawer, setOpenDrawer, reloadBrands } = props;

    return (
        <>
            <div style={{ minHeight: '75%', overflowY: 'visible', overflowX: 'hidden' }}>
                <WebsitePreviewV2 websiteType={websiteType} fullView />
            </div>
            <Drawer
                open={openDrawer}
                anchor="bottom"
                onClose={() => setOpenDrawer(false)}
                PaperProps={{ className: classes.drawerPaper }}
            >
                <MainContentContainer
                    className="mainContentContainerIndex2 h-full w-full lg:w-12/12 xl:w-12/12 mx-auto lg:box-border relative"
                >
                    <WebsiteEditorV2 brandContent={brandContent} websiteType={websiteType} reloadBrands={reloadBrands} />
                    <Footer style={{ backgroundColor: SystemColors.BAR_COLOR }}>
                        <PrimaryButton text="Preview" onClick={() => setOpenDrawer(false)} />
                    </Footer>
                </MainContentContainer>
            </Drawer>
            <Footer style={{ backgroundColor: SystemColors.BAR_COLOR }}>
                <PrimaryButton text="Customize" onClick={() => setOpenDrawer(true)} />
            </Footer>
        </>
    )
}

interface WebsiteParams {
    websiteType: WebsiteType;
    websiteSlug?: string;
}
const style = {
    subTabPanel: {
        padding: 16,
        backgroundColor: "#F5F3F6",
    },
    tabPanelSetupContainer: {
        width: "100%",
    },
}

const { DATA_COLLECTOR, TOUR } = WEBSITE_TYPES

const Website = ({ reloadBrands }: { reloadBrands: () => void; }) => {
    const params = useParams<WebsiteParams>();
    const { websiteType, websiteSlug } = params;
    const location = useLocation()
    const url = new URLSearchParams(location.search).get("url")

    const history = useHistory();
    const { mobileView } = useMediaBreakpoints();

    const { currentBrand } = useContext(CurrentBrandContext)
    const hasAdminAccess = isBrandAdministrator(currentBrand!)
    const showPaymentFailedBanner = getStatusToShowPaymentFailedBanner(currentBrand)

    const [openDrawer, setOpenDrawer] = useState<boolean>(false)
    const [loadingContent, setLoadingContent] = useState<any>([])
    const [brandContent, setBrandContent] = useState<any>(null)
    const [openEditUrlModal, setOpenEditUrlModal] = useState<boolean>(false)
    const [openPublishModal, setOpenPublishModal] = useState<boolean>(false)
    const [openPublishedModal, setOpenPublishedModal] = useState<boolean>(false)
    const [websiteUrl, setWebsiteUrl] = useState<string>(formattedWebsiteUrl(currentBrand, brandContent))
    const linkSlugs = extractSlugsFromFrontfacingLink(websiteUrl, currentBrand?.slug!)
    const [localUrl, setLocalUrl] = useState(linkSlugs ? `${linkSlugs.brandSlug}-${linkSlugs.urlSlug}` : "")

    useEffect(() => {
        if (linkSlugs) setLocalUrl(`${linkSlugs.brandSlug}-${linkSlugs.urlSlug}`)
    }, [linkSlugs])

    const {
        finishedTasks,
        finishTask,
    } = useContext(GettingStartedContext)

    const { published } = brandContent?.content_metadata || {}

    const getWebsiteEditorData = async (newWebsiteSlug?: string) => {
        if (newWebsiteSlug) {
            setLoadingContent(true)
            const brandContent = await getBrandContent(currentBrand?.slug!, newWebsiteSlug)
            if (brandContent) {
                setBrandContent(brandContent)
            }
            setLoadingContent(false)
        } else if (url) {
            setLoadingContent(true)
            const response = await searchSpotifyReleases(url)
            if (response) {
                setBrandContent(response.data)
            }
            setLoadingContent(false)
        } else if (currentBrand && websiteSlug) {
            setLoadingContent(true)
            const brandContent = await getBrandContent(currentBrand.slug!, websiteSlug)
            if (brandContent) {
                setBrandContent(brandContent)
            } else if (websiteType === DATA_COLLECTOR) {
                const DefaultDataCollectorContent = {
                    name: "New data collector site",
                    type: DATA_COLLECTOR,
                    content_metadata: {
                        primary_artist: currentBrand.name,
                        tracks: [], // TODO: Remove once we verify it's not needed
                    },
                }
                setBrandContent(DefaultDataCollectorContent)
            } else if (websiteType === TOUR) {
                const DefaultTourContent = {
                    name: "New Events & Tours site",
                    type: TOUR,
                    content_metadata: {
                        primary_artist: currentBrand.name,
                    },
                }
                setBrandContent(DefaultTourContent)
            }
            setLoadingContent(false)
        }
    }

    // This is called when the page first loads

    useEffect(() => {
        pageView("Website")

        // this marks the task as complete for onboarding (for first time users)
        if (!finishedTasks.includes(SYMPHONY_TASKS.WEBSITE_CUSTOMIZED)) {
            finishTask(SYMPHONY_TASKS.WEBSITE_CUSTOMIZED);
        }

        // New funciton - uses the url param to pull in the specific content data
        // to laod the editor
        getWebsiteEditorData()
    }, [])

    useEffect(() => {
        if (currentBrand && brandContent) {
            setWebsiteUrl(formattedWebsiteUrl(currentBrand, brandContent))
        }
    }, [brandContent, currentBrand])

    const isProject = brandContent?.content_metadata?.tracks?.length > 0;
    const artistName = brandContent?.content_metadata?.primary_artist || currentBrand?.name;

    const onEditUrl = (newBrandContentSlug: string) => {
        const baseBrandUrlIndex = websiteUrl.lastIndexOf("/");
        const newWebsiteUrl = websiteUrl.slice(0, baseBrandUrlIndex + 1) + newBrandContentSlug;
        setWebsiteUrl(newWebsiteUrl);
        getWebsiteEditorData(newBrandContentSlug);
    }

    const renderCustomizeLoader = () => {
        return (
            <div className="flex items-center justify-center h-full">
                <div className="flex-col flex items-center">
                    <NewLoader black />
                    <p className="mt-1 text-center">Loading Website...</p>
                </div>
            </div>
        )
    }

    const renderUrlComponent = () => {
        if (!websiteUrl) return
        return (
            <LinkContainer
                url={websiteUrl}
                showShareButton
                showEditButton={hasAdminAccess}
                width={mobileView ? "100%" : "70%"}
                padding="8px 16px"
                margin={mobileView ? "8px 0px 16px 0" : '0'}
                buttonTextColor={SystemColors.ACCENT_COLOR_ON_DARK}
                backgroundColor={SystemColors.PAPER_DIVIDER_CONTAINER_LIGHT}
                textColor={SystemColors.DISABLED_TEXT_COLOR}
                onOpenEditLinkModal={() => setOpenEditUrlModal(true)}
            />
        )
    }

    const handleOpenPublishModal = () => setOpenPublishModal(true)

    let topBarTitle = "";
    if (loadingContent || !brandContent) {
        topBarTitle = 'Loading website details...';
    } else if (websiteType === DATA_COLLECTOR) {
        topBarTitle = `${artistName} - ${brandContent.name}`
    } else if (websiteType === TOUR) {
        topBarTitle = `${artistName} - ${brandContent.name}`
    } else {
        topBarTitle = `${artistName} - ${brandContent.name} - ${isProject ? "Album" : "Single"}`
    }

    if (loadingContent) return renderCustomizeLoader()

    return (
        <Fragment>
            <WebsiteProvider brandContent={brandContent} websiteType={websiteType}>
                <div className={`websiteIndex2 relative ${mobileView && 'h-full'}`} style={{ backgroundColor: SystemColors.ACCENT_COLOR }}>
                    {showPaymentFailedBanner && (
                        <Container maxWidth="1200px" margin="0 auto" width="100%">
                            <PaymentFailedBanner withBorderRadius={false}
                                customerId={currentBrand?.subscription?.cid} />
                        </Container>
                    )}
                    <TopBar
                        renderRightSideContent={
                            <RenderRightSideTopBarContent
                                brandContent={brandContent}
                                published={published}
                                mobileView={mobileView}
                                handleOpenPublishModal={handleOpenPublishModal}
                                renderUrlComponent={renderUrlComponent}
                            />
                        }
                        renderDownSideContent={(published && mobileView) && renderUrlComponent()}
                        showCloseIcon
                        hideGetSupportLabel
                        handleClose={() => history.goBack()}
                        title={topBarTitle}
                    />
                    {mobileView ?
                        <MobileContent
                            {...{
                                brandContent,
                                websiteType,
                                openDrawer,
                                setOpenDrawer,
                                reloadBrands,
                            }}
                        />
                        :
                        <MainContentContainer
                            className="mainContentContainerIndex2 h-full w-full lg:w-12/12 xl:w-12/12 mx-auto lg:box-border relative"
                        >
                            <WebsiteEditorV2 brandContent={brandContent} websiteType={websiteType} reloadBrands={reloadBrands} />
                        </MainContentContainer>
                    }
                    <EditWebsiteSlugModal
                        open={openEditUrlModal}
                        closeModal={() => setOpenEditUrlModal(false)}
                        brandSlug={currentBrand?.slug!}
                        brandId={currentBrand?.id!}
                        updatingContentSlug={linkSlugs?.urlSlug || brandContent.slug || websiteSlug!}
                        onSave={(newSlug: string) => onEditUrl(newSlug)}
                        url={localUrl}
                        onSaveUrl={(newSlug: string) => {
                            setLocalUrl(newSlug)
                        }}
                    />
                    <PublishSiteModal
                        open={openPublishModal}
                        onClose={() => setOpenPublishModal(false)}
                        brandContent={brandContent}
                        originalSlug={brandContent?.slug || websiteSlug!}
                        onPublished={() => setOpenPublishedModal(true)}
                        refreshSiteData={getWebsiteEditorData}
                    />
                    <PublishedWebsiteModal
                        open={openPublishedModal}
                        websiteUrl={websiteUrl}
                        onClose={() => setOpenPublishedModal(false)}
                    />
                </div>
            </WebsiteProvider>
        </Fragment >
    );
};



export default Website;
