import { FunctionComponent, useContext, useEffect, useMemo, useState } from "react"
import { ReactComponent as WarningIcon } from "assets/images/campaignDetails/warning.svg"
import {
  Body2,
  Caption,
  Subtitle2,
} from "components/shareable/Typography"
import { SystemColors } from "types/globalStyles"
import LinearProgress from "@material-ui/core/LinearProgress"
import useStyles, {
  Card,
  ProgressContainer,
  FlexContainer,
  MainContainer,
  FeeContainer,
} from "./styles"
import { OrganizationBillingData, SpendBudgetData } from "types/global"
import millify from "millify"
import getSymbolFromCurrency from "currency-symbol-map"
import { getBillingInfo } from "services/symphonyApi/organizationService"
import dayjs from "dayjs"
import { checkIfIsProTier } from "utils"
import { CurrentBrandContext } from "Hooks/CurrentBrandContext"

type Props = SpendBudgetData

const { WARNING_COLOR, PRIMARY_TEXT_COLOR, SECONDARY_TEXT_COLOR } = SystemColors

const format = (value: number, showDigits?: boolean) => {
  const numberOfDigits = showDigits ? 2 : 0

  return (value || 0).toLocaleString("en-US", {
    minimumFractionDigits: numberOfDigits,
    maximumFractionDigits: numberOfDigits,
  })
}

const SpendLimitAlertCard: FunctionComponent<Props> = ({
  spent,
  isOrganization,
  feePrice,
  feeTotal,
  feeUnit,
  newSpent,
  spentLeft,
  spentLimit,
  status,
  spentToDate,
  newSpentLimit,
  total,
  disableSpendLimit,
  firstTimeSpending,
  currencyCode,
}: Props) => {
  const classes = useStyles()
  const { currentBrand } = useContext(CurrentBrandContext)
  const isProUser = checkIfIsProTier(currentBrand)

  let percentage = 0
  let bufferValue = 0
  let spentThisMonth = 0
  const currentCampaignBudget = newSpent - spentToDate
  const showCurrencyCode = currencyCode && currencyCode !== 'USD' ? ` ${currencyCode}` : ''

  const [loading, setLoading] = useState<boolean>(false)
  const [billingData, setBillingData] = useState<OrganizationBillingData>()
  const subscriptionActive = useMemo(() => !["canceled"].includes(billingData?.status!), [billingData])

  const initBillingData = async () => {
    setLoading(true)
    const response = await getBillingInfo()
    if (response) setBillingData(response)
    setLoading(false)
  }

  useEffect(() => {
    initBillingData()
  }, [])

  if (spentToDate === 0 && newSpent > 0) {
    percentage = (newSpent * 100) / newSpentLimit
    spentThisMonth = newSpent
  } else {
    percentage = (spentToDate * 100) / newSpentLimit
    bufferValue = ((newSpent) * 100) / newSpentLimit
    spentThisMonth = spentToDate
  }

  const freeLimit = newSpentLimit === feeUnit
  const currencySymbol = currencyCode ? getSymbolFromCurrency(currencyCode) : ''

  function renderExplainerText() {
    if (firstTimeSpending) {
      return `If you want to spend more than ${currencySymbol}${format(newSpentLimit)}${showCurrencyCode} a month, we charge an automation fee of ${currencySymbol}${format(feePrice)}${showCurrencyCode} on additional ad budget.`
    } else {
      if (isOrganization) {
        return `If you want to spend more than ${currencySymbol}${format(newSpentLimit)}${showCurrencyCode} a month across all of your Team's profiles, we charge a 2% automation fee on any additional ad budget you spend.`
      } else {
        return `If you want to spend more than ${currencySymbol}${format(newSpentLimit)}${showCurrencyCode} a month, we charge an automation fee of ${currencySymbol}${format(feePrice)}${showCurrencyCode} on additional ad budget.`
      }
    }
  }

  if (disableSpendLimit) {
    return null
  }

  let cardBgColor = null
  let primaryTextColor = WARNING_COLOR
  let iconColor = classes.warningIcon
  let barProgressColor = classes.colorPrimary
  let barBackgroundColor = classes.barColorPrimary
  let automationFeeSubtitle = `+${currencySymbol}${format(feePrice)}${showCurrencyCode} per additional ${currencySymbol}${format(feeUnit)}`

  if (isOrganization) {
    automationFeeSubtitle = '2% of additional spend over $7,500'
  }

  if (firstTimeSpending || status !== 'exceeded') {
    cardBgColor = SystemColors.DARK_SUCCESS
    primaryTextColor = SystemColors.GREEN_COLOR
    iconColor = classes.positiveIcon
    barProgressColor = classes.zeroState_BarColorPrimary
    barBackgroundColor = classes.zeroState_BarColorSecondary
  }

  return (
    <MainContainer>
      <Card
        backgroundColor={cardBgColor}>
        <FlexContainer gap="8px">
          <WarningIcon className={`${iconColor} flex-shrink-0`} height={16} />
          <Subtitle2 color={primaryTextColor}>
            {renderExplainerText()}

          </Subtitle2>
        </FlexContainer>
        <ProgressContainer>
          <LinearProgress
            classes={{
              root: classes.root,
              colorPrimary: barBackgroundColor,
              barColorPrimary: barProgressColor,
            }}
            className=""
            color="primary"
            variant="buffer"
            value={percentage}
            valueBuffer={bufferValue}
          />
        </ProgressContainer>
        <FlexContainer
          alignItems="flex-start"
          justifyContent="space-between"
          marginTop={4}
        >
          <FlexContainer flexDirection="column">
            <Subtitle2 color={SECONDARY_TEXT_COLOR}
              weight={400}>
              {firstTimeSpending ? `Your budget: ${currencySymbol}${format(spentThisMonth)}` : `New Monthly Spend: ${currencySymbol}${format(newSpent)}`}
            </Subtitle2>
            {!firstTimeSpending && (
              <>
                {!loading && billingData && subscriptionActive && isProUser && (
                  <Caption color={SECONDARY_TEXT_COLOR}>
                    Billing Period: {dayjs(billingData.nextBillingDate).subtract(1, 'month').format('MMM DD')} - {dayjs(billingData.nextBillingDate).format("MMM DD")}
                  </Caption>
                )}
                <Caption color={SECONDARY_TEXT_COLOR}>
                  This campaign's spend: {currencySymbol}{format(currentCampaignBudget)}
                </Caption>
                <Caption color={SECONDARY_TEXT_COLOR}>
                  Spent this month: {currencySymbol}{format(spentThisMonth)}
                </Caption>
              </>
            )}
          </FlexContainer>
          <Subtitle2 color={SECONDARY_TEXT_COLOR}
            weight={400}>
            {currencySymbol}{millify(newSpentLimit)} Limit
          </Subtitle2>
        </FlexContainer>
      </Card>
      {feeTotal > 0 && (
        <>
          <FeeContainer>
            <FlexContainer alignItems="center" justifyContent="space-between">
              <Body2 color={PRIMARY_TEXT_COLOR}>Automation Fee </Body2>
              <Body2 color={PRIMARY_TEXT_COLOR}>+{currencySymbol}{format(feeTotal, true)}</Body2>
            </FlexContainer>
            <FlexContainer>
              <Caption color={SECONDARY_TEXT_COLOR}>
                {automationFeeSubtitle}
              </Caption>
            </FlexContainer>
          </FeeContainer>
          {!isOrganization && (<FeeContainer>
            <FlexContainer alignItems="center" justifyContent="space-between">
              <Subtitle2 color={PRIMARY_TEXT_COLOR}>Your New Monthly Ad Spend Limit</Subtitle2>
              <Subtitle2 color={PRIMARY_TEXT_COLOR}>{currencySymbol}{format(newSpentLimit)}{showCurrencyCode}</Subtitle2>
            </FlexContainer>
          </FeeContainer>)}
        </>
      )}
    </MainContainer>
  )
}

export default SpendLimitAlertCard
