import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import GeneralPurpleButton from '../PurpleButton';
import ClickableChip from 'pages/post-auth/MarketingPage/Components/CreateCampaignBadge';

interface CampaignItem {
  key: string;
  title: string;
  icon: string;
  link?: string | undefined;
  isWebsite?: boolean;
}

interface CustomCampaignSelectorProps {
  buttonText?: string;
  onButtonClick?: () => void;
  campaignItems: CampaignItem[];
  onCampaignItemClick: (campaignKey: string) => void;
  additionalItemsText?: string;
  hidePrimaryButton?: boolean;
  stackedView?: boolean;
  mobileView?: boolean;
}

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media (min-width: 801px) {
    flex-direction: row;
    align-items: center;
  }
`;

const ButtonContainer = styled.div`
  width: 100%;
  padding: 0px 16px;

  @media (min-width: 801px) {
    width: auto;
  }
`;

const ItemsContainer = styled.div`
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  white-space: nowrap;
  width: 100%;
  align-items: center;
  gap: 8px;

  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

const PopularCampaignsText = styled.p`
  margin-left: 16px;
`;

const ChipContainer = styled.div`
  margin: 0 8px;
`;

const DefaultAdditionalActions: React.FC<CustomCampaignSelectorProps> = ({
  buttonText,
  onButtonClick,
  campaignItems,
  onCampaignItemClick,
  additionalItemsText,
  hidePrimaryButton = false,
  stackedView = false,
  mobileView = false
}) => {
  return (
    <Container style={{
      marginBottom: stackedView ? '0px' : mobileView ? '8px' : '16px',
      marginTop: mobileView ? '0.75rem' : '16px',
    }}>
      {!hidePrimaryButton && buttonText && onButtonClick && (
        <ButtonContainer>
          <GeneralPurpleButton fullWidthOnMobile onClick={onButtonClick}>
            {buttonText}
          </GeneralPurpleButton>
        </ButtonContainer>
      )}
      <ItemsContainer
        style={{
          paddingLeft: stackedView ? '16px' : '0px',
          paddingRight: stackedView ? '16px' : '0px',
        }}
      >
        {additionalItemsText && <PopularCampaignsText>{additionalItemsText}</PopularCampaignsText>}
        {campaignItems.map((item) => (
          <ChipContainer style={{
            marginLeft: stackedView ? '0px' : '8px',
            marginRight: stackedView ? '0px' : '8px',
          }} key={item.key}>
            {item.link ? (
              <Link
                to={item.link}
                onClick={() => onCampaignItemClick(item.key)}
              >
                <ClickableChip
                  stacked={stackedView}
                  onClick={() => {}}
                  campaignImage={item.icon}
                  campaignName={item.title}
                  campaignType="title"
                />
              </Link>
            ) : (
              <div onClick={() => onCampaignItemClick(item.key)}>
                <ClickableChip
                  stacked={stackedView}
                  onClick={() => {}}
                  campaignImage={item.icon}
                  campaignName={item.title}
                  campaignType="title"
                />
              </div>
            )}
          </ChipContainer>
        ))}
      </ItemsContainer>
    </Container>
  );
};

export default DefaultAdditionalActions;