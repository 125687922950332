import {
    FunctionComponent,
    SetStateAction,
    useState,
    Dispatch,
    useContext,
} from "react";
import Grid from "@material-ui/core/Grid";
import clsx from "clsx";
import CircularStep from "../CircularStep";
import { CurrentBrand, OrganizationData } from "types/global";
import * as Sentry from "@sentry/react";

import FB from "helpers/FB";
import { useMediaQuery, useTheme } from "@material-ui/core";
import { SNACKBAR_WRONG_TARGETING_MESSAGE, TargetedCountries, showFixTargetingSnackbar, showToast } from "utils";
import { BillingCycle } from "components/shareable/UpgradeToProModal/utils";
import { useStyles, Card } from "./styles";
import { track } from "analytics";
import Axios from "helpers/Interceptor";
import { StyledLabel } from "styles/shared";
import SubmitCampaignOrUpgrade from "./SubmitCampaignOrUpgrade";
import { CampaignInput } from "./types";
import { SpendBudgetContext } from "Hooks/BudgetTabSpendContext";
import { CurrentBrandContext } from "Hooks/CurrentBrandContext";
import { ConnectionsContext } from "../../hooks/ConnectionsContext";

interface Props {
    budget: number;
    primaryOrganization?: OrganizationData;
    creatingCampaign?: boolean;
    isProUser: boolean;
    campaignInput: CampaignInput;
    allowCampaignCreation: Dispatch<SetStateAction<boolean>>;
    onCancelPayPalOrder?: () => void;
    onErrorPayPalOrder?: () => void;
    setCreatingCampaign: Dispatch<SetStateAction<boolean>>;
    cancelSaving: () => void;
    validateCountriesToFix: () => boolean;
    submissionName?: string;
    submissionDescription?: string;
    checkout_source: string;
}



export const redirectToCheckout = async (
    brand: CurrentBrand,
    data: Record<string, unknown>,
) => {
    const campaignInput = data.campaign_input as {
        campaign_type: string,
        conversionsEnabled: boolean
    };
    track("Completed Campaign Step - Checkout", {
        type: campaignInput?.campaign_type,
        step: 4,
        name: "confirmation",
        brand_id: brand.id,
        brand_name: brand.name,
        brand_slug: brand.slug,
        payment_method: "stripe",
        conversionsEnabled: Boolean(campaignInput?.conversionsEnabled),
        conversionsSetup: Boolean(brand.conversionsStatus?.conversions_tasks_completed),
    });

    try {
        const response = await Axios.post(
            `/campaign/brand/${brand.id}/checkout`,
            data
        );

        window.location = response.data.data.url;
    } catch (error) {
        Sentry.captureException(error);
        console.error("error: ", error);
        return { error: true }
    }
};

const GoLiveCard: FunctionComponent<Props> = ({
    budget,
    primaryOrganization,
    creatingCampaign,
    isProUser,
    campaignInput,
    allowCampaignCreation,
    setCreatingCampaign,
    cancelSaving,
    validateCountriesToFix,
    submissionName,
    submissionDescription,
    checkout_source
}: Props) => {

    const { currentBrand: brand } = useContext(CurrentBrandContext);
    const { loggedInFbUser } = useContext(ConnectionsContext);
    const [selectedBillingCycle, setBillingCycle] = useState<BillingCycle>(BillingCycle.MONTHLY) // [BillingCycle.MONTHLY, BillingCycle.ANNUALLY

    // to show Currency for Pro campaigns
    const { data: spendBudgetData, currency } = useContext(SpendBudgetContext)

    const classes = useStyles();
    const theme = useTheme()
    const [error, setError] = useState(false);

    const isMobile = useMediaQuery(theme.breakpoints.down(768))
    const { targetedCountries } = campaignInput
    const targeted = targetedCountries as unknown as TargetedCountries[]

    const handleClickStripeButton = async () => {
        if (!validateCountriesToFix()) {
            return
        }

        const showSnackbar = showFixTargetingSnackbar(targeted)

        if (showSnackbar) {
            showToast({
                message: SNACKBAR_WRONG_TARGETING_MESSAGE,
                error: true,
                mobile: isMobile,
            })
            return
        }

        cancelSaving();
        if (error) setError(false);

        const localStorageItem = localStorage.getItem("facebook_page") || "{}";
        const facebookPage = JSON.parse(localStorageItem);

        let accessToken = loggedInFbUser?.access_token
        if (!accessToken) {
            const authResp = FB().getAuthResponse();
            accessToken = authResp.accessToken
        }

        const data = {
            page_access_token: facebookPage?.access_token,
            access_token: accessToken,
            campaign_input: {
                price_data: {
                    price: budget,
                    name: submissionName,
                    description: submissionDescription,
                },
                payment_method: "stripe",
                ...campaignInput,
            },
            checkout_source: checkout_source,
            recurring_interval: selectedBillingCycle,
            isOrganizationUpgrade: !isProUser && (primaryOrganization?.status === 'ready')

        };

        setCreatingCampaign(true);

        const { error: checkoutError } =
            (await redirectToCheckout(brand!, data)) || {};

        if (checkoutError) {
            showToast({
                message: 'Something went wrong. Please contact support.',
                error: true,
                mobile: isMobile,
            });
            setError(true);
        }
    };

    const handleClickSubmitButton = () => {
        if (!validateCountriesToFix()) {
            return
        }

        const showSnackbar = showFixTargetingSnackbar(targeted)

        if (showSnackbar) {
            showToast({
                message: SNACKBAR_WRONG_TARGETING_MESSAGE,
                error: true,
                mobile: isMobile,
            })
            return
        }

        cancelSaving();
        allowCampaignCreation(true);
    };

    return (
        <Card borderRadius={isMobile ? undefined : "12px"}>
            <Grid container direction="column">
                <Grid item className={clsx(classes.space, classes.border)}>
                    <Grid container alignItems="center" spacing={2}>
                        <Grid item>
                            <CircularStep step="⚡" />
                        </Grid>
                        <StyledLabel fontSize={18} fontWeight={600}>
                            Let’s go live
                        </StyledLabel>
                    </Grid>
                </Grid>
                <SubmitCampaignOrUpgrade
                    primaryOrganization={primaryOrganization}
                    currencyCode={currency}
                    spendBudgetData={spendBudgetData}
                    selectedBillingCycle={selectedBillingCycle}
                    setBillingCycle={setBillingCycle}
                    mobileView={isMobile}
                    error={error}
                    creatingCampaign={creatingCampaign}
                    handleClickUpgradeButton={handleClickStripeButton}
                    handleClickSubmitButton={handleClickSubmitButton} />
            </Grid>
        </Card>
    );
};

export default GoLiveCard;
