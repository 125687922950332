import React, { ChangeEvent, useContext, useEffect, useMemo, useState } from "react";
import { TabPanel, useTabs } from "react-headless-tabs";
import { useLocation, Link } from "react-router-dom";
import { useMediaQuery, useTheme } from "@material-ui/core";
import { CONVERSIONS_VIDEOS, CampaignDetails, MarketingCampaignType, UserData, ArtistPermissions } from "types/global";
import useStyles, { BannerContainer, ContentContainer, MainContainer, TabsContainer, TabsPanelsContainer } from './styles';
import CardTitle from "components/shareable/CommonComponent/CardTitle";
import SymphonyTabs from "components/shareable/SymphonyTabs";
import GeneralPurpleButton from "components/shareable/CommonComponent/PurpleButton";
import ClickableChip from "./Components/CreateCampaignBadge";
import CreateNewCampaignModal from "./Components/CreateNewCampaignModal";
import { availableGoals, CAMPAIGN_TYPE_MAPPINGS } from "./utils/marketing";
import { CurrentBrandContext } from "Hooks/CurrentBrandContext";
import SuperboostSection from "./Components/SuperboostSection";
import CampaignsSection from "./Components/CampaignsSection";
import ToolsTab from './Components/ToolsTab';
import { trackNewCampaign } from "./Components/CampaignsSection/analytics";
import ConversionsCallToAction from "./Components/ConversionsCallToAction";
import useBanner from "Hooks/useBanner";
import * as Sentry from "@sentry/react";
import WarningNoReleaseUpc from './PreSave/Components/WarningNoReleaseUpc';
import PaymentFailedBanner from "components/shareable/PaymentFailedBanner";
import { getStatusToShowPaymentFailedBanner, isBrandAdministrator } from "utils";
import DefaultLearningIndicator, { LearningIndicatorType } from "components/shareable/LearningIndicator/default";
import { healthCheckCampaign } from "services/facebookApi";
import { Container, OutlinedButton } from "styles/shared";
import DropdownArrow from "components/shareable/DropdownArrow";
import { AnimatePresence, AnimatePresenceProps } from "framer-motion";
import CampaignTypesFilter from "./Components/CampaignTypesFilter";
import { CampaignFilterType } from "./Components/CampaignTypesFilter/types";
import { isAdmin } from "helpers/admin";
import { StyledNavBar } from "styles/shared";
import { DEFAULT_CAMPAIGN_PARAMS } from "./constants";
import DefaultAdditionalActions from "components/shareable/CommonComponent/CardTitle/DefaultAdditionalActions";

interface NewAnimatePresenceProps extends Omit<AnimatePresenceProps, "children"> {
  children: React.ReactNode;
}

export function AnimatedComponent({ children }: { children: React.ReactNode }) {
  const NewAnimatePresence: React.FC<NewAnimatePresenceProps> = AnimatePresence;

  return <NewAnimatePresence>{children}</NewAnimatePresence>
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const orderNoSpotifyCampaigns = [
  "grow_instagram_engagement",
  "increase_link_clicks",
  "grow_playlist_followers",
  "increase_video_views",
  "increase_record_streams",
  "get_playlisted",
  "pre_save",
  "tiktok_ads"
];

const hiddenCampaigns = ['creative_generator']

const sortedCampaigns = (data: { noSpotifyConnected?: boolean }) => {
  const { noSpotifyConnected } = data;
  if (noSpotifyConnected) {
    const sortedCampaignsArray: any[] = [];
    orderNoSpotifyCampaigns.forEach((campaignKey) => sortedCampaignsArray.push(availableGoals[campaignKey]))
    return sortedCampaignsArray;
  } else {
    return Object.values(availableGoals);
  }
}

enum MarketingPageTabs {
  CAMPAIGNS = 'campaigns',
  TOOLS = 'tools',
  INSTAGRAM = 'instagram',
}

const GetStarted = (props: {
  loadingLocally: boolean;
  currentCampaigns: CampaignDetails[];
  setGoal: (goal: string) => void;
  isSpotifyConnected: boolean;
  getCampaigns: (filterObject: CampaignFilterType, localLoading?: boolean) => void;
  loadingCampaigns: boolean,
  currentUser?: UserData;
  returnedAllCampaigns: boolean;
  loadMoreCampaigns: () => void;
  loadingMore: boolean;
  setCampaignParams(campaignParams: CampaignFilterType): void;
  quantityCampaigns: number;
}) => {
  const {
    currentCampaigns,
    loadingLocally,
    setGoal,
    isSpotifyConnected,
    getCampaigns,
    currentUser,
    returnedAllCampaigns,
    loadMoreCampaigns,
    loadingMore,
    setCampaignParams,
    quantityCampaigns
  } = props;

  const { currentBrand } = useContext(CurrentBrandContext);

  const hasAdminAccess = isBrandAdministrator(currentBrand!);

  let campaigns: CampaignDetails[] = []
  const currentUserIsAdmin = currentUser ? isAdmin(currentUser) : false

  try {
    campaigns = currentCampaigns
      ?.filter(({ status }: { status: string }) => status !== 'DRAFT')
      .filter(({ campaign_metadata }: { campaign_metadata: { campaign_type: string } }) =>
        !hiddenCampaigns.includes(campaign_metadata.campaign_type)
      )

    if (campaigns) {
      const campaignHealthChecks: (Promise<void> | null)[] | null = campaigns.map((campaign: CampaignDetails) => {
        const { campaign_type } = campaign.campaign_metadata
        const validCampaigns = ['link_clicks', 'record_streams', 'boost_instagram', 'grow_playlist_followers']

        if (
          currentBrand?.id &&
          campaign.status === 'ACTIVE' &&
          validCampaigns.includes(campaign_type)
        ) {
          return healthCheckCampaign(currentBrand?.id, campaign.id)
        }

        return null
      })

      Promise.all(campaignHealthChecks)
    }
  } catch (error) {
    Sentry.captureException(error);
  }



  const showPaymentFailedBanner = getStatusToShowPaymentFailedBanner(currentBrand)

  const creativeGeneratorCampaigns = currentCampaigns?.filter((
    { campaign_metadata }: { campaign_metadata: { campaign_type: string } }
  ) => campaign_metadata.campaign_type === "creative_generator");

  const theme = useTheme();
  const setResponsiveView = useMediaQuery(theme.breakpoints.down(701));

  const { conversions_tasks_completed, isError, conversions_tasks } = currentBrand?.conversionsStatus || {};
  const showCallToAction = Boolean(!conversions_tasks_completed || isError)
  const conversionsCallToAction = useBanner({ initiallyVisible: showCallToAction });

  // Tabs
  const query = useQuery();

  const classes = useStyles();
  const tabs = [MarketingPageTabs.CAMPAIGNS, MarketingPageTabs.TOOLS];
  const [selectedTab, setSelectedTab] = useTabs(tabs, MarketingPageTabs.CAMPAIGNS);



  // used to show new modal popup 
  const [openCreateModal, setOpenCreateModal] = useState<boolean>(false)

  const lastPreference = localStorage.getItem('showFilters')
  const savedFiltersPreference = lastPreference ? JSON.parse(lastPreference) : true;
  const [showFilters, setShowFilters] = useState<boolean>(savedFiltersPreference);

  useEffect(() => {
    localStorage.setItem('showFilters', JSON.stringify(showFilters));
  }, [showFilters]);

  const openCreateCampaignModal = () => setOpenCreateModal(true)
  const closeCreateCampaignModal = () => setOpenCreateModal(false)

  const [campaignsInDraft, setCampaignsInDraft] = useState<CampaignDetails[]>([])
  const [campaignWasDeleted, setCampaignWasDeleted] = useState(false)
  const draftsCount = useMemo(() => campaignsInDraft ? campaignsInDraft.length : 0, [campaignsInDraft])

  const handleSelectGoal = (goal: string) => () => { setGoal(goal) }

  const moreThanOneCampaign = campaigns
    && campaigns.filter((campaign: CampaignDetails) =>
      campaign?.campaign_metadata?.campaign_type !== "creative_generator").length >= 1;

  const onDraftDelete = (campaignId: number) => {
    const filteredCampaigns = (
      campaignsInDraft
    ).filter(({ id }: { id: number }) => id !== campaignId)
    setCampaignsInDraft(filteredCampaigns)
    setCampaignWasDeleted(true)
  }

  useEffect(() => {
    if (currentCampaigns) {
      const filteredCampaigns = currentCampaigns.filter((campaign: CampaignDetails) => {
        const { campaign_metadata, status } = campaign;
        if (!campaign_metadata || status !== "DRAFT") return false;
        const { content, campaign_type } = campaign_metadata;
        const mappingData = CAMPAIGN_TYPE_MAPPINGS[campaign_type as string];
        return Boolean(content) && Boolean(mappingData);
      })
        .slice(0, 3)
      // only show 3 campaign drafts
      setCampaignsInDraft(filteredCampaigns)
    }

  }, [currentCampaigns]);

  useEffect(() => {
    // if (!draftsCount && campaignWasDeleted) setSelectedTab("new")
    if (campaignWasDeleted) setCampaignWasDeleted(false)
  }, [draftsCount, campaignWasDeleted])

  const POPULAR_CAMPAIGNS = Object.values(availableGoals)
    .filter((o: MarketingCampaignType) => {
      return [
        "increase_record_streams",
        // "grow_instagram_engagement",
        // "pre_save"
        "pre_save",
        'increase_video_views',
        // 'get_playlisted'
      ].indexOf(o.key) !== -1
    })
    .map((goal: MarketingCampaignType) => {
      return {
        ...goal
      }
    })

  const tabsToShow = [
    {
      value: MarketingPageTabs.CAMPAIGNS,
      label: "Campaigns"
    }
  ]

  if (creativeGeneratorCampaigns && creativeGeneratorCampaigns.length > 0) {
    tabsToShow.push({
      value: MarketingPageTabs.TOOLS,
      label: "Tools"
    })
  }

  const [filters, setFilters] = useState<CampaignFilterType>(DEFAULT_CAMPAIGN_PARAMS)
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    if (currentBrand) {
      getCampaigns(filters, true)
    }
  }, [filters])


  // handles when filters are updated
  function handleFiltersUpdate(filters: CampaignFilterType) {
    setCampaignParams(filters)
    setFilters(filters)
  }

  function renderFilterSection() {
    return showFilters && quantityCampaigns > 3 && <CampaignTypesFilter updatedAppliedFilterObject={handleFiltersUpdate} searchQuery={searchQuery} />
  }

  // if there are already campaigns and its locally loading, show the loader over the current items
  const showLoader = loadingLocally

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value)
  }

  return (
    <>
      <MainContainer
        flexDirection={"column"}
        padding={setResponsiveView ? "0 0 92px 0" : "32px 80px"}
      >
        {showPaymentFailedBanner && (
          <BannerContainer>
            <PaymentFailedBanner customerId={currentBrand?.subscription?.cid} />
          </BannerContainer>
        )}
        <ContentContainer>
          <CardTitle title="Marketing"
            useDefaultPadding={false}
            titleTextPadding='16px 24px'
            subtitle="Grow your fanbase with AI-powered marketing."
            mobile={setResponsiveView}
            additionalContent={
              <DefaultLearningIndicator
                text="Learn how to use Symphony's Marketing tools"
                type={LearningIndicatorType.MARKETING}
              />}
            additionalButtons={(
              <>
                {currentCampaigns && currentCampaigns.length > 0 && hasAdminAccess && (
                  <DefaultAdditionalActions
                    buttonText="Start New Campaign"
                    additionalItemsText="Popular Campaigns:"
                    onButtonClick={openCreateCampaignModal}
                    campaignItems={POPULAR_CAMPAIGNS.filter(campaignType => !campaignType.underMaintenance).map(campaignType => ({
                      key: campaignType.link,
                      title: campaignType.title,
                      icon: campaignType.icon,
                      link: `/marketing/new/${campaignType.link}`
                    }))}
                    onCampaignItemClick={(campaignType: any) => {
                      trackNewCampaign({
                        type: campaignType,
                        from: 'popular',
                        zeroState: false,
                        aiQuestion: null,
                        currentBrand: currentBrand
                      });
                    }}
                  />
                )}
              </>
            )}
          />
          {!setResponsiveView && conversionsCallToAction.visible && hasAdminAccess && (
            <ConversionsCallToAction
              videoUrl={CONVERSIONS_VIDEOS.onboardingVideo.videoUrl}
              hide={conversionsCallToAction.hide}
              conversionsTasks={conversions_tasks}
              rounded
            />
          )}
          <WarningNoReleaseUpc campaigns={campaigns} />
          <TabsContainer borderRadius={setResponsiveView ? "0px" : "8px"} padding="16px 40px 16px 40px">
            <StyledNavBar>
              <SymphonyTabs
                selectedValue={selectedTab!}
                onChange={(value) => setSelectedTab(value as MarketingPageTabs)}
                data={tabsToShow}
              />
              <Container marginLeft="auto" display="flex" gap="8px">
                {/* 
                TODO: uncomment this when search is needed
                <TextField
                  variant="outlined"
                  size="small"
                  placeholder="Search..."
                  value={searchQuery}
                  onChange={handleSearchChange}
                  className={classes.textField}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />              */}
                {quantityCampaigns > 3 && (
                  <OutlinedButton
                    padding="4px 12px"
                    onClick={() => setShowFilters(!showFilters)}
                  >
                    <div style={{
                      transform: `translateY(1px)`,
                    }}>
                      Filters
                    </div>
                    <div className="ml-1">
                      <DropdownArrow
                        color={"#8800FF"}
                        width={16}
                        height={16}
                        direction={showFilters ? "up" : "down"} />
                    </div>
                  </OutlinedButton>
                )}
              </Container>
            </StyledNavBar>
            <TabsPanelsContainer>
              <TabPanel hidden={selectedTab !== MarketingPageTabs.CAMPAIGNS}>
                {renderFilterSection()}
                <CampaignsSection
                  addedFilters={filters !== DEFAULT_CAMPAIGN_PARAMS}
                  locallyLoading={showLoader}
                  isAdmin={currentUserIsAdmin}
                  campaigns={campaigns}
                  campaignsInDraft={campaignsInDraft}
                  onDraftDelete={onDraftDelete}
                  fullyReturned={returnedAllCampaigns}
                  loadingMore={loadingMore}
                  loadNextPage={() => {
                    if (!returnedAllCampaigns) {
                      loadMoreCampaigns()
                    }
                  }}
                />
              </TabPanel>
              <TabPanel hidden={selectedTab !== MarketingPageTabs.INSTAGRAM}>
                <SuperboostSection
                  currentBrand={currentBrand}
                />
              </TabPanel>
              {creativeGeneratorCampaigns && creativeGeneratorCampaigns.length > 0 && (
                <TabPanel hidden={selectedTab !== MarketingPageTabs.TOOLS}>
                  <ToolsTab
                    campaigns={creativeGeneratorCampaigns}
                    setGoal={setGoal}
                  />
                </TabPanel>
              )}
            </TabsPanelsContainer>
          </TabsContainer>
        </ContentContainer>
      </MainContainer>
      <CreateNewCampaignModal
        open={openCreateModal}
        close={closeCreateCampaignModal} />
    </>
  )


};
export default GetStarted;