/* eslint-disable react-hooks/exhaustive-deps */
import mixpanel from "mixpanel-browser";
import { useEffect, useState, useContext, Fragment } from "react";
import { useHistory } from "react-router-dom";
import { setUserProperty, track } from "../../../analytics";
import { LoadingIndicator } from "../../../components/Loader";
import MainContentContainer from "../../../components/shareable/MainContentContainer";
import Axios from "../../../helpers/Interceptor";
import { NavbarContext } from "./Data/NavbarProvider";
import TopBar from "components/shareable/TopBar";
import useStyles from "./styles";
import { useTheme, useMediaQuery } from "@material-ui/core";
import * as Sentry from "@sentry/react";
import { AxiosError } from "axios";
import { GettingStartedContext } from "Hooks/GettingStartedContext";
import { CurrentBrand, SYMPHONY_TASKS } from "types/global";
import UpgradeToProSucceededModal from 'components/shareable/UpgradeToProSucceededModal';
import { CurrentUserContext } from "../../../Hooks/CurrentUserContext"
import { CurrentBrandContext } from "Hooks/CurrentBrandContext";

// On this page, we should be getting campaign_id + session_id variables
//  - if both are present, get the campaign details + stripe details
//      - if stripe transaction was a success, show success state
//      - else show error state
//  - if one or the other is missing, show an error state
export default function MarketingSuccessPage(props: {
    refreshCampaigns: () => void;
    reloadBrands: () => void;
}) {

    const {
        refreshCampaigns,
        reloadBrands,
    } = props

    const {
        setNextButtonHandler,
    } = useContext(NavbarContext);

    const classes = useStyles()
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down(769))

    const [loadingData, setLoadingData] = useState<any>(true)
    const [campaignData, setCampaignData] = useState<any>(null)
    const [errorState, setErrorState] = useState<boolean>(false)
    const [sessionId, setSessionId] = useState<string>("")
    const [openWelcomeProModal, setOpenWelcomeProModal] = useState<boolean>(false)
    const metaData = campaignData?.campaign_metadata || campaignData?.campaign?.campaign_metadata
    const { reloadUser } = useContext(CurrentUserContext);
    const { currentBrand } = useContext(CurrentBrandContext) as { currentBrand: CurrentBrand }

    const history = useHistory()

    async function getCampaignStatus() {
        var urlParams = new URLSearchParams(window.location.search);

        // get campaign_id + session_id
        var campaign_id = urlParams.get('campaign_id')
        var session_id = urlParams.get('session_id')
        let welcome_pro = urlParams.get('welcome_pro')

        if (!campaign_id || !session_id) {
            setErrorState(true)
        } else {
            if (welcome_pro) {
                setOpenWelcomeProModal(true)
            }
            setSessionId(session_id)
            // call to finalize campaign
            try {
                const finalizeCampaign = await Axios.post(`/campaign/brand/${currentBrand.id}/${campaign_id}/finalize`, {
                    campaign_id: campaign_id,
                    session_id: session_id
                }, {
                    timeout: 60 * 1000,
                })

                setCampaignData(finalizeCampaign.data.data)
                await reloadUser()
                await reloadBrands()
                setLoadingData(false)


            } catch (e) {
                const campaign_type = (e as AxiosError).response?.data?.data?.campaign_metadata?.campaign_type
                // error setting up campaign
                console.error('error setting up campaign', e)
                Sentry.captureException(e)
                track("Error Finalizing Campaign", {
                    campaign_type,
                    brand: currentBrand,
                    campaign_id,
                    session_id,
                    error: e
                })
                setLoadingData(false)
                setErrorState(true)
            }
        }

    }

    const onErrorStateHandler = () => {
        history.replace('/marketing')
    }

    const {
        finishedTasks,
    } = useContext(GettingStartedContext)

    useEffect(() => {
        if (!finishedTasks.includes(SYMPHONY_TASKS.CAMPAIGN_STARTED)) {
            setUserProperty(`Completed "Create Campaign" Onboarding Step`, true)
            // TODO: put -1 instead of -2 when we isolate the youtube video step
            track(`Completed "Create Campaign" Onboarding Step`, {
                remainingSteps: Object.keys(SYMPHONY_TASKS).length - finishedTasks.length - 2,
            });
            mixpanel.people.increment({
                "completed_onboarding_steps": 1,
            });
        }
        getCampaignStatus()
    }, [])

    useEffect(() => {
        const tabName = 'success-page'
        if (loadingData) {
            setNextButtonHandler({
                handler: () => { },
                tab: tabName,
                show: false,
                text: '',
                disabled: true,
            })
        } else if (!errorState) {
            setNextButtonHandler({
                handler: refreshCampaigns,
                tab: tabName,
                show: true,
                text: 'See Your Campaign',
                disabled: false,
            })
        } else {
            setNextButtonHandler({
                handler: onErrorStateHandler,
                tab: tabName,
                show: true,
                text: 'Okay',
                disabled: false,
            })
        }
    }, [loadingData, errorState]);

    // for tracking 
    useEffect(() => {
        if (campaignData && metaData) {
            const { campaign_type } = metaData;

            if (campaign_type === "get_playlisted") {
                mixpanel.people.increment({
                    "submitted_marketing_campaign": 1,
                    "submitted_playlisting_campaign": 1
                })

                track("Submitted Campaign", {
                    type: "get_playlisted",
                    payment_method: metaData.payment_method,
                    brand_id: currentBrand.id,
                    brand_name: currentBrand.name,
                    brand_slug: currentBrand.slug,
                    content_name: metaData.content.name,
                    content_slug: metaData.content.slug,

                    tier: metaData.price_data.tier,

                    budget: metaData.price_data.price
                })
            } else if (campaign_type === "increase_video_views") {
                mixpanel.people.increment({
                    "submitted_marketing_campaign": 1,
                    "submitted_video_views_campaign": 1
                })

                track("Submitted Campaign", {
                    type: "increase_video_views",
                    payment_method: metaData.payment_method,

                    brand_id: currentBrand.id,
                    brand_name: currentBrand.name,
                    brand_slug: currentBrand.slug,
                    content_name: metaData.content.name,
                    content_id: metaData.content.id,

                    budget: metaData.price_data.price
                })
            } else if (campaign_type === "creative_generator") {
                mixpanel.people.increment({
                    "submitted_marketing_campaign": 1,
                    "submitted_creative_generator_campaign": 1
                })

                track("Submitted Campaign", {
                    type: "creative_generator",
                    payment_method: metaData.payment_method,

                    brand_id: currentBrand.id,
                    brand_name: currentBrand.name,
                    brand_slug: currentBrand.slug,
                    content_name: metaData.content.name,
                    content_id: metaData.content.id,

                    budget: metaData.price_data.price
                })
            } else if (campaign_type === "record_streams") {
                mixpanel.people.increment({
                    "submitted_marketing_campaign": 1,
                    "submitted_record_streams_campaign": 1
                })

                track("Submitted Campaign", {
                    type: "increase_record_streams",
                    payment_method: metaData.payment_method,

                    brand_id: currentBrand.id,
                    brand_name: currentBrand.name,
                    brand_slug: currentBrand.slug,
                    content_name: metaData.content.name,
                    content_id: metaData.content.id,
                    content_type: metaData.content.type,
                    budget: metaData.campaign_input.price_data.price
                })
            } else if (campaign_type === "grow_playlist_followers") {
                mixpanel.people.increment({
                    "submitted_marketing_campaign": 1,
                    "submitted_grow_playlist_followers_campaign": 1
                })

                track("Submitted Campaign", {
                    type: "grow_playlist_followers",
                    payment_method: metaData.payment_method,

                    brand_id: currentBrand.id,
                    brand_name: currentBrand.name,
                    brand_slug: currentBrand.slug,
                    content_name: metaData.content.name,
                    content_id: metaData.content.id,

                    budget: metaData.campaign_input.price_data.price
                })
            } else if (campaign_type === "link_clicks") {
                mixpanel.people.increment({
                    "submitted_marketing_campaign": 1,
                    "submitted_link_clicks_campaign": 1
                })

                track("Submitted Campaign", {
                    type: "link_clicks",
                    payment_method: metaData.payment_method,

                    brand_id: currentBrand.id,
                    brand_name: currentBrand.name,
                    brand_slug: currentBrand.slug,
                    content_name: metaData.content.name,
                    content_id: metaData.content.id,

                    budget: metaData.campaign_input.price_data.price
                })
            }
        }
    }, [campaignData])

    function campaignMessage() {
        if (campaignData && metaData) {
            const { campaign_type, content } = metaData;

            if (campaign_type === "get_playlisted") {
                return <p className="">
                    Your campaign to pitch "{content.name}" to playlists has been submitted. Once approved, you will see campaign updates in the dashboard.
                </p>
            } else if (campaign_type === "increase_video_views") {
                return <p className="">
                    Your YouTube ads campaign for "{content.name}" has been submitted. Once it goes live, you will see campaign updates in the dashboard.
                </p>
            } else if (campaign_type === "creative_generator") {
                return <p className="">
                    Our AI is creating the videos for "{content.name}". You can review the status under the Marketing &gt; Tools page.
                </p>
            } else if (campaign_type === "record_streams" || campaign_type === "grow_playlist_followers") {
                return <p className="">
                    Your campaign "{content.name}" has been submitted. Once approved, you will see campaign updates in the dashboard.
                </p>
            } else if (campaign_type === "link_clicks") {
                return <p className="">
                    Your campaign to increase link clicks has been submitted. Once approved by Instagram, it will start running automatically.
                </p>
            } else if (campaign_type === "boost_instagram") {
                return <p className="">
                    Your campaign to boost your Instagram engagement has been submitted. Once approved by Instagram, it will start running automatically.
                </p>
            }
        }
    }

    function campaignTitle() {
        if (campaignData && metaData) {
            const { campaign_type } = metaData;
            if (campaign_type === "get_playlisted" ||
                campaign_type === "increase_video_views" ||
                campaign_type === "record_streams" ||
                campaign_type === "grow_playlist_followers" ||
                campaign_type === "link_clicks" ||
                campaign_type === "boost_instagram") {
                return "Your campaign has been pushed."
            } else if (campaign_type === "creative_generator") {
                return "Your videos are being generated!"
            }
        }
    }

    function errorText() {
        if (campaignData) {
            const { campaign_type } = metaData;

            switch (campaign_type) {
                case 'get_playlisted':
                    return 'There was an error creating your playlisting campaign.'
                case 'increase_video_views':
                    return "There was an error creating your increase video views campaign."
                case 'record_streams':
                    return "There was an error creating your increase music streams campaign."
                case 'grow_playlist_followers':
                    return "There was an error creating your grow playlist followers campaign."
                case 'link_clicks':
                    return "There was an error creating your increase link clicks campaign."
                case 'boost_instagram':
                    return "There was an error creating your boost Instagram engagement campaign."
                case 'creative_generator':
                    return "There was an error submitting your music video generator request."
            }
        } else {
            return "There was an error submitting your campaign."
        }
    }
    return <Fragment>
        <UpgradeToProSucceededModal
            dialogProps={{
                open: openWelcomeProModal,
                onClose: () => setOpenWelcomeProModal(false),
            }}
            sessionId={sessionId}
            brandSlug={currentBrand.slug}
            brandName={currentBrand.name}
            brandImage={currentBrand.image}
        />
        {loadingData ? (
            <>
                <TopBar title="Finalizing your campaign..." />
                <MainContentContainer className={classes.mainContainer}>
                    <div className={`flex-col flex items-center mt-10 h-80 bg-white pt-16 rounded-${isMobile ? "none" : "xl"}`}>
                        <LoadingIndicator height="100px" color="black" />
                        <p className="mt-1 text-center">Finalizing your campaign...</p>
                    </div>
                </MainContentContainer>
            </>
        ) : !errorState ? (
            <>
                <TopBar title="Campaign created!" />
                <MainContentContainer className={classes.mainContainer}>
                    <div>
                        <div className="card-content bg-white shadow-none lg:shadow-lg">
                            <div className="top-wrapper">
                                <div className="card-head relative">

                                    <h4 className="text-center text-dark lg:text-white text-2xl al">
                                        Campaign created!
                                    </h4>
                                </div>
                            </div>


                            <div className="text-center mx-auto py-4 px-20">
                                <img
                                    alt="tick-light.png"
                                    className="mx-auto m-10"
                                    src={require("../../../assets/images/tick-light.png").default}
                                />
                                <h5 className="font-bold text-xl">{campaignTitle()}</h5>
                                {campaignMessage()}

                            </div>
                            <div className="cta-wrapper">
                                <div className="text-center pb-4 px-4 lg:px-10">
                                    <div className="btn-primary w-auto px-3 "
                                        onClick={() => {
                                            if (["boost_instagram", "get_playlisted", "increase_video_views", "record_streams", "link_clicks", "grow_playlist_followers"].indexOf(metaData.campaign_type) > -1) {
                                                history.push(`/marketing/campaigns/${campaignData?.id || campaignData?.campaign?.id}`);

                                            } else {
                                                history.push(`/marketing/tools/creatives`);
                                            }
                                        }}>

                                        See Your Campaign
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </MainContentContainer>
            </>
        ) : (
            <>
                <TopBar title="Campaign Error" />
                <MainContentContainer className={classes.mainContainer}>
                    <div className="card-body">
                        <div className="card-content bg-white shadow-none lg:shadow-lg">
                            <div className="top-wrapper">
                                <div className="card-head relative">

                                    <h4 className="text-center text-dark lg:text-white text-2xl al">
                                        Campaign Error
                                    </h4>
                                </div>
                            </div>


                            <div className="text-center mx-auto p-10 lg:p-20">

                                <h5 className="font-bold text-xl">{errorText()}</h5>
                                <p className="">
                                    We apologize for the inconvenience - please try submitting your campaign again.
                                </p>
                            </div>
                            <div className="cta-wrapper">
                                <div className="text-center pb-4 px-4 lg:px-10">
                                    <button className="btn-primary w-auto px-3 "
                                        onClick={() => {
                                            history.replace('/marketing')
                                        }}
                                    >
                                        Okay
                                    </button>
                                </div>
                            </div>
                        </div>


                    </div>
                </MainContentContainer>
            </>
        )}
    </Fragment>
}