import { ReactComponent as AutomatedIcon } from "assets/images/automated.svg"
import { ReactComponent as UserIcon } from "assets/images/user.svg"
import { ReactComponent as FacebookIcon } from "assets/images/facebook-i.svg"
import { ReactComponent as GlobeIcon } from "assets/images/globe.svg"
import { ReactComponent as PreSaveIcon } from "assets/images/PreSaves.svg"
import { ReactComponent as HeadphonesIcon } from "assets/images/headphones.svg"
import { ReactComponent as Download2Icon } from "assets/images/download-2.svg"
import { ClassNameMap } from "@material-ui/core/styles/withStyles"
import { MONTHLY_PRO_PLAN_PRICE, YEARLY_BY_MONTH_PRO_PLAN_PRICE, MONTHLY_TEAM_PLAN_PRICE, YEARLY_BY_MONTH_TEAM_PLAN_PRICE, MAX_ADDITIONAL_USERS_WITH_PRO, MAX_ADDITIONAL_USERS_WITH_TEAM_PRO } from "../../../constants"
import { CurrencyCodesType } from "types/global"
import getSymbolFromCurrency from "currency-symbol-map"
import millify from "millify"



export enum BillingCycle {
  MONTHLY = "monthly",
  ANNUALLY = "annual",
}

type IconComponent = React.FunctionComponent<
  React.SVGProps<SVGSVGElement> & {
    title?: string
  }
>

//TODO: Change by the real quotes and images
export const TESTIMONIALS = [
  {
    action: "Grew by",
    quantity: "400%",
    units: "monthly listeners",
    testimony:
      "For any artist serious about growing their fanbase, Symphony is a must. Their ability to automate ad campaigns and extract valuable insight gives users a unique blend of dependability & simplicity.",
    witness: "West",
    occupation: "Rapper",
    picture:
      "https://symphonyos-public-assets.s3.us-east-2.amazonaws.com/images/testimonials/artist-west.jpeg",
  },
  {
    action: "Manages",
    quantity: "5 artists",
    units: "using Symphony",
    testimony:
      "Symphony is an incredible resource for today’s music entrepreneur and artist. As a full time artist manager for 4 artists and the CEO of a record label, my goal everyday is to promote my artist’s music and scale their careers as quickly as possible, with long-term career sustainability in mind.",
    witness: "Ashley",
    occupation: "CEO, Breakroom17",
    picture:
      "https://symphonyos-public-assets.s3.us-east-2.amazonaws.com/images/testimonials/ceo-ashley-breakroom17.png",
  },
  {
    action: "Grown to",
    quantity: "25k",
    units: "monthly listeners on Spotify",
    testimony:
      "Symphony is a necessity for all artists. Their automated ad campaigns and other marketing tools have allowed me to grow my following and listeners across all platforms!",
    witness: "Christof Hunte",
    occupation: "Pop Artist",
    picture:
      "https://symphonyos-public-assets.s3.us-east-2.amazonaws.com/images/testimonials/artist-christof-hunte.png",
  },
]

export type FeatureType = {
  icon: IconComponent
  feature: string
  caption?: string | ((currency: CurrencyCodesType, convertedAmount: number) => string)
  className?: string
}

export const getFeatures = (classes: ClassNameMap, isOrganization?: boolean): Array<FeatureType> => [
  {
    icon: AutomatedIcon,
    feature: "Automated marketing on Instagram + YouTube",
    caption: (currency: CurrencyCodesType, convertedAmount: number) =>
      `Monthly ad spend limit of ${getSymbolFromCurrency(currency)}${millify(convertedAmount)} + 2% automation fee after`,
    className: classes.icon,
  },
  {
    icon: UserIcon,
    feature: `Invite up to ${isOrganization ? MAX_ADDITIONAL_USERS_WITH_TEAM_PRO : MAX_ADDITIONAL_USERS_WITH_PRO} users to access your profile`,
    caption: (currency: CurrencyCodesType, convertedAmount: number) =>
      `${getSymbolFromCurrency(currency)}${convertedAmount} monthly per additional user`,
    className: classes.icon,
  },
  {
    icon: PreSaveIcon,
    feature: "Collect unlimited data, emails, and pre-saves",
    className: classes.icon,
  },
  {
    icon: HeadphonesIcon,
    feature: "Playlisting campaigns for 15% off",
    className: classes.icon,
  },
  {
    icon: FacebookIcon,
    feature: "Advanced targeting with Facebook Conversions API",
    className: classes.icon,
  },
  {
    icon: Download2Icon,
    feature: "Export fan data",
    className: classes.icon,
  },
  {
    icon: GlobeIcon,
    feature: "Track fan demographics",
  },
  {
    icon: UserIcon,
    feature: "Priority Support",
    className: classes.icon,
  },
]

export const format = (value: number) =>
  value.toLocaleString("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })

export const labels = {
  [BillingCycle.MONTHLY]: {
    id:'monthly',
    price: MONTHLY_PRO_PLAN_PRICE,
    totalPrice: MONTHLY_PRO_PLAN_PRICE,
    billed: "Billed monthly",
    billedTrialText: 'month'
  },
  [BillingCycle.ANNUALLY]: {
    id:'annually',
    price: YEARLY_BY_MONTH_PRO_PLAN_PRICE,
    totalPrice: YEARLY_BY_MONTH_PRO_PLAN_PRICE * 12,
    billed: "Billed annually",
    billedTrialText: 'year'
  },
}

export const teamLabels = {
  [BillingCycle.MONTHLY]: {
    id:'monthly',
    price: MONTHLY_TEAM_PLAN_PRICE,
    totalPrice: MONTHLY_TEAM_PLAN_PRICE,
    billed: "Billed monthly",
    billedTrialText: 'month'
  },
  [BillingCycle.ANNUALLY]: {
    id:'annually',
    price: YEARLY_BY_MONTH_TEAM_PLAN_PRICE,
    totalPrice: YEARLY_BY_MONTH_TEAM_PLAN_PRICE * 12,
    billed: "Billed annually",
    billedTrialText: 'year'
  },
}