import React, { useEffect, useState, useContext } from "react";
import { useTabs, TabPanel } from "react-headless-tabs";
import { useHistory, useLocation } from "react-router-dom";
import Axios from "../../../helpers/Interceptor";
import MobileHeader from "../../../components/MobileHeader";
import OverallDashboard from "./OverallDashboard";
import MainContentContainer from "../../../components/shareable/MainContentContainer";
import mixpanel from "mixpanel-browser";
import { pageView, setUserProperty, track } from "../../../analytics";
import { checkIfIsProTier, getStatusToShowPaymentFailedBanner } from "utils";
import PlatformsConnector from "./PlatformsConnector";
import { CurrentBrandContext } from "Hooks/CurrentBrandContext";
import GettingStarted, { GettingStartedNames } from '../../../components/dashboard/GettingStarted';
import { GettingStartedContext } from '../../../Hooks/GettingStartedContext/index';
import { clsx } from 'clsx';
import useScreenQueryDimensions from "Hooks/useScreenQueryDimensions";
import { Connections, CurrentBrand, MarketingCampaignType, SpotifyArtist, SYMPHONY_TASKS, UserData, WebsiteType } from "types/global";
import { BannerContainer, CardContainer } from "./styles";
import { DATE_OPTIONS, getDatesByRange, getFanbaseData, getSmallGraphEmptyData } from "./utils";
import dayjs from "dayjs";
import { NewLoader } from "components/Loader/ConnectBrandLoader";
import AlreadyExistingArtist from "components/connect/connectModals/AlreadyExistingArtist";
import PaymentFailedBanner from "components/shareable/PaymentFailedBanner";
import UpgradeToProSucceededModal from "components/shareable/UpgradeToProSucceededModal";
import { isAdmin, isYouTubeLogin } from "helpers/admin";
import MaestroCampaignRecommender from "../MarketingPage/Components/CreateNewCampaignModal/MaestroCampaignRecommender";
import { availableGoals } from "../MarketingPage/utils/marketing";
import DefaultAdditionalActions from "components/shareable/CommonComponent/CardTitle/DefaultAdditionalActions";
import { trackNewCampaign } from "../MarketingPage/Components/CampaignsSection/analytics";
import MusicSiteIcon from 'assets/images/websiteThemes/music-site.svg';
import CreateNewSiteModal from "../Website/components/CreateNewSiteModal";
import Recommendationsv1UI from "./Recommendationsv1";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export interface FanbaseMetricsType {
  totalFanbase: number;
  foreverPresaveFans: number;
  fanbaseGrowth: string;
  foreverPresaveFansGrowth: string;
  dailyMetrics: {
    [key: string]: {
      totalFans: number;
      foreverSaves: number;
    }
  };
}

// TODO: ask megh for icons - music-player by default
export const siteIconsData: { [key: string]: any; }
  = {
  deezer: require("../../../assets/images/platforms/deezer.svg").default,
  spotify: require("../../../assets/images/platforms/spotify.svg").default,
  tidal: require("../../../assets/images/platforms/tidal.svg").default,
  youtube: require("../../../assets/images/platforms/youtube.svg").default,
  apple_music: require("../../../assets/images/music-player.svg").default,
  amazonStore: require("../../../assets/images/music-player.svg").default,
  amazon_music: require("../../../assets/images/music-player.svg").default,
  instagram: require("../../../assets/images/platforms/instagram.svg").default,
  tiktok: require("../../../assets/images/platforms/tiktok.svg").default,
  facebook: require("../../../assets/images/platforms/facebook.svg").default,
  soundcloud: require("../../../assets/images/platforms/soundcloud.svg").default,
  twitter: require("../../../assets/images/twitter.svg").default,
  audiomack: require("../../../assets/images/platforms/audiomack.svg").default,

  fans: require("../../../assets/images/platforms/fans.svg").default,
  'forever-saves': require("../../../assets/images/platforms/forever-saves.svg").default,

}

const Dashboard = (props: {
  user: UserData;
  isLoadingBrands: boolean;
  loadedBrands: CurrentBrand[];
}) => {
  const {
    currentBrand,
    setCurrentBrand,
    reloadBrand
  } = useContext(CurrentBrandContext);

  const query = useQuery();
  const { loadedBrands, isLoadingBrands, user } = props;
  const userIsAdmin = user ? isAdmin(user) : false;

  const showPaymentFailedBanner = getStatusToShowPaymentFailedBanner(currentBrand)
  const isProUser = checkIfIsProTier(currentBrand);
  const [sessionId, setSessionId] = useState(String())
  const [showUpgradeToProSucceededModal, setShowUpgradeToProSucceededModal] =
    useState(false)


  // show text the YT not included in the graph(this is to adhere with YT terms of service)
  const isYTLoginAdmin = isYouTubeLogin(user)


  const [isLoadingFanbase, setLoadingFanbase] = useState(true && loadedBrands && loadedBrands.length > 0);
  const [isLoadingContent, setLoadingContent] = useState(true && loadedBrands && loadedBrands.length > 0);

  const [isLoadingNewDate, setLoadingNewDate] = useState(false);

  // edit platforms modal
  const [platformsModalOpen, setPlatformsModalOpen] = useState<boolean>(false);
  const [openAlreadyExistModal, setOpenAlreadyExistModal] = useState<boolean>(false)
  const [existingBrand, setExistingBrand] = useState<CurrentBrand>({} as CurrentBrand);
  const [artist, setArtist] = useState<SpotifyArtist>({} as SpotifyArtist);

  // to handle date selection
  const [selectedRange, setSelectedRange] = useState<DATE_OPTIONS | 'custom' | null>('last-7-days')
  const [selectedDate, setSelectedDate] = useState<{
    startDate: string;
    endDate: string;
  }>({
    startDate: dayjs().subtract(7, "days").format("YYYY-MM-DD"),
    endDate: dayjs().format("YYYY-MM-DD")
  })

  const checkSubscriptionStatus = async () => {
    const urlParams = new URLSearchParams(window.location.search)
    const subscription = urlParams.get("subscription")
    const brand = urlParams.get("brand")
    const session_id = urlParams.get("session_id")

    if (
      session_id &&
      subscription === "succeeded" &&
      brand === currentBrand?.slug
    ) {
      setSessionId(session_id)
      setShowUpgradeToProSucceededModal(true)
    } else {
      setSessionId(String())
      setShowUpgradeToProSucceededModal(false)
    }
  }

  const closeUpgradeToProSucceededModal = () => {
    setSessionId(String())
    setShowUpgradeToProSucceededModal(false)
  }

  useEffect(() => {
    checkSubscriptionStatus()
  }, [])

  useEffect(() => {
    setShowUpgradeToProSucceededModal(Boolean(sessionId))
  }, [sessionId])

  function updateDateRange(range: DATE_OPTIONS | 'custom') {
    setSelectedRange(range)
    if (range !== 'custom') {
      var {
        startDate,
        endDate
      } = getDatesByRange(range)

      setSelectedDate({
        startDate: startDate,
        endDate: endDate
      })
    }
  }

  const tabs = ["overall", "content"];
  let defaultTab = "overall";
  if (query.get("tab")) {
    const t = query.get("tab");
    if (t && tabs.includes(t)) {
      defaultTab = t;
    }
  }
  const [selectedTab, setSelectedTab] = useTabs(tabs, defaultTab);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [overallWeeklyGrowth, setOverallWeeklyGrowth] = useState<any>({});

  const [brandContent, setBrandContent] = useState<any>([]);

  const [overallStats, setOverallStats] = useState<any>({});
  const [fetchedOverallStats, setFetchedOverallStats] = useState(false);

  const NEEDS_CONNECTIONS: Record<string, (currentBrand: CurrentBrand) => boolean> = {
    'spotify': (currentBrand: CurrentBrand) => !(currentBrand.connections.spotify_artist_page),
    'youtube': (currentBrand: CurrentBrand) => !(currentBrand.connections.youtube_channel),
    'instagram': (currentBrand: CurrentBrand) => !(currentBrand.connections.business_discovery_instagram),
    'tiktok': (currentBrand: CurrentBrand) => !(currentBrand.connections.tiktok && currentBrand.connections.tiktok.url && currentBrand.connections.tiktok.username),
    'soundcloud': (currentBrand: CurrentBrand) => !(currentBrand.connections.soundcloud_artist_page),
    'audiomack': (currentBrand: CurrentBrand) => !(currentBrand.connections.audiomack),
    // 'twitter': (currentBrand: CurrentBrand) => !(currentBrand.connections.twitter && currentBrand.connections.twitter.url),
    'facebook': (currentBrand: CurrentBrand) => !(currentBrand.connections.facebook_page && currentBrand.connections.facebook_page.id),
    'deezer': (currentBrand: CurrentBrand) => !(currentBrand.connections.deezer && currentBrand.connections.deezer.id),


  }

  const [smallGraphData, setSmallGraphData] = useState<any[]>([

    {
      name: "Spotify Monthly Listeners",
      platformId: 'spotify',
      platformName: "Spotify",
      statIdentifier: 'monthly_listeners',
      statLabel: "Monthly Listeners",
      chartData: {},
      secondaryData: {},
      imageAsset: siteIconsData['spotify'],
      needsConnection: currentBrand && NEEDS_CONNECTIONS['spotify'](currentBrand),
      type: "platforms"
    },
    {
      name: "Spotify Followers",
      platformId: 'spotify',
      platformName: "Spotify",
      statIdentifier: 'followers',
      statLabel: "Followers",
      chartData: {},
      secondaryData: {},
      imageAsset: siteIconsData['spotify'],
      needsConnection: currentBrand && NEEDS_CONNECTIONS['spotify'](currentBrand),
      type: "platforms"
    },
    {
      name: "YouTube Subscribers",
      platformId: 'youtube',
      platformName: "YouTube",
      statIdentifier: 'subscribers',
      statLabel: "Subscribers",
      chartData: {},
      secondaryData: {},
      imageAsset: siteIconsData['youtube'],
      needsConnection: currentBrand && NEEDS_CONNECTIONS['youtube'](currentBrand),
      hideDataFromOverall: isYTLoginAdmin,
      type: "platforms"
    },
    {
      name: "Instagram Followers",
      platformId: 'instagram',
      platformName: "Instagram",
      statIdentifier: 'followers',
      statLabel: "Followers",
      chartData: {},
      secondaryData: {},
      imageAsset: siteIconsData['instagram'],
      needsConnection: currentBrand && NEEDS_CONNECTIONS['instagram'](currentBrand),
      type: "platforms"
    },
    {
      name: "TikTok Followers",
      platformId: 'tiktok',
      platformName: "TikTok",
      statIdentifier: 'followers',
      statLabel: "Followers",
      chartData: {},
      secondaryData: {},
      imageAsset: siteIconsData['tiktok'],
      needsConnection: currentBrand && NEEDS_CONNECTIONS['tiktok'](currentBrand),
      type: "platforms"
    },
    // {
    //   name: "Twitter Followers",
    //   platformId: 'twitter',
    //   platformName: "Twitter",
    //   statIdentifier: 'followers',
    //   statLabel: "Followers",
    //   chartData: {},
    //   secondaryData: {},
    //   imageAsset: siteIconsData['twitter'],
    //   needsConnection: currentBrand && NEEDS_CONNECTIONS['twitter'](currentBrand),
    //   type: "platforms",
    //   decommissionMessage: "Unfortunately due to changes at X / Twitter beyond our control, it's no longer possible for us to pull followers. We're hoping to bring this feature back soon!"
    // },
    {
      name: "Facebook Page Followers",
      platformId: 'facebook',
      platformName: "Facebook Page",
      statIdentifier: 'followers',
      statLabel: "Followers",
      chartData: {},
      secondaryData: {},
      imageAsset: siteIconsData['facebook'],
      needsConnection: currentBrand && NEEDS_CONNECTIONS['facebook'](currentBrand),
      type: "platforms"
    },
    {
      name: "SoundCloud Followers",
      platformId: 'soundcloud',
      platformName: "SoundCloud",
      statIdentifier: 'followers',
      statLabel: "Followers",
      chartData: {},
      secondaryData: {},
      imageAsset: siteIconsData['soundcloud'],
      needsConnection: currentBrand && NEEDS_CONNECTIONS['soundcloud'](currentBrand),
      type: "platforms"
    },
    {
      name: "Audiomack Followers",
      platformId: 'audiomack',
      platformName: "Audiomack",
      statIdentifier: 'followers',
      statLabel: "Followers",
      chartData: {},
      secondaryData: {},
      imageAsset: siteIconsData['audiomack'],
      needsConnection: currentBrand && NEEDS_CONNECTIONS['audiomack'](currentBrand),
      type: "platforms"
    },
    {
      name: "Deezer Fans",
      platformId: 'deezer',
      platformName: "Deezer",
      statIdentifier: 'followers',
      statLabel: "Fans",
      chartData: {},
      secondaryData: {},
      imageAsset: siteIconsData['deezer'],
      needsConnection: currentBrand && NEEDS_CONNECTIONS['deezer'](currentBrand),
      type: "platforms"
    },

    // {
    //   name: "Instagram",
    //   platformId: 'instagram',
    //   platformName: "Instagram",
    //   statIdentifier: 'followers',
    //   statLabel: "Followers",
    //   chartData: {},
    //   chartOptions: JSON.parse(JSON.stringify(defaultChartOptions)),
    //   secondaryData: {},
    //   imageAsset: siteIconsData['instagram'],
    //   needsConnection: currentBrand && currentBrand.connections.instagram_page ? false : true
    // },
  ])

  const {
    finishedTasks,
    completed,
    fetchOnboardingTasks,
  } = useContext(GettingStartedContext);
  const { innerWidth } = useScreenQueryDimensions();

  const history = useHistory();



  const ProfileClick = (event?: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  async function brandHasData() {

    if (currentBrand && currentBrand.dashboard_initialized) {
      getContent()
    } else {
      const brandId = localStorage.getItem('selected-brand');

      // create a timeout to wait for current 
      setLoadingFanbase(true)

      var brandPromise = await new Promise(async (resolve: any, reject: any) => {

        // every 3 seconds check if its done
        var refreshIntervalId = setInterval(async () => {
          const brandResponse = await Axios.get(`/brand/${brandId}`);
          const brandData = brandResponse.data.data

          if (brandData.dashboard_initialized) {
            clearInterval(refreshIntervalId);
            resolve(brandData)
          }

        }, 7000);
      })

      setCurrentBrand(brandPromise)
    }


  }


  const [fanbaseMetrics, setFanbaseMetrics] = useState<FanbaseMetricsType>({
    totalFanbase: 0,
    foreverPresaveFans: 0,
    fanbaseGrowth: "0",
    foreverPresaveFansGrowth: "0",
    dailyMetrics: {}
  });

  const getFanEngagementStats = async () => {
    try {
      const { data } = await Axios.get(`/brand/${currentBrand!.id}/metrics/fans?startDate=${selectedDate.startDate}&endDate=${selectedDate.endDate}`);
      setFanbaseMetrics(data.data);
    } catch (err) {
      console.error('Error fetching fan engagement stats:', err);
    }
  };

  function getContent() {

    const getOverallFanbaseGrowth = async () => {

      // Retrieve analytics data from Analytics API
      // the api needs a startDate and chartPeriod to get range of data
      const chartPeriod = 'weekly';

      let startDate = selectedDate.startDate
      let endDate = selectedDate.endDate

      // const overall_fanbase_growth = Axios.get(`/dashboard/brand/${brandId}/overall-fanbase?startDate=${startDate}&chartPeriod=${chartPeriod}`);

      var promises = smallGraphData
        .map((d: any) => {
          const { platformId, statIdentifier, name } = d
          if (!currentBrand) {
            console.error('currentBrand is undefined')
            return Promise.resolve(null)
          };

          let connectionKey: keyof Connections

          if (platformId === "youtube") {
            connectionKey = "youtube_channel";
          } else if (platformId === "instagram") {
            connectionKey = "business_discovery_instagram";
          } else if (platformId === "spotify") {
            connectionKey = "spotify_artist_page";
          } else if (platformId === "facebook") {
            connectionKey = "facebook_page";
          } else if (platformId === "soundcloud") {
            connectionKey = "soundcloud_artist_page";
          } else {
            connectionKey = platformId as keyof Connections;
          }

          const isConnected = Boolean(currentBrand.connections[connectionKey])

          if (isConnected) {
            return getFanbaseData({
              brandId: currentBrand!.id,
              platformId,
              statIdentifier,
              startDate: startDate,
              endDate: endDate
            })
          } else {
            return Promise.resolve(getSmallGraphEmptyData(platformId, statIdentifier));
          }
        })

      Promise.all(promises)
        .then((results: any[]) => {

          plotChartData(results)

        })
        .catch((err: any) => {
          console.error('errored fetching data', err);
        })
        .finally(() => {
          if (loadedBrands && loadedBrands.length > 0) {

          }
        })
    }

    getFanEngagementStats()

    getOverallFanbaseGrowth()


  }

  // Mixpanel pageview
  useEffect(() => {
    if (!user.hasBrands && !isLoadingBrands && !loadedBrands?.length) {
      history.push('/add-artist');
    } else {
      pageView("Dashboard")
    }
  }, [])

  // get data for brand
  useEffect(() => {
    if (currentBrand !== null) {
      if (!completed) {
        fetchOnboardingTasks()
      }
      brandHasData()
    }
    refreshConnections()
  }, [currentBrand])

  useEffect(() => {

    if (currentBrand) {
      setLoadingNewDate(true)
      if (currentBrand.dashboard_initialized) {
        getContent()
      }

      track("Updated Time Range", {
        name: "Dashboard",
        to: selectedRange,
        startDate: selectedDate.startDate,
        endDate: selectedDate.endDate,
        brand_id: currentBrand.id,
        brand_slug: currentBrand.slug,
        brand_name: currentBrand.name
      })
    }
  }, [selectedDate])

  // only fire the brand content api, if we are the content tab
  useEffect(() => {
    async function fetchBrandContent() {
      const brandId = localStorage.getItem('selected-brand');
      setLoadingContent(true);
      try {
        const brandContentResponse = await Axios.get(`/brand-content/${brandId}/analytics`, { timeout: 60 * 1000 });
        setBrandContent(brandContentResponse.data.data);
      } catch (error) {
        console.error('Error in fetching brand content', error);
      } finally {
        setLoadingContent(false)
      }
    }
    if (selectedTab === 'content') {
      setFetchedOverallStats(false);
      fetchBrandContent()
    }
  }, [selectedTab])

  // fire the overall content stats api fetch only when we have obtained
  // contents from the brand content api
  useEffect(() => {
    const updateContentWithStats = (stats: any) => {
      if (!stats.growthData) {
        return;
      }
      const contentIds = Object.keys(stats.growthData);
      // we find the above contentIds in brandContent
      const _brandContent = [...brandContent];
      for (const cid of contentIds) {
        const idx = _brandContent.findIndex(bc => Number(bc.id) === Number(cid));
        if (idx !== -1) {
          _brandContent[idx]['weeklyGrowth'] = stats.growthData[cid]
        }
      }

      setBrandContent(_brandContent);
      return;
    }

  }, [brandContent, fetchedOverallStats])

  useEffect(() => {
    if (!isLoadingFanbase && !finishedTasks.includes(SYMPHONY_TASKS.FANBASE_COMPLETED)) {
      const completedFanbase = smallGraphData.every((chartItem: any) => !chartItem.needsConnection);
      if (completedFanbase) {
        setUserProperty(`Completed "${GettingStartedNames[SYMPHONY_TASKS.FANBASE_COMPLETED]}" Onboarding Step`, true)
        track(`Completed "${GettingStartedNames[SYMPHONY_TASKS.FANBASE_COMPLETED]}" Onboarding Step`, {
          remainingSteps: Object.keys(SYMPHONY_TASKS).length - finishedTasks.length - 1,
        });
        mixpanel.people.increment({
          "completed_onboarding_steps": 1,
        });
      }
    }
  }, [smallGraphData, isLoadingFanbase])


  const plotChartData = (dataArray: any) => {
    const currentPlatformData = JSON.parse(JSON.stringify(smallGraphData))

    const overallDataCalculator: any = {}

    dataArray.map((p: any) => {
      const {
        platform,
        stat,

      } = p.data.data

      // returns the data for the chart 
      const dataForChart: any = plotChart(p.data.data)

      var foundItem = currentPlatformData.find((d: any) => {
        return platform === d.platformId && stat == d.statIdentifier
      })

      foundItem.chartData.data = dataForChart.chartData
      foundItem.secondaryData = dataForChart.growthData

      // now we've got the items populated for each graph 

      var minValue = 0
      dataForChart.chartData.map((dateValue: any) => {
        minValue = minValue === 0 ? dateValue.value : Math.min(dateValue.value, minValue)
        if (!overallDataCalculator[dateValue.date]) {
          overallDataCalculator[dateValue.date] = {
            datetime: dateValue.date,
            values: []
          }
        }

        overallDataCalculator[dateValue.date].values.push(parseInt(dateValue.value))
      })


    })

    // overalldatacalculator is an object with:
    /**
     * {
     *  datetime: 2021-08-09T00:00:00.000Z
     *  values: []
     * }
     * 
     * Just calculate the average for now
     */

    var dateValues = Object.keys(overallDataCalculator)
    var weeklyOverallData = dateValues.map((d: any) => {
      const { values, datetime } = overallDataCalculator[d]
      const filteredValues = values.filter((v: any) => v !== 0)
      const total = filteredValues.reduce((acc: any, c: any) => acc + c, 0);
      // const avg = total / filteredValues.length;

      return {
        date: datetime,
        value: total

      }
    })

    const overallChart = {
      chartData: weeklyOverallData,
      percentage: 0
    }


    // in the case where NO DATA is available for any platform
    //  (for example, where an artist doesnt have any past data in chartmetrics and we're starting fresh),
    // show an empty chart
    if (overallChart.chartData.length > 0) {
      overallChart.percentage = ((weeklyOverallData[weeklyOverallData.length - 1].value - weeklyOverallData[0].value) / weeklyOverallData[0].value) * 100
    }

    setSmallGraphData(currentPlatformData)
    setOverallStats(overallChart)
    setLoadingFanbase(false)
    setLoadingNewDate(false)

  }

  const plotChart = (chartData: any) => {


    var totalDays = 7

    if (selectedRange === 'last-30-days') {
      totalDays = 30
    } else if (selectedRange === 'last-3-months') {
      totalDays = 90
    } else if (selectedRange === 'custom') {
      totalDays = dayjs(selectedDate.endDate).diff(dayjs(selectedDate.startDate), 'day')
    }

    const lastWeekStartDate = selectedDate.startDate

    /**
     * an item in the api's data reponse array looks like
     * {
     *    datetime: 2021-08-09T00:00:00.000Z
     *    fanbase_count: 123
     * }
     **/

    /**
     * get 7 days ago date
     *  - get each day for last 7 days
     */
    var localChartData: any = []

    var lastVal = 0

    // if no data was returned at all, it means we're still gathering data
    //  - return a fully empty chart breakdown

    if (chartData.chartData.length === 0) {
      return {
        chartData: localChartData,
        growthData: {
          currentGrowthValue: 0,
          percentage: 0
        }
      }
    }


    for (var i = 0; i < totalDays; i++) {
      // get each day of data based on avail date
      const date = dayjs(lastWeekStartDate).add(i, 'days').startOf('day');
      let dateData = chartData.chartData.find((d: any) => {
        return dayjs(d.datetime).format('YYYY-MM-DD') === dayjs(date).format('YYYY-MM-DD')
      })

      // if the dateData for the given day is found, then use that as the
      // lastVal AND set it for the day
      var dateDetails = {
        date: date.format("YYYY-MM-DD"),
        value: 0

      }



      if (dateData) {
        var fanCount = parseInt(dateData.fanbase_count)

        // if the fanbase count > 0, set it

        if (fanCount > 0) {

          // lastVal = dateData.fanbase_count
          dateDetails = {
            date: date.format("YYYY-MM-DD"),
            value: fanCount

          }
        } else {
          // otherwise, 
          //  - if this is the first value, get the next value
          //  - if this is the last value, get the value before it
          //  - if this is a middle value, ttake the averages of before and after
          if (i === 0) {
            const nextDate = date.clone().add(1, 'days').startOf('day');
            let nextDateData = chartData.chartData.find((d: any) => {
              return dayjs(d.datetime).format('YYYY-MM-DD') === dayjs(nextDate).format('YYYY-MM-DD')
            })

            if (nextDateData) {
              // if there is a found value, then use that value 
              var nextDayCount = parseInt(nextDateData.fanbase_count)

              if (nextDayCount > 0) {
                dateDetails = {
                  date: date.format("YYYY-MM-DD"),
                  value: nextDayCount
                }
              }
            }
          } else if (i === 6) {
            //  - if this is the last value, get the value before it
            const prevDate = date.clone().subtract(1, 'days').startOf('day');
            let prevDateData = chartData.chartData.find((d: any) => {
              return dayjs(d.datetime).format('YYYY-MM-DD') === dayjs(prevDate).format('YYYY-MM-DD')
            })

            if (prevDateData) {
              // if there is a found value, then use that value 
              var prevDayCount = parseInt(prevDateData.fanbase_count)

              if (prevDayCount > 0) {
                dateDetails = {
                  date: date.format("YYYY-MM-DD"),
                  value: prevDayCount
                }
              }
            }
          } else {
            //  - if this is a middle value, take the averages of before and after
            const nextDate = date.clone().add(1, 'days').startOf('day');
            let nextDateData = chartData.chartData.find((d: any) => {
              return dayjs(d.datetime).format('YYYY-MM-DD') === dayjs(nextDate).format('YYYY-MM-DD')
            })

            const prevDate = date.clone().subtract(1, 'days').startOf('day');
            let prevDateData = chartData.chartData.find((d: any) => {
              return dayjs(d.datetime).format('YYYY-MM-DD') === dayjs(prevDate).format('YYYY-MM-DD')
            })

            if (prevDateData && nextDateData) {
              var prevDayCount = parseInt(prevDateData.fanbase_count)
              var nextDayCount = parseInt(nextDateData.fanbase_count)

              if (nextDayCount > 0 && prevDateData > 0) {
                dateDetails = {
                  date: date.format("YYYY-MM-DD"),
                  value: (nextDayCount + prevDayCount) / 2
                }
              }
            }
          }
        }

      } else {
        if (i === 0) {
          const nextDate = date.clone().add(1, 'days').startOf('day');
          let nextDateData = chartData.chartData.find((d: any) => {
            return dayjs(d.datetime).format('YYYY-MM-DD') === dayjs(nextDate).format('YYYY-MM-DD')
          })


          if (nextDateData) {
            // if there is a found value, then use that value 
            var nextDayCount = parseInt(nextDateData.fanbase_count)
            if (nextDayCount > 0) {
              dateDetails = {
                date: date.format("YYYY-MM-DD"),
                value: nextDayCount
              }
            }
          } else {
            // loop thru the dates until a value is found 
            var dateAddition = 2
            var dataFound: any = null
            while (!dataFound) {
              if (dateAddition === 6) break

              let attemptedDate = date.clone().add(dateAddition, 'days').startOf('day');

              let attemptedDateData = chartData.chartData.find((d: any) => {
                return dayjs(d.datetime).format('YYYY-MM-DD') === dayjs(attemptedDate).format('YYYY-MM-DD')
              })

              if (attemptedDateData) {
                dataFound = attemptedDateData
              } else {
              }

              dateAddition++
            }

            if (dataFound) {

              var foundDataCount = parseInt(dataFound.fanbase_count)
              if (foundDataCount > 0) {
                dateDetails = {
                  date: date.format("YYYY-MM-DD"),
                  value: foundDataCount
                }
              }
            }
          }
        } else if (i === 6) {
          //  - if this is the last value, get the value before it
          const prevDate = date.clone().subtract(1, 'days').startOf('day');
          let prevDateData = chartData.chartData.find((d: any) => {
            return dayjs(d.datetime).format('YYYY-MM-DD') === dayjs(prevDate).format('YYYY-MM-DD')
          })

          if (prevDateData) {
            // if there is a found value, then use that value 
            var prevDayCount = parseInt(prevDateData.fanbase_count)

            if (prevDayCount > 0) {
              dateDetails = {
                date: date.format("YYYY-MM-DD"),
                value: prevDayCount
              }
            }
          }
        } else {
          //  - if this is a middle value, take the averages of before and after
          const nextDate = date.clone().add(1, 'days').startOf('day');
          let nextDateData = chartData.chartData.find((d: any) => {
            return dayjs(d.datetime).format('YYYY-MM-DD') === dayjs(nextDate).format('YYYY-MM-DD')
          })

          const prevDate = date.clone().subtract(1, 'days').startOf('day');
          let prevDateData = chartData.chartData.find((d: any) => {
            return dayjs(d.datetime).format('YYYY-MM-DD') === dayjs(prevDate).format('YYYY-MM-DD')
          })


          if (prevDateData && nextDateData) {
            var prevDayCount = parseInt(prevDateData.fanbase_count)
            var nextDayCount = parseInt(nextDateData.fanbase_count)

            if (nextDayCount > 0 && prevDayCount > 0) {
              dateDetails = {
                date: date.format("YYYY-MM-DD"),
                value: (nextDayCount + prevDayCount) / 2
              }
            }
          } else if (prevDateData) {
            var prevDayCount = parseInt(prevDateData.fanbase_count)
            if (prevDayCount > 0) {
              dateDetails = {
                date: date.format("YYYY-MM-DD"),
                value: prevDayCount
              }
            }
          } else if (nextDateData) {
            var nextDayCount = parseInt(nextDateData.fanbase_count)
            if (nextDayCount > 0) {
              dateDetails = {
                date: date.format("YYYY-MM-DD"),
                value: nextDayCount
              }
            }
          } else {
            // no data found for previous date OR next date
            // -- loop thru the dates until a value is found 
            var dateAddition = 2
            var dataFound: any = null
            while (!dataFound) {
              if (dateAddition === 6) break

              let attemptedDate = date.clone().add(dateAddition, 'days').startOf('day');
              let attemptedDateData = chartData.chartData.find((d: any) => {
                return dayjs(d.datetime).format('YYYY-MM-DD') === dayjs(attemptedDate).format('YYYY-MM-DD')
              })

              if (attemptedDateData) {
                dataFound = attemptedDateData
              } else {
              }

              dateAddition++
            }

            if (dataFound) {

              var foundDataCount = parseInt(dataFound.fanbase_count)
              if (foundDataCount > 0) {
                dateDetails = {
                  date: date.format("YYYY-MM-DD"),
                  value: foundDataCount
                }
              }
            }
          }
        }
      }

      localChartData.push(dateDetails)

    }

    // in the case where the first few days have 0 data, and the next few days dont,
    // update all of the first few days to the firstvalue found
    var firstFoundValue = 0
    localChartData.map((o: {
      date: string,
      value: number
    }, idx: number) => {
      const {
        value
      } = o

      if (value > 0) {
        firstFoundValue = value
      }
    })

    // now go thru the numbers and set each one to the fixed one
    localChartData = localChartData.map((o: {
      date: string,
      value: number
    }, idx: number) => {
      const {
        value,
        date
      } = o

      if (value === 0) {
        return {
          date: date,
          value: firstFoundValue
        }
      } else {
        return o
      }
    })

    return {
      chartData: localChartData,
      growthData: {
        currentGrowthValue: chartData.growthData.currentGrowthValue,
        percentage: ((localChartData[localChartData.length - 1].value - localChartData[0].value) / localChartData[0].value) * 100
      }
    }
  }

  async function refreshDashboardData() {
    setLoadingFanbase(true)

    // call refresh function 
    try {
      const url = `/dashboard/brand/${currentBrand!.id}/fanbase-refresh`
      const refresher = await Axios.get(url)

      // then on success, re-call the individual fanbase data
      await getContent()
    } catch (e) {
      console.error("Error calling refreshDashboardData", e)
    }
  }

  function refreshConnections() {
    const currentPlatformsData = smallGraphData.map(platformData => {
      const { platformId = 'spotify' } = platformData;
      platformData.needsConnection = currentBrand && NEEDS_CONNECTIONS[platformId](currentBrand);
      return platformData;
    })
    setSmallGraphData(currentPlatformsData)
  }

  async function updateBrandConnections() {
    await reloadBrand()
    refreshConnections()
  }

  const handleShowAlreadyExistsModal = (artist: SpotifyArtist, existingBrand: CurrentBrand) => {
    setOpenAlreadyExistModal(true)
    setArtist(artist)
    setExistingBrand(existingBrand)
    setPlatformsModalOpen(false)
  }
  const handleCloseAlreadyExistsModal = () => {
    setPlatformsModalOpen(false)
    setArtist({} as SpotifyArtist)
    setExistingBrand({} as CurrentBrand)
    setOpenAlreadyExistModal(false)
  }
  const handleGoBackAlreadyExistsModal = () => {
    setPlatformsModalOpen(true)
    setArtist({} as SpotifyArtist)
    setExistingBrand({} as CurrentBrand)
    setOpenAlreadyExistModal(false)
  }

  const POPULAR_CAMPAIGNS = Object.values(availableGoals)
    .filter((o: MarketingCampaignType) => {
      return [
        "increase_record_streams",
        "pre_save",
        'increase_video_views',
        'grow_instagram_engagement'
      ].indexOf(o.key) !== -1
    })
    .map((goal: MarketingCampaignType) => {
      return {
        ...goal
      }
    })

  const quickActionItems = [
    {
      key: "music",
      title: "Build a Music Site",
      icon: MusicSiteIcon,
      link: undefined,
      isWebsite: true  // Add this flag to differentiate from regular campaigns
    },
    ...POPULAR_CAMPAIGNS.filter(campaignType => !campaignType.underMaintenance).map(campaignType => ({
      key: campaignType.link,
      title: campaignType.title,
      icon: campaignType.icon,
      link: `/marketing/new/${campaignType.link}`,
      isWebsite: false
    }))
  ]

  const [initialSiteType, setInitialSiteType] = useState<WebsiteType | null>('record_streams');
  const [openCreateModal, setOpenCreateModal] = useState<boolean>(false)


  const openCreateSiteModal = (websiteKey?: string) => {
    setInitialSiteType(websiteKey as WebsiteType || null);
    setOpenCreateModal(true);
  }

  const closeCreateSiteModal = () => {
    setOpenCreateModal(false);
    setInitialSiteType(null);
  }

  let mobileView = innerWidth <= 768
  return (
    <>
      {currentBrand && (
        <UpgradeToProSucceededModal
          dialogProps={{
            open: showUpgradeToProSucceededModal,
            onClose: closeUpgradeToProSucceededModal,
          }}
          sessionId={sessionId}
          brandSlug={currentBrand.slug}
          brandName={currentBrand.name}
          brandImage={currentBrand.image}
        />
      )}
      <MobileHeader
        currentBrand={currentBrand!}
        setCurrentBrand={setCurrentBrand}
        loadedBrands={loadedBrands}
      />
      {isLoadingFanbase ? (
        <div className="flex-col fixed top-1/2 left-1/2 lg:pl-56 flex items-center transform-gpu -translate-x-1/2 -translate-y-1/2 ">
          <NewLoader black />
          <p className="mt-1 text-center">Loading Dashboard...</p>
        </div>
      ) : (
        <MainContentContainer
          backgroundColor="#f5f3f6"
          className="h-full w-full lg:w-12/12 xl:w-12/12 mx-auto lg:pl-16 lg:pr-8 lg:box-border relative flex-col"
        >
          {showPaymentFailedBanner && (
            <BannerContainer>
              <PaymentFailedBanner customerId={currentBrand?.subscription?.cid} />
            </BannerContainer>
          )}
          <div className="flex flex-col"
          >
            <div className={`bg-white mx-auto md:mt-3 w-full`} style={{
              maxWidth: '1200px',
              borderRadius: '8px'
            }}>
              <div className="bg-white mb-0 md:hidden w-11/12 mx-auto mt-2 "
              >
                <MaestroCampaignRecommender
                  askMaestroSource="Dashboard"
                  hideAskAiBadge={mobileView ? true : false}
                  defaultQueryOptions={[]}
                  campaignCreationSource="maestro-dashboard"
                />
              </div>
              <DefaultAdditionalActions
                hidePrimaryButton
                mobileView={mobileView}
                onButtonClick={console.log}
                campaignItems={quickActionItems}
                stackedView={mobileView}
                additionalItemsText={mobileView ? undefined : "Shortcuts:"}
                onCampaignItemClick={(itemKey: string) => {
                  const item = quickActionItems.find(i => i.key === itemKey);
                  if (item?.isWebsite) {
                    // Handle website creation tracking
                    openCreateSiteModal(itemKey)
                  } else {
                    // Handle regular campaign tracking
                    trackNewCampaign({
                      type: itemKey,
                      from: 'dashboard-quick-actions',
                      zeroState: false,
                      aiQuestion: null,
                      currentBrand: currentBrand
                    });
                  }
                }}
              />

            </div>
         
            <CardContainer
              className={clsx(completed || innerWidth <= 1200 ? 'w-full md:my-3' : 'lg:w-9/12 md:my-3')}
            >
              {/* <nav className="flex border-b border-gray-300 items-center">
                <TabSelector
                  isActive={selectedTab === "overall"}
                  onClick={() => setSelectedTab("overall")}
                >
                  Overall
                </TabSelector>


              </nav> */}


              <div className="px-4 py-3 pb-32 md:py-10 md:pt-2 2xl:pt-3">
                <TabPanel hidden={selectedTab !== "overall"}>
                  <OverallDashboard
                    isLoadingNewDate={isLoadingNewDate}
                    isYTLoginAdmin={isYTLoginAdmin}
                    fanbaseMetrics={fanbaseMetrics}
                    timeRange={selectedRange}
                    setTimeRange={updateDateRange}
                    selectedDate={selectedDate}
                    setSelectedDate={setSelectedDate}
                    isProUser={isProUser}
                    refreshDashboardData={refreshDashboardData}
                    isLoading={isLoadingFanbase}
                    setLoading={setLoadingFanbase}
                    chartData={smallGraphData}
                    overallData={overallStats}
                    setPlatformsModalOpen={(value: any) => {
                      setPlatformsModalOpen(value)
                    }}
                    updateBrandConnections={updateBrandConnections}
                  />
                </TabPanel>
                {/* <TabPanel hidden={selectedTab !== "content"}>
                <OverallContent
                  brandItems={brandContent}
                  isLoading={isLoadingContent} />
              </TabPanel> */}

              </div>
            </CardContainer>
            {/* {
              !completed ?
                <div className={clsx('lg:w-3/12 lg:ml-6 lg:mr-0 lg:my-0 bg-gray-100', innerWidth <= 1200 && 'hidden')}>
                  <GettingStarted
                    platformConnectorOpener={() => {
                      setPlatformsModalOpen(true)
                    }}
                    currentBrand={currentBrand}
                  />
                </div>
                : null
            } */}

          </div>

        </MainContentContainer>

      )}

      <PlatformsConnector
        open={platformsModalOpen}
        closeModal={() => {
          setPlatformsModalOpen(false)
        }}
        updateBrandConnections={updateBrandConnections}
        onArtistAlreadyExist={handleShowAlreadyExistsModal}
      />
      {Boolean(artist) && <AlreadyExistingArtist
        open={openAlreadyExistModal}
        goBack={handleGoBackAlreadyExistsModal}
        closeModal={handleCloseAlreadyExistsModal}
        artist={artist}
        existingBrand={existingBrand}
        setCurrentBrand={setCurrentBrand}
      />}
      <CreateNewSiteModal
        open={openCreateModal}
        closeModal={closeCreateSiteModal}
        initialSiteType={initialSiteType}
      />
    </>
  );
};

export default Dashboard;