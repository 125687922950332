import { useState, createContext, ReactNode, FunctionComponent } from "react";
import { CurrentBrand } from "types/global";
import Axios from "helpers/Interceptor";

interface Context {
    currentBrand: CurrentBrand | undefined;
    setCurrentBrand: (brand: any) => CurrentBrand | undefined;
    reloadBrand: () => Promise<CurrentBrand> | undefined;
    loadingBrand: boolean;
}

export const CurrentBrandContext = createContext<Context>({
    currentBrand: undefined,
    setCurrentBrand: () => undefined,
    reloadBrand: () => undefined,
    loadingBrand: false
});

type Props = {
    brand?: CurrentBrand;
    children: ReactNode[] | ReactNode;
};

const CurrentBrandProvider: FunctionComponent<Props> = ({
    children,
}: Props) => {
    const [storedBrand, setBrand] = useState<CurrentBrand | undefined>(undefined);
    const [loading, setLoading] = useState<boolean>(false);

    async function reloadBrand(): Promise<CurrentBrand> {
        setLoading(true);
        var getBrand = await Axios.get(`/brand/${storedBrand?.id}`);
        const reloadedBrand: CurrentBrand = getBrand.data.data
        setBrand(reloadedBrand);
        setLoading(false);

        return reloadedBrand
    }

    function setCurrentBrand(brand: any): CurrentBrand | undefined {
        // set brand in localStorage so it's used
        // as the default 
        if (brand?.id) {
            localStorage.setItem('selected-brand', brand.id)
        }
        setBrand(brand)
        return brand
    }

    const context = {
        currentBrand: storedBrand,
        setCurrentBrand: setCurrentBrand,
        reloadBrand: reloadBrand,
        loadingBrand: loading
    };

    return (
        <CurrentBrandContext.Provider value={context}>
            {children}
        </CurrentBrandContext.Provider>
    );
};

export default CurrentBrandProvider;
