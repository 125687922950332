import { FunctionComponent, useContext, useEffect, useState } from "react"
import UpgradeToProLabel from "components/shareable/UpgradeToProLabel"
import {
  Headline1,
  Headline1Accent,
  Subtitle2,
  Body2,
  Caption,
  Headline2,
  Headline3,
  Subtitle1,
} from "components/shareable/Typography"
import { SystemColors } from "types/globalStyles"
import {
  TESTIMONIALS,
  getFeatures,
  BillingCycle,
  format,
  labels,
  FeatureType,
} from "./utils"
import useStyles, {
  DialogContent,
  RightContent,
  LeftContent,
  BillingChipContainer,
  Feature,
  FeatureIcon,
  FeatureList,
  MarqueesContainer,
  MarqueeItemContainer,
  SpotifyIconContainer,
  TextContainer,
  FlexContainer,
  FixedContainer,
  TitleContainer,
  BottomBar,
  CloseIconContainer,
  StyledImg,
} from "./styles"
import Marquee from "../Marquee"
import times from "lodash/times"
// import { ReactComponent as SpotifyIcon } from "assets/images/spotify.svg"
import SpotifyIcon from "assets/images/logos/sidebar-collapsed.png"

import TestimonialsCarousel from "../TestimonialsCarousel"
import PrimaryButton from "../PrimaryButton"
import QuestionMarkIcon from "assets/images/question-mark.svg"
import { useMediaQuery, useTheme } from "@material-ui/core"
import { ReactComponent as CloseIcon } from "assets/images/close-black.svg"
import { StripeFormattedData, redirectToCheckout } from "./api"
import { PaymentMethods } from "types/global"
import { CurrentBrandContext } from "Hooks/CurrentBrandContext"
import { BillingChip } from "./Components/BillingChip"
import Intercom from "helpers/Intercom"
import { Container, StyledLabel } from "styles/shared"
import { getBrandPartnerDetails, getPartnerDiscount, isPartner } from "helpers/partners"
import { ADDITIONAL_SPEND_BUDGET, MONTHLY_EXTRA_USER_PRICE, MONTHLY_PRO_PLAN_PRICE, YEARLY_BY_MONTH_PRO_PLAN_PRICE } from "../../../constants"
import { DEFAULT_CURRENCY } from "pages/post-auth/MarketingPage/constants"
import getSymbolFromCurrency from "currency-symbol-map"
import { getConvertedCurrency } from "services/symphonyApi"
import { getNumberWithDecimals } from "helpers/General"
import { CurrentUserContext } from "Hooks/CurrentUserContext"
import { track } from "analytics"

const { ANNUALLY, MONTHLY } = BillingCycle

interface Props {
  source?: string | null
  secondaryButtons?: React.ReactNode[]
}

const list = times(8, () => (
  <MarqueeItemContainer>
    <SpotifyIconContainer>
      <img src={SpotifyIcon} width={16} height={16} />
    </SpotifyIconContainer>
    <Body2 color={SystemColors.PRIMARY_TEXT_COLOR}>
      The easiest way to grow.
    </Body2>
  </MarqueeItemContainer>
))

const getMarqueeProps = (toRight: boolean) => ({
  list,
  marqueeContainerProps: {
    styles: {
      width: "600px",
      marginTop: toRight ? "0px" : "20px",
    },
  },
  toRight,
})

type CurrencyPrices = {
  annualConverted: number,
  monthlyConverted: number,
}

const OnboardingUpgradeToPro: FunctionComponent<Props> = ({ source, secondaryButtons }) => {
  const [billingCycle, setBillingCycle] = useState(MONTHLY)
  const [loading, setLoading] = useState(false)
  const theme = useTheme()
  const mobileView = useMediaQuery(theme.breakpoints.down(901))
  const classes = useStyles({ isMobileView: mobileView })
  const { currentBrand, reloadBrand } = useContext(CurrentBrandContext)
  const [monthlySpendCamptionValue, setMonthlySpendCamptionValue] = useState<number>(ADDITIONAL_SPEND_BUDGET)
  const [monthlyAditionalUserValue, setMonthlyAditionalUserValue] = useState<number>(MONTHLY_EXTRA_USER_PRICE)
  const [currencyPrices, setCurrencyPrices] = useState<CurrencyPrices>({
    annualConverted: YEARLY_BY_MONTH_PRO_PLAN_PRICE,
    monthlyConverted: MONTHLY_PRO_PLAN_PRICE,
  })

  const currencyCode = currentBrand?.currency?.code || DEFAULT_CURRENCY
  const currencySymbol = getSymbolFromCurrency(currencyCode)

  const { freeTrialOver, slug: brandSlug } = currentBrand || {}
  const { price, billed, id: priceId } = labels[billingCycle]



  const handleClickUpgradeToProButton = () => {
    setLoading(true)
    const data = {
      recurring_interval: billingCycle,
      payment_method: PaymentMethods.STRIPE,
      checkout_source: "onboarding"
    } as StripeFormattedData

    track("Checkout Started from Onboarding", { source: source ? source : null });
    redirectToCheckout(data, brandSlug, !freeTrialOver).then(async () => {
      await reloadBrand()
      setLoading(false)
    })
  }


  const fromPartner = getBrandPartnerDetails(currentBrand)
  const partnerDiscount: number | undefined = fromPartner ? fromPartner.discountAmount : 1

  let showPartnerText;
  if (fromPartner) {
    const partnerDiscountPercentage = 100 - (partnerDiscount! * 100)
    const basePartnerLabel = `artists get a ${partnerDiscountPercentage}% discount on monthly plans!`
    switch (fromPartner.partnerId) {
      case 'UM':
        showPartnerText = `UnitedMasters SELECT ${basePartnerLabel}`
        break
      case 'Audiomack':
        showPartnerText = `Audiomack ${basePartnerLabel}`
        break
      case 'CDBaby':
        showPartnerText = `CDBaby ${basePartnerLabel}`
        break
      case 'Daily Playlists':
        showPartnerText = `Daily Playlists ${basePartnerLabel}`
        break
      case 'Downtown':
        showPartnerText = `Downtown Music ${basePartnerLabel}`
        break
    }
  }

  let mainPrice = 20
  if (price && partnerDiscount) {
    mainPrice = currencyPrices.monthlyConverted * partnerDiscount
  }


  const getCaption = (feature: FeatureType) => {
    const { caption, feature: featureName } = feature
    if (typeof caption === 'string') return caption
    switch (featureName) {
      case 'Automated marketing on Instagram + YouTube':
        return (caption!)(currencyCode, monthlySpendCamptionValue)
      case 'Invite up to 2 users to access your profile':
      default:
        return (caption!)(currencyCode, monthlyAditionalUserValue)
    }
  }

  const getPricesValues = async () => {
    const baseMonthly = await getConvertedCurrency({ from: DEFAULT_CURRENCY, to: currencyCode, amount: MONTHLY_PRO_PLAN_PRICE })
    const baseAnnual = await getConvertedCurrency({ from: DEFAULT_CURRENCY, to: currencyCode, amount: YEARLY_BY_MONTH_PRO_PLAN_PRICE })

    setCurrencyPrices({
      annualConverted: Number(getNumberWithDecimals(baseAnnual, 2)),
      monthlyConverted: Number(getNumberWithDecimals(baseMonthly, 2)),
    })
  }

  useEffect(() => {
    if (currencyCode !== DEFAULT_CURRENCY) {
      getConvertedCurrency({ from: DEFAULT_CURRENCY, to: currencyCode, amount: MONTHLY_EXTRA_USER_PRICE })
        .then((res) => setMonthlyAditionalUserValue(Number(getNumberWithDecimals(res, 2))))

      getConvertedCurrency({ from: DEFAULT_CURRENCY, to: currencyCode, amount: ADDITIONAL_SPEND_BUDGET })
        .then((res) => setMonthlySpendCamptionValue(Number(getNumberWithDecimals(res, 2))))

      getPricesValues()
    } else {
      // TODO: remove this else statement when we fix the way we switch from brand to brand, right now it keeps some values unchanged
      if (currencyPrices.monthlyConverted !== MONTHLY_PRO_PLAN_PRICE) {
        setCurrencyPrices({
          annualConverted: YEARLY_BY_MONTH_PRO_PLAN_PRICE,
          monthlyConverted: MONTHLY_PRO_PLAN_PRICE,
        })
        setMonthlyAditionalUserValue(Number(getNumberWithDecimals(MONTHLY_EXTRA_USER_PRICE, 2)))
        setMonthlySpendCamptionValue(Number(getNumberWithDecimals(ADDITIONAL_SPEND_BUDGET, 2)))
      }
    }
  }, [currencyCode])

  return (

    <RightContent
      margin="0px auto 0px"
      padding={mobileView ? "0px" : "0px"}
      width={"100%"}
      maxHeight="unset"
    >

      <UpgradeToProLabel
        margin="0px auto 12px"
        maxWidth="160px"
        color="PURPLE"
        label="Symphony Pro"
      />
      <TitleContainer>
        <Headline1 color={SystemColors.PRIMARY_TEXT_COLOR}>
          Try Symphony Pro for free
        </Headline1>
        <Subtitle2 color={SystemColors.PRIMARY_TEXT_COLOR}>
          Everything you need to analyze, market, and grow your fanbase.* {showPartnerText ? (
            <>🎁 {showPartnerText}
          </>) 
          : ''}
        </Subtitle2>
      </TitleContainer>
   
      <div className="flex flex-col gap-2 items-center mt-1">
        <PrimaryButton
          {...{ loading }}
          width={mobileView ? "100%" : "100%"}
          disabled={loading}
          text={freeTrialOver ? "Upgrade to Pro" : "Start my 14-day Free Trial"}
          onClick={handleClickUpgradeToProButton}
        />
        {secondaryButtons && secondaryButtons.map((button, index) => (
          <div key={`secondary-button-${index}`} className="w-full">
            {button}
          </div>
        ))}
        <StyledLabel
          fontSize={12}>  *Free trial is for 14 days, then ${mainPrice} / month unless you cancel. You'll get an email reminder 7 days before your trial ends. By continuing, you agree to the <a href="https://www.symphonyos.co/terms-of-service" target="_blank" rel="noreferrer"><u>Terms of Service</u></a>, <a href="https://www.symphonyos.co/privacy-policy" target="_blank" rel="noreferrer"><u>Privacy Policy</u></a> and <a href="https://support.symphonyos.co/en/articles/8956441-cancellation-refund-policy" target="_blank" rel="noreferrer"><u>Refund Policy</u></a>.</StyledLabel>

      </div>
      <div className="mt-3 mb-2"><Subtitle2>What's included?</Subtitle2></div>
      <FeatureList marginTop="0px" isMobileView={mobileView}>
        {getFeatures(classes).map(
          ({ icon: Icon, feature, caption, className }, index) => (
            <Feature key={`upgrade-to-pro-modal-feature-${index}`}>
              <FeatureIcon>
                <Icon {...{ className }} />
              </FeatureIcon>
              <TextContainer>
                <Body2 color={SystemColors.PRIMARY_TEXT_COLOR}>
                  {feature}
                </Body2>
                {caption && (
                  <Caption color={SystemColors.SECONDARY_TEXT_COLOR}>
                    {getCaption({ icon: Icon, feature, caption, className })}
                  </Caption>
                )}
              </TextContainer>
            </Feature>
          )
        )}
      </FeatureList>



    </RightContent>
  )
}

export default OnboardingUpgradeToPro
