/* eslint-disable react-hooks/exhaustive-deps */
import { CurrentBrandContext } from "Hooks/CurrentBrandContext"
import {
  useContext,
  useState,
  useCallback,
  createContext,
  ReactNode,
  useEffect,
} from "react"
import { CurrencyCodesType, SpendBudgetData, UserData } from "types/global"
import debounce from "lodash/debounce"
import { getCurrency, getData } from "./api"
import { DEFAULT_CURRENCY } from "pages/post-auth/MarketingPage/constants"
import { COMMON_WIZARD_STEPS } from "./utils"
import { CAMPAIGN_TYPE_ENUM, FB_CAMPAIGN_TYPES } from "../../constants"

const { FINALIZATION, BUDGET } = COMMON_WIZARD_STEPS

interface Context {
  data: SpendBudgetData | null
  loading: boolean
  currency: CurrencyCodesType
  onChangeCurrency: (currency: CurrencyCodesType) => void
  onChangeBudget: (budget: number) => void
}

export const SpendBudgetContext = createContext<Context>({
  data: null,
  loading: false,
  onChangeCurrency: () => undefined,
  onChangeBudget: () => undefined,
  currency: 'USD'
})

interface Props {
  currentUser: UserData;
  fbAccountId?: string | null
  budgetValue: number
  step: string
  campaignType: CAMPAIGN_TYPE_ENUM
  children: ReactNode[] | ReactNode,
  access_token?: string
}

const SpendBudgetProvider = ({
  children,
  step,
  budgetValue,
  fbAccountId,
  campaignType,
  currentUser,
  access_token
}: Props) => {
  const { currentBrand } = useContext(CurrentBrandContext)

  const isOrganization = currentUser?.organization?.status === "ready"
  const brandSlug = currentBrand?.slug
  const [data, setData] = useState<SpendBudgetData | null>(null)
  const [currency, setCurrency] = useState(currentBrand?.currency?.code || DEFAULT_CURRENCY)
  const [isInitialLoad, setIsInitialLoad] = useState(true)
  const [loading, setLoading] = useState(false)

  const onChangeBudget = useCallback(
    debounce(async (budget: number) => {
      setLoading(true)
      const data = await getData({ isOrganization, brandSlug, budget, currency })
      setData(data)
      setLoading(false)
    }, 500),
    [currency]
  ) as unknown as (budget: number) => void

  const onChangeCurrency = async (currency: CurrencyCodesType) => {
    setCurrency(currency)
    if (budgetValue <= 0) return
    setLoading(true)
    const data = await getData({ isOrganization, brandSlug, budget: budgetValue, currency })
    setData(data)
    setLoading(false)
  }

  useEffect(() => {
    if (isInitialLoad) {
      const initialize = async () => {
        let newCurrency = currency
        const isFBCampaign = FB_CAMPAIGN_TYPES.includes(campaignType)
        const validSteps = step === FINALIZATION || step === BUDGET

        if (validSteps && isFBCampaign) {
          newCurrency = await getCurrency(fbAccountId, access_token)
          setCurrency(newCurrency)
        }

        const data = await getData({
          brandSlug,
          isOrganization,
          budget: budgetValue,
          currency: newCurrency,
        })
        setData(data)
        setIsInitialLoad(false)
      }

      initialize()
    }
  }, [isInitialLoad, budgetValue, fbAccountId, campaignType, step])

  const context = {
    data,
    loading,
    currency,
    onChangeCurrency,
    onChangeBudget,
  }

  return (
    <SpendBudgetContext.Provider value={context}>
      {children}
    </SpendBudgetContext.Provider>
  )
}

export default SpendBudgetProvider
