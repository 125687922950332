/* eslint-disable react-hooks/exhaustive-deps */
import {
  FunctionComponent,
  useEffect,
  Dispatch,
  SetStateAction,
  useState,
  Fragment,
  useContext,
  useRef,
} from "react"
import { CurrentBrand, OrganizationData, UserData } from "types/global"
import { Grid, Tab, Tabs } from "@material-ui/core"
import { pageView } from "analytics"
import {
  StyledNavBar,
  TabsPanelsContainer,
  MainContainer,
  Card,
  StyledLabel,
  CreateCampaignButtonContainer,
} from "./styles"
import { TabPanel } from "react-headless-tabs"
import isEqual from "lodash/isEqual"
import startCase from "lodash/startCase"
import lowerCase from "lodash/lowerCase"
import { SystemColors } from "types/globalStyles"
import { PageTabs } from "./utils"
import { isAdmin } from "helpers/admin"
import { useLocation } from "react-router-dom"
import OrganizationZeroState from "./ZeroState"
import OrganizationNeedsSetupState from "./SetupStatus"
import OrganizationFinish from "./ReadyState"
import { OrganizationBilling } from "./BillingTab"
import { CurrentUserContext } from "Hooks/CurrentUserContext"
import MainConfetti from "components/shareable/Confetti"
import DialogPaper from "components/shareable/DialogPaper"
import { ReactComponent as TadaIcon } from "assets/images/tada.svg"
import HeaderModal from "components/connect/connectModals/Spotify/Components/HeaderModal";
import { ModalContainer } from "components/shareable/DialogPaper/style"
import PrimaryButton from "components/shareable/PrimaryButton"
import { TeamPlanProvider } from "./hooks/TeamMembersContext"
import { TeamTab } from "./Components/TeamTab"
import { CurrentBrandContext } from "Hooks/CurrentBrandContext"
import { isOrganizationAdministrator } from "utils"
import { useHistory } from "react-router-dom";
import { OrganizationMarketingTab } from "./Components/OrganizationMarketingTab"
import MaestroCampaignRecommender from "../MarketingPage/Components/CreateNewCampaignModal/MaestroCampaignRecommender"
import { Body2, Headline3 } from "../../../components/shareable/Typography"
import { StarsIcon } from "components/svg-icons";
import { CAMPAIGN_CARDS } from "../MarketingPage/utils/marketing"
import CreateCampaignCard from "../MarketingPage/Components/CreateNewCampaignModal/CreateCampaignCard"
import { trackNewCampaign } from "../MarketingPage/Components/CampaignsSection/analytics"
import GeneralPurpleButton from "components/shareable/CommonComponent/PurpleButton"
import CreateCampaignModal from "components/shareable/CreateCampaignModal"

interface Props {
  loadedBrands: CurrentBrand[]
  user: UserData;
  reloadBrands: () => void;
}

const { BRANDS, MARKETING_CAMPAIGNS, BILLING, TEAM } = PageTabs

const tabs = [
  BRANDS, MARKETING_CAMPAIGNS, TEAM, BILLING,
].map((tab) => ({
  value: tab,
  name: startCase(lowerCase(tab)),
}))


enum OrganizationSetupStatus {
  NOT_STARTED = "NOT_STARTED",
  PAID_BUT_NEEDS_SETUP = "PAID_BUT_NEEDS_SETUP",
  SETUP = "SETUP"
}

const OrganizationPage: FunctionComponent<Props> = ({
  loadedBrands,
  user,
  reloadBrands,
}: Props) => {
  const history = useHistory()
  const { reloadUser } = useContext(CurrentUserContext);
  const { currentBrand, setCurrentBrand } = useContext(CurrentBrandContext);
  const userIsAdmin = user ? isAdmin(user) : false
  const [materialTabValue, setMaterialTabValue] = useState<PageTabs>(
    PageTabs.BRANDS
  )

  const currentOrganization: OrganizationData | undefined | null = user.organization
  const currentOrganizationStatus = determineOrganizationStatus()
  const [organizationSetupStatus, setOrganizationSetupStatus] = useState<OrganizationSetupStatus>(currentOrganizationStatus || OrganizationSetupStatus.NOT_STARTED)

  // only show confetti if a user is on the screen, and just finished pro setup. 
  // dont show it any other times
  const [showConfetti, setShowConfetti] = useState(false)
  const [openCompletedModal, setOpenCompletedModal] = useState(false)
  const prevOrganizationSetupStatus = useRef<OrganizationSetupStatus | null>(null);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const tabFromQuery = queryParams.get("tab") as PageTabs;

  const [campaignModalOpen, setCampaignModalOpen] = useState(false);  
  const openCreateCampaignModal = () => {
    setCampaignModalOpen(true);
  }

  useEffect(() => {
    if (tabFromQuery && Object.values(PageTabs).includes(tabFromQuery)) {
      setMaterialTabValue(tabFromQuery);
    }
  }, [tabFromQuery]);

  useEffect(() => {
    pageView("Organization");
    if (currentOrganization) {
      if (!isOrganizationAdministrator(currentOrganization)) history.push("/dashboard");
    }
  }, []);

  useEffect(() => {
    setOrganizationSetupStatus(determineOrganizationStatus())
  }, [currentOrganization])

  useEffect(() => {
    if (showConfetti) {
      setOpenCompletedModal(true)
    }
  }, [showConfetti])

  useEffect(() => {
    // Capture the status change specifically from PAID_BUT_NEEDS_SETUP to SETUP
    if (prevOrganizationSetupStatus.current === OrganizationSetupStatus.PAID_BUT_NEEDS_SETUP && organizationSetupStatus === OrganizationSetupStatus.SETUP) {
      setShowConfetti(true);
    } else {
      setShowConfetti(false);
    }
    // Update the ref to the current status for the next render
    prevOrganizationSetupStatus.current = organizationSetupStatus;
  }, [organizationSetupStatus]);

  // uses the user.metadata.organization_subscription to determine the status of the organization
  // and display the relevant page:
  //  - not started (payment not set up)
  //  - paid but needs setup (user paid but needs to select artists)
  // - setup (user has selected artists)
  function determineOrganizationStatus() {
    const organizationSetupStatus = user.organization?.status

    if (!user.organization) {
      return OrganizationSetupStatus.NOT_STARTED
    } else {
      switch (organizationSetupStatus) {
        case "ready":
          return OrganizationSetupStatus.SETUP
        case "waiting_for_details":
          return OrganizationSetupStatus.PAID_BUT_NEEDS_SETUP
        default:
          return OrganizationSetupStatus.NOT_STARTED
      }
    }
  }

  // refreshes the local organizaiton state 
  async function refreshStatus() {
    await reloadUser()
    await reloadBrands()
  }

  // Renders the UI based on organization setup status
  function renderPrimaryView() {
    switch (organizationSetupStatus) {
      case OrganizationSetupStatus.NOT_STARTED:
        return (
          <>
            <Card padding="40px 24px" borderRadius="8px" marginBottom="16px"
              width="650px">
              <OrganizationZeroState currentBrand={currentBrand!} />
            </Card>
          </>
        )
      case OrganizationSetupStatus.PAID_BUT_NEEDS_SETUP:
        return (
          <>
            <Card padding="40px 24px" borderRadius="8px" marginBottom="16px"
              width="650px">
              <OrganizationNeedsSetupState currentBrand={currentBrand!}
                existingBrands={loadedBrands}
                organization={currentOrganization!}
                onFinished={() => refreshStatus()} />
            </Card>
          </>
        )
      case OrganizationSetupStatus.SETUP:
        return (
          <>
            <Card padding="40px 24px" borderRadius="8px" maxWidth="1200px">
              <StyledLabel fontSize={28}>{currentOrganization?.name}</StyledLabel>
              <StyledNavBar>
                <Tabs
                  style={{ flex: '1'}}
                  scrollButtons="auto"
                  variant="scrollable"
                  value={materialTabValue}
                  onChange={(_event, newValue) => setMaterialTabValue(newValue)}
                  aria-label="Settings Options Selector"
                  TabIndicatorProps={{
                    style: { backgroundColor: SystemColors.ACCENT_COLOR },
                  }}
                >
                  {tabs.map(({ name, value }) => (
                    <Tab
                      key={value}
                      disableRipple
                      value={value}
                      label={
                        <span className="capitalize font-medium text-base px-4 py-2">
                          {name}
                        </span>
                      }
                    />
                  ))}
                </Tabs>
                {
                  isEqual(materialTabValue, MARKETING_CAMPAIGNS) && (
                    <CreateCampaignButtonContainer>
                      <GeneralPurpleButton
                        fullWidthOnMobile={true}
                        onClick={openCreateCampaignModal}>
                        Create campaign
                      </GeneralPurpleButton>
                    </CreateCampaignButtonContainer>
                  )
                }
              </StyledNavBar>
              <TabsPanelsContainer>
                <TeamPlanProvider>
                  <TabPanel hidden={!isEqual(materialTabValue, BRANDS)}>
                    <OrganizationFinish
                      setCurrentBrand={setCurrentBrand}
                      reloadOrganization={() => refreshStatus()}
                      organization={currentOrganization!}
                      currentBrand={currentBrand!}
                      existingBrands={loadedBrands} />
                    {showConfetti && (
                      <>
                        <MainConfetti
                          numberOfPieces={600} />
                      </>)}
                    <DialogPaper open={openCompletedModal}>
                      <ModalContainer>
                        <HeaderModal
                          closeModal={() => setOpenCompletedModal(false)}
                          title="Welcome to the Symphony for Teams Beta!"
                          subtitle="We've got a lot of exciting new features on the way for the Team plan. If you have any ideas or feedback, reach out to us - we'd love to hear from you."
                          HeaderIcon={<TadaIcon />}
                        />
                        <PrimaryButton onClick={() => {
                          setOpenCompletedModal(false)
                        }} text="Let's Go" />
                      </ModalContainer>
                    </DialogPaper>
                  </TabPanel>
                  <TabPanel hidden={!isEqual(materialTabValue, BILLING)}>
                    <OrganizationBilling
                      isAdmin={userIsAdmin}
                      organizationBrands={loadedBrands}
                      organization={currentOrganization!}
                    />
                  </TabPanel>
                  <TabPanel hidden={!isEqual(materialTabValue, TEAM)}>
                    <TeamTab/>
                  </TabPanel>
                  <TabPanel hidden={!isEqual(materialTabValue, MARKETING_CAMPAIGNS)}>
                    <OrganizationMarketingTab/>
                  </TabPanel>
                  <CreateCampaignModal
                    isOpen={campaignModalOpen}
                    onClose={() => setCampaignModalOpen(false)}
                    existingBrands={loadedBrands}
                    setCurrentBrand={setCurrentBrand}
                    currentBrand={currentBrand}
                  />
                </TeamPlanProvider>
              </TabsPanelsContainer>
            </Card>
            {
              isEqual(materialTabValue, MARKETING_CAMPAIGNS) && (
                <Card padding="40px 24px" borderRadius="8px" maxWidth="1200px">
                  <div className="flex flex-col gap-2">
                    <div className="flex flex-col items-center p-4 gap-2">
                      <StarsIcon color="#8800FF" />
                      <Headline3 color={SystemColors.PRIMARY_TEXT_COLOR}>Ask Maestro AI anything about your fanbase</Headline3>
                    </div>
                    <MaestroCampaignRecommender
                      askMaestroSource="Marketing - Team Plan"
                    />
                    <>
                      <div className="w-full flex flex-row items-center justify-center px-3 gap-2">
                        <Body2 color={SystemColors.SECONDARY_TEXT_COLOR}>Or Choose a Playbook:</Body2>
                        <div className="h-0.5 flex-1 w-auto" style={{ backgroundColor: SystemColors.DIVIDER_LINE }}></div>
                      </div>
                      <div>
                        <Grid container spacing={2}>
                          {CAMPAIGN_CARDS
                            .sort((a: any, b: any) => {
                              if (a.underMaintenance && !b.underMaintenance) {
                                return 1;
                              }
                              if (!a.underMaintenance && b.underMaintenance) {
                                return -1;
                              }
                              return 0;
                            })
                            .map((campaign, index) => {
                              if (campaign.underMaintenance) {
                                return (
                                  <Grid item key={index} xs={12} sm={6} md={4}>
                                    <CreateCampaignCard
                                      requiresPro={campaign.requiresPro}
                                      title={campaign.title}
                                      description={campaign.description}
                                      imageUrl={campaign.imageUrl}
                                      chipLabel
                                      statusMessage={
                                        campaign.underMaintenance
                                          ? campaign.statusMessage
                                          : null
                                      }
                                      status={
                                        campaign.underMaintenance ? "maintenance" : null
                                      }
                                    />
                                  </Grid>
                                );
                              } else {
                                return (
                                  <Grid item key={index} xs={12} sm={6} md={4}>
                                    <CreateCampaignCard
                                      requiresPro={campaign.requiresPro}
                                      link={`/marketing/new/${campaign.link}`}
                                      chipLabel
                                      onLinkClick={() => {
                                        trackNewCampaign({
                                          type: campaign.title,
                                          from: "list",
                                          zeroState: false,
                                          aiQuestion: null,
                                          currentBrand,
                                        });
                                      }}
                                      title={campaign.title}
                                      description={campaign.description}
                                      imageUrl={campaign.imageUrl}
                                      status={
                                        campaign.underMaintenance ? "maintenance" : null
                                      }
                                      statusMessage={
                                        campaign.underMaintenance
                                          ? campaign.statusMessage
                                          : null
                                      }
                                      isConversionsCampaign={
                                        campaign.isConversionsCampaign
                                      }
                                    />
                                  </Grid>
                                );
                              }
                            })}
                        </Grid>
                      </div>
                    </>
                  </div>
                </Card>
              )
            }
          </>
        )
    }
  }

  return (
    <Fragment>
      <MainContainer>
        {renderPrimaryView()}
      </MainContainer>
    </Fragment>
  )
}

export default OrganizationPage
