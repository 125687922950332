/**
 * App Constants
 */
import keyBy from "lodash/keyBy";
import { globalDefaultLocations, touringDefaultLocations } from "pages/post-auth/MarketingPage/SongAds/TargetingView/data";

export const TABS = {
  RECORD: "record",
  LINK: "link",
  CREATIVE: "creative",
  TARGETING: "targeting",
  BUDGET: "budget",
  FINALIZATION: "finalization",
};

export const MONTHLY_EXTRA_USER_PRICE = 5;
export const MONTHLY_PRO_PLAN_PRICE = 20.00;
export const YEARLY_BY_MONTH_PRO_PLAN_PRICE = (MONTHLY_PRO_PLAN_PRICE * 10) / 12;
export const ADDITIONAL_SPEND_BUDGET = 1000;
export const MAX_ADDITIONAL_USERS_WITH_PRO = 2;
export const OLD_PLAN_PRICE = 15;

// TEAM PLAN
export const MONTHLY_TEAM_PLAN_PRICE = 55.00
export const MONTHLY_TEAM_PLAN_PRICE_EXTRA_USER = 15.00
export const MAX_ADDITIONAL_USERS_WITH_TEAM_PRO = 3;
export const ADDITIONAL_SPEND_BUDGET_TEAM = 7500;
// TODO: check if this calculation is correct or change it
export const YEARLY_BY_MONTH_TEAM_PLAN_PRICE = (MONTHLY_TEAM_PLAN_PRICE * 10) / 12

export const PRO_ADS_COMISSION = 0;

export const FREE_ADS_COMISSION = 0.1;

export const stage: "development" | "production" | "review" = process.env.NODE_ENV as "development" | "production" | "review";

export const isDev = stage !== "production";

export const SYMPHONY_WEBSITE_URL = isDev
  ? "http://localhost:3001"
  : stage === "review"
    ? "https://symphonyos.app"
    : "https://symphony.to";


export const CAMPAIGN_WIZARD_ROUTES = [
  "/marketing/new/music-streams",
  "/marketing/new/grow-playlist-followers",
  "/marketing/new/grow-instagram-engagement",
  "/marketing/new/playlists",
  "/marketing/new/video-views",
  "/marketing/new/tiktok-ads",
  "/marketing/new/presave",
  "/marketing/new/creatives",
  "/marketing/new/link-clicks",
  "/marketing/success",
];


export enum CAMPAIGN_TYPE_ENUM {
  record_streams = 'record_streams',
  grow_playlist_followers = 'grow_playlist_followers',
  get_playlisted = 'get_playlisted',
  increase_video_views = 'increase_video_views',
  pre_save = 'pre_save',
  email_fanbase = 'email_fanbase',
  link_clicks = 'link_clicks',
  creative_generator = 'creative_generator',
  boost_instagram = 'boost_instagram'
}

export const CAMPAIGN_TYPES = {
  record_streams: CAMPAIGN_TYPE_ENUM.record_streams,
  grow_playlist_followers: CAMPAIGN_TYPE_ENUM.grow_playlist_followers,
  get_playlisted: CAMPAIGN_TYPE_ENUM.get_playlisted,
  increase_video_views: CAMPAIGN_TYPE_ENUM.increase_video_views,
  pre_save: CAMPAIGN_TYPE_ENUM.pre_save,
  email_fanbase: CAMPAIGN_TYPE_ENUM.email_fanbase,
  link_clicks: CAMPAIGN_TYPE_ENUM.link_clicks,
  creative_generator: CAMPAIGN_TYPE_ENUM.creative_generator,
  boost_instagram: CAMPAIGN_TYPE_ENUM.boost_instagram
};

export const FB_CAMPAIGN_TYPES = [
  CAMPAIGN_TYPES.record_streams,
  CAMPAIGN_TYPES.link_clicks,
  CAMPAIGN_TYPES.boost_instagram,
  CAMPAIGN_TYPES.grow_playlist_followers
];

export const CAMPAIGN_TYPE_MAPPINGS: Record<string, string> = {
  record_streams: "Increase Music Streams",
  grow_playlist_followers: "Grow Playlist Followers",
  link_clicks: "Increase Link Clicks",
  increase_sales: "Increase Sales",
  get_playlisted: "Pitch Playlists",
  increase_video_views: "Increase Video Views",
  boost_instagram: "Boost Instagram Engagement",
  pre_save: "Pre-save Campaign",
}

export const GEOGRAPHY_TARGETING_DEFAULTS = [
  {
    id: "touring-markets",
    description:
      "This audience automatically targets people in countries where you're likely to tour, including the US, Canada, and primary markets in Europe.",
    name: "Touring Markets",
    budget: 0.7,
    locations: keyBy(touringDefaultLocations, "value"),
  },
  {
    id: "global-markets",
    description:
      "This audience automatically targets people in countries where it's cheaper to drive a stream, to help build your global fanbase and drive your algorithmic streams.",
    name: "Global Markets",
    budget: 0.3,
    locations: keyBy(globalDefaultLocations, "value"),
  },
];


export const PLATFORMS = {
  spotify: 'spotify',
  apple_music: 'apple_music',
  youtube: 'youtube',
  deezer: 'deezer',
  sound_cloud: 'soundcloud'
}

export const DEBOUNCE_SAVE_DRAFT_TIME = 3000

export const CURRENCY_SYMBOLS = {
  AED: 'د.إ',
  AFN: '؋',
  ALL: 'L',
  AMD: '֏',
  ANG: 'ƒ',
  AOA: 'Kz',
  ARS: '$',
  AUD: '$',
  AWG: 'ƒ',
  AZN: '₼',
  BAM: 'KM',
  BBD: '$',
  BDT: '৳',
  BGN: 'лв',
  BHD: '.د.ب',
  BIF: 'FBu',
  BMD: '$',
  BND: '$',
  BOB: '$b',
  BOV: 'BOV',
  BRL: 'R$',
  BSD: '$',
  BTC: '₿',
  BTN: 'Nu.',
  BWP: 'P',
  BYN: 'Br',
  BYR: 'Br',
  BZD: 'BZ$',
  CAD: '$',
  CDF: 'FC',
  CHE: 'CHE',
  CHF: 'CHF',
  CHW: 'CHW',
  CLF: 'CLF',
  CLP: '$',
  CNH: '¥',
  CNY: '¥',
  COP: '$',
  COU: 'COU',
  CRC: '₡',
  CUC: '$',
  CUP: '₱',
  CVE: '$',
  CZK: 'Kč',
  DJF: 'Fdj',
  DKK: 'kr',
  DOP: 'RD$',
  DZD: 'دج',
  EEK: 'kr',
  EGP: '£',
  ERN: 'Nfk',
  ETB: 'Br',
  ETH: 'Ξ',
  EUR: '€',
  FJD: '$',
  FKP: '£',
  GBP: '£',
  GEL: '₾',
  GGP: '£',
  GHC: '₵',
  GHS: 'GH₵',
  GIP: '£',
  GMD: 'D',
  GNF: 'FG',
  GTQ: 'Q',
  GYD: '$',
  HKD: '$',
  HNL: 'L',
  HRK: 'kn',
  HTG: 'G',
  HUF: 'Ft',
  IDR: 'Rp',
  ILS: '₪',
  IMP: '£',
  INR: '₹',
  IQD: 'ع.د',
  IRR: '﷼',
  ISK: 'kr',
  JEP: '£',
  JMD: 'J$',
  JOD: 'JD',
  JPY: '¥',
  KES: 'KSh',
  KGS: 'лв',
  KHR: '៛',
  KMF: 'CF',
  KPW: '₩',
  KRW: '₩',
  KWD: 'KD',
  KYD: '$',
  KZT: '₸',
  LAK: '₭',
  LBP: '£',
  LKR: '₨',
  LRD: '$',
  LSL: 'M',
  LTC: 'Ł',
  LTL: 'Lt',
  LVL: 'Ls',
  LYD: 'LD',
  MAD: 'MAD',
  MDL: 'lei',
  MGA: 'Ar',
  MKD: 'ден',
  MMK: 'K',
  MNT: '₮',
  MOP: 'MOP$',
  MRO: 'UM',
  MRU: 'UM',
  MUR: '₨',
  MVR: 'Rf',
  MWK: 'MK',
  MXN: '$',
  MXV: 'MXV',
  MYR: 'RM',
  MZN: 'MT',
  NAD: '$',
  NGN: '₦',
  NIO: 'C$',
  NOK: 'kr',
  NPR: '₨',
  NZD: '$',
  OMR: '﷼',
  PAB: 'B/.',
  PEN: 'S/.',
  PGK: 'K',
  PHP: '₱',
  PKR: '₨',
  PLN: 'zł',
  PYG: 'Gs',
  QAR: '﷼',
  RMB: '￥',
  RON: 'lei',
  RSD: 'Дин.',
  RUB: '₽',
  RWF: 'R₣',
  SAR: '﷼',
  SBD: '$',
  SCR: '₨',
  SDG: 'ج.س.',
  SEK: 'kr',
  SGD: 'S$',
  SHP: '£',
  SLL: 'Le',
  SOS: 'S',
  SRD: '$',
  SSP: '£',
  STD: 'Db',
  STN: 'Db',
  SVC: '$',
  SYP: '£',
  SZL: 'E',
  THB: '฿',
  TJS: 'SM',
  TMT: 'T',
  TND: 'د.ت',
  TOP: 'T$',
  TRL: '₤',
  TRY: '₺',
  TTD: 'TT$',
  TVD: '$',
  TWD: 'NT$',
  TZS: 'TSh',
  UAH: '₴',
  UGX: 'USh',
  USD: '$',
  UYI: 'UYI',
  UYU: '$U',
  UYW: 'UYW',
  UZS: 'лв',
  VEF: 'Bs',
  VES: 'Bs.S',
  VND: '₫',
  VUV: 'VT',
  WST: 'WS$',
  XAF: 'FCFA',
  XBT: 'Ƀ',
  XCD: '$',
  XOF: 'CFA',
  XPF: '₣',
  XSU: 'Sucre',
  XUA: 'XUA',
  YER: '﷼',
  ZAR: 'R',
  ZMW: 'ZK',
  ZWD: 'Z$',
  ZWL: '$'
}