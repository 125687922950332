import { FunctionComponent } from "react";
import { lightOrDark } from "helpers/StyleUtils";
import { RecordPreviewData, TitleAlignments } from "types/global";
import isEqual from "lodash/isEqual";
import { StyleTypes, BoxContrasts, BackgroundTypes } from "types/global";
import MusicalNoteImage from "assets/images/placeholders/musical-note.png";
import useStyles, { StyledIcon } from "./styles";
import {
  EMPTY_RECORD_NAME,
  getArtworkClassName,
  getContrastColorClassName,
  getFormattedOptions,
  getImageClassName,
  getOptionContainerClassName,
  getPrincipalContainerBackgroundColor,
  getPrincipalContainerStyle,
} from "./utils";
import { STACKED } from "pages/post-auth/MarketingPage/PreSave/constants";
import useMediaBreakpoints from "Hooks/useMediaBreakpoints";

export interface Props {
  contentDetails: RecordPreviewData;
  backgroundType: string;
  backgroundColor: string;
  mainColor: string;
  secondaryColor: string;
  titleAlignment?: TitleAlignments;
  styleType: StyleTypes;
  top?: number | string;
  className?: string;
  relative?: boolean;
  creation?: boolean;
}

const { BOXES, TRADITIONAL } = StyleTypes;

const RecordPreview: FunctionComponent<Props> = ({
  contentDetails,
  backgroundType,
  backgroundColor,
  mainColor,
  secondaryColor,
  styleType,
  top,
  className,
  relative = false,
  creation = true,
  titleAlignment = TitleAlignments.CENTER,
}: Props) => {
  const classes = useStyles();
  const { mobileView, largeView } = useMediaBreakpoints()
  const { name: recordName, artworkUrl, options, subtitle } = contentDetails;
  const mainBoxContrast = lightOrDark(mainColor) as BoxContrasts;
  const secondaryBoxContrast = lightOrDark(secondaryColor) as BoxContrasts;
  const imageClassName = getImageClassName(styleType);
  const artworkClassName = getArtworkClassName(artworkUrl);
  const principalContainerBackgroundColor =
    getPrincipalContainerBackgroundColor(backgroundType, backgroundColor);
  const contrastColorClassName = getContrastColorClassName(mainBoxContrast);
  const secondaryContrastColorClassName = getContrastColorClassName(secondaryBoxContrast);
  const optionContainerClassName = getOptionContainerClassName(styleType);
  const actionBoxContrast = isEqual(styleType, StyleTypes.TRADITIONAL) ?
    mainBoxContrast
    :
    mainBoxContrast;
  const formatted = getFormattedOptions({
    options,
    actionBoxContrast: isEqual(styleType, BOXES) ? secondaryBoxContrast : actionBoxContrast,
    styleType,
    relative,
    creation,
  });
  const principalStyle = getPrincipalContainerStyle({
    backgroundType: backgroundType as BackgroundTypes,
    backgroundColor: principalContainerBackgroundColor,
    top,
    relative,
  });

  return (
    <div
      className={className || `w-full ${!relative && "h-80 pb-52"} z-10 lg:pb-0`}
      style={principalStyle}
    >
      <div className="bg-transparent w-full h-full">
        <div>
          <img
            alt="thumbnail"
            src={artworkUrl || MusicalNoteImage}
            className={artworkClassName}
          />
        </div>
        <div
          className="w-auto lg:w-auto text-center bg-white m-auto"
          style={{ backgroundColor: mainColor, borderRadius: '0 0 0.3em 0.3em' }}
        >
          <div className={contrastColorClassName} style={{ padding: '3% 4%' }}>
            <p
              style={{ textAlign: titleAlignment }}
              className={creation ? "text-lg font-normal uppercase mb-0" : relative ? classes.relativeTitle : classes.title}
            >
              PRE-SAVE
            </p>
            <p
              style={{ textAlign: titleAlignment }}
              className={creation ? "text-xl mt-0 font-semibold" : relative ? classes.relativeName : classes.name}
            >
              {recordName || EMPTY_RECORD_NAME}
            </p>
            {subtitle ?
              <p
                style={{ textAlign: titleAlignment }}
                className={creation ? "text-md font-weight-500" : relative ? classes.relativeSubtitle : classes.subtitle}
              >
                {subtitle}
              </p>
              :
              null
            }
          </div>
          {options && (
            <div className={`flex align-center justify-evenly flex-wrap ${classes.anchors}`}>
              {formatted.map(({ name, url, imageDefault, anchorClassName }, index) => (
                <a
                  key={name}
                  href={url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={anchorClassName}
                  style={{
                    backgroundColor: isEqual(styleType, BOXES) ?
                      secondaryColor
                      :
                      undefined,
                  }}
                >
                  <div className={optionContainerClassName}>
                    {isEqual(styleType, STACKED) && imageDefault && relative ? (
                      <StyledIcon width={mobileView ? "20vw" : "3vw"} src={imageDefault} />
                    ) :
                      isEqual(styleType, STACKED) && imageDefault && !creation ? (
                        <StyledIcon width={largeView ? "150px" : "13vw"} src={imageDefault} />
                      ) :
                        imageDefault ?
                          <img
                            alt="default"
                            className={`${imageClassName} ${name}`}
                            src={imageDefault}
                          />
                          :
                          <p>{name}</p>
                    }
                    {isEqual(styleType, TRADITIONAL) && (
                      <div
                        className={`
                          ${creation ? classes.creationButton : classes.button}
                          ${relative && 'traditional-button'}
                          ${index === formatted.length - 1 && 'bb-0'}
                          text-md
                        `}
                        style={{ backgroundColor: secondaryColor }}
                      >
                        <span className={getContrastColorClassName(lightOrDark(secondaryColor) as BoxContrasts)}>Pre-save</span>
                      </div>
                    )}
                  </div>
                </a>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default RecordPreview;
