import { TabSelector } from "helpers/TabSelector";
import {
    InputContainer,
    Card,
    CardInfo,
    CardSubContainer,
    ProjectImage,
    ProjectName,
    StyledContentList,
    useStyles
} from "./shared-styles"
import { ChangeEvent, Fragment, useEffect, useMemo, useState } from "react";
import { InputAdornment, TextField } from "@material-ui/core";
import { getRecentReleases, getRefreshReleases } from "../api";
import { CurrentBrand, Release } from "types/global";
import { ReactComponent as SearchIcon } from "assets/images/search-icon-2.svg"
import Loaderv2 from "components/Loader/v2";
import ErrorBanner from "./ErrorBanner";
import { searchSpotifyReleases } from "./shared-music-selector-utils";
import { Album, FormattedRecords, FormattedSearchSpotifyResults, Playlist, SpotifyResultSearchContent, Track } from "./shared-music-selector-types";
import { isValidURL } from "utils";
import { Caption, Subtitle2 } from "components/shareable/Typography";
import { SystemColors } from "types/globalStyles";
import debounce from "lodash.debounce"
import { searchSpotifyByText } from "services/symphonyApi/brandContentService"
import { getFormattedRecords } from "../../../utils"
import millify from "millify";
import dayjs from "dayjs";

interface SharedMusicViewSelectorProps {
    currentBrand: CurrentBrand;
    isSpotifyConnected: boolean;
    parentLoading?: boolean;
    parentError?: boolean;
    letUserSearchSpotify?: boolean;
    letUserRefreshReleases?: boolean,
    options?: ("single" | "project" | "playlist")[];
    handleChangeRecord: (inputValue: string, slug: string | null) => void
}

enum Tabs {
    SINGLE = "single",
    PROJECT = "project",
    PLAYLIST = "playlist",
}

const { SINGLE, PROJECT, PLAYLIST } = Tabs

const ExplicitIcon = () => (
    <p className="rounded-sm box-border ml-2 bg-gray-300 p-2 h-4 w-4 flex items-center justify-center">
        <span className="text-xs font-medium">E</span>
    </p>
)

export default function SharedMusicViewSelector({
    currentBrand,
    isSpotifyConnected,
    parentLoading,
    parentError,
    letUserSearchSpotify,
    letUserRefreshReleases,
    options = ['single', 'project', 'playlist'],
    handleChangeRecord,
}: SharedMusicViewSelectorProps) {
    const classes = useStyles()

    let allowPlaylist = options.includes(PLAYLIST)
    let allowSingle = options.includes(SINGLE)
    let allowProject = options.includes(PROJECT)

    let isPlaylistCampaign = options.length === 1 && options[0] === PLAYLIST


    const [selectedTab, setSelectedTab] = useState<Tabs>(options ? (options[0] as Tabs) : SINGLE)
    const [resultType, setResultType] = useState<Tabs>()
    const [inputValue, setInputValue] = useState<string>("")
    const [searchResult, setSearchedResult] = useState<FormattedSearchSpotifyResults | null>(null)
    const [loadingReleases, setLoadingReleases] = useState(false)
    const [recentReleases, setRecentReleases] = useState<Release[] | null>(null)
    const [errorReleases, setErrorReleases] = useState(false)
    const [errorSearchResult, setErrorSearchResult] = useState(false)
    const [loadingSearchResult, setLoadingSearchResult] = useState(false)
    const [records, setRecords] = useState<FormattedRecords[] | null>([])
    const [isSearchBySongName, setIsSearchBySongName] = useState(false)
    const [searchResultsBySongName, setSearchResultsBySongName] = useState<SpotifyResultSearchContent | null>(null)
    // releases puller
    const [refreshingReleases, setRefreshingReleases] = useState<boolean>(false)

    const loading = loadingReleases || parentLoading || loadingSearchResult
    const error = errorReleases || parentError || errorSearchResult

    const handleChangeTab = (tab: Tabs) => () => {
        setSelectedTab(tab)
    }

    const debouncedChangeHandler = useMemo(
        () =>
            debounce((value: string, releases: Release[] | null, tab: Tabs) => {
                handleSearch(value, releases, tab)
            }, 500),
        []
    )

    const handleSearch = (value: string, releases: Release[] | null, tab: Tabs) => {
        const formattedRecords = getFormattedRecords({
            recentReleases: releases,
            releaseType: tab,
            search: value,
        })

        if (isValidURL(value)) {
            getSearchedResults(value)
            setIsSearchBySongName(false)
            setSearchResultsBySongName(null)
        } else if (formattedRecords.length > 0) {
            setSearchedResult(null)
            setIsSearchBySongName(false)
            setSearchResultsBySongName(null)
            console.log("formattedRecords", formattedRecords)
            setRecords(formattedRecords)
        } else if (value.length > 0) {
            getSearchedResultsByName(value, tab)
        }
    }

    useEffect(() => {
        handleSearch(inputValue, recentReleases, selectedTab)
    }, [recentReleases, selectedTab])

    useEffect(() => {
        (async () => {
            setLoadingReleases(true)
            const { data, error } = await getRecentReleases(currentBrand)

            if (data && !error) {
                setRecentReleases(data)
            } else {
                setErrorReleases(true)
            }
            setLoadingReleases(false)
        })()
    }, [currentBrand, isSpotifyConnected])

    async function getSearchedResultsByName(value: string, tab: Tabs) {
        setLoadingSearchResult(true)
        try {
            const response = await searchSpotifyByText(value, 30)
            if (response?.data) {
                setSearchResultsBySongName(response.data)
            }
        } catch (e) {
            console.log("Error getting search results", e)
            setErrorSearchResult(true)
        }
        setIsSearchBySongName(true)
        setLoadingSearchResult(false)
    }

    async function getSearchedResults(value: string) {
        setLoadingSearchResult(true)
        let type = SINGLE;
        try {
            const response = await searchSpotifyReleases(value)
            if (response?.data) {
                const spotifyType = response.data.content_metadata.type

                switch (spotifyType) {
                    case "single":
                        type = SINGLE
                        break;
                    case "project":
                    case "album":
                        const numberOfTracks = response.data.content_metadata.tracks.length
                        if (numberOfTracks < 2) type = SINGLE
                        else type = PROJECT
                        break;
                    case "playlist":
                        if (!allowPlaylist) {
                            setLoadingSearchResult(false)
                            return
                        }
                        type = PLAYLIST
                        break;
                }
                setSearchedResult(response.data)
            }
        } catch (e) {
            console.log("Error getting search results", e)
            setErrorSearchResult(true)
        }
        setLoadingSearchResult(false)

        // ensure for playlist campaigns, that playlist is always
        // selected
        if (options.length === 1 && options[0] === 'playlist') {
            setSelectedTab(PLAYLIST)
        } else {
            setSelectedTab(type)
        }
        setResultType(type)
    }

    const handleTryAgain = async () => {
        setLoadingReleases(true)
        const { data, error } = await getRecentReleases(currentBrand)
        console.log("data", data)
        if (data && !error) {
            setRecentReleases(data)
            setErrorReleases(false)
        } else {
            setErrorReleases(true)
        }
        setLoadingReleases(false)
    }

    const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value
        setInputValue(value)
        debouncedChangeHandler(value, recentReleases, selectedTab)
    }

    // makes a call to refresh releases
    async function refreshReleases() {
        setLoadingReleases(true)
        const { data, error } = await getRefreshReleases({ brand: currentBrand, setRefreshingReleases })

        if (data && !error) {
            setRecentReleases(data)
        } else {
            setErrorReleases(true)
        }
        setLoadingReleases(false)
    }

    function renderSearchField() {

        let placeholderText = `Search for your ${selectedTab}`
        if (isPlaylistCampaign) {
            placeholderText = "Search or paste your playlist's URL"
        } else if (letUserSearchSpotify) {
            placeholderText = "Search for your song, album, or playlist"
        }

        const searchTextField = (
            <TextField
                className={classes.textField}
                variant="outlined"
                value={inputValue}
                onChange={handleChangeInput}
                placeholder={placeholderText}
                fullWidth
                InputProps={{
                    classes: {
                        root: classes.inputBorder,
                    },
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon />
                        </InputAdornment>
                    ),
                }}
            />)
        return searchTextField
    }


    function renderRecords() {
        if (records && !error && !loading && !loadingSearchResult) {

            // this is if a user searched with a specific URL
            if (searchResult && !isSearchBySongName) {
                const numberOfTracks = searchResult
                    ? Array.isArray(searchResult!.content_metadata.tracks)
                        ? searchResult!.content_metadata.tracks.length
                        : searchResult!.content_metadata.tracks
                    : 0;

                const followerCount = Boolean(typeof searchResult!.content_metadata?.followers !== "undefined" && searchResult!.content_metadata?.followers !== null)

                let isPlaylistTab = Boolean(selectedTab === PLAYLIST)

                // only show playlists in playlist tab if there is a search result
                let shownRecords = records
                if (isPlaylistTab) {
                    shownRecords = shownRecords.filter((o) => o.spotify_release_type === "Playlist")
                }

                return (
                    <Fragment>
                        {!letUserSearchSpotify && renderSearchField()}
                        <StyledContentList height="300px">
                            {resultType === selectedTab ? (
                                <Card onClick={() => handleChangeRecord(inputValue, searchResult!.content_metadata.spotify_id)}>
                                    <CardSubContainer>
                                        <ProjectImage src={searchResult?.content_metadata.thumbnail_url} />
                                        <CardInfo>
                                            <ProjectName>{searchResult?.content_metadata.name}</ProjectName>
                                            {searchResult!.content_metadata.primary_artist && (
                                                <Caption color={SystemColors.SECONDARY_TEXT_COLOR}>
                                                </Caption>
                                            )}
                                            {searchResult!.content_metadata.tracks && followerCount && (
                                                <Caption color={SystemColors.SECONDARY_TEXT_COLOR}>
                                                    {searchResult!.content_metadata.primary_artist} •  {millify(searchResult!.content_metadata?.followers!)} Follower{searchResult!.content_metadata?.followers === 1 ? "" : "s"} {numberOfTracks ? ` • ${numberOfTracks} Song${numberOfTracks === 1 ? "" : "s"}` : null}
                                                </Caption>
                                            )}
                                        </CardInfo>
                                    </CardSubContainer>
                                </Card>
                            ) : (
                                <>
                                    {shownRecords.map((record, index) =>
                                        <Card
                                            key={`${record.spotify_id}-${index}`}
                                            onClick={() => handleChangeRecord(inputValue, record.slug)}
                                        >
                                            <CardSubContainer>
                                                <ProjectImage src={record.thumbnail_url} />
                                                <CardInfo>
                                                    <ProjectName>{record.name} {record.explicit && <ExplicitIcon />}</ProjectName>
                                                    {selectedTab === SINGLE && (
                                                        <Caption color={SystemColors.SECONDARY_TEXT_COLOR}>from {record.parentName}</Caption>
                                                    )}
                                                    {selectedTab === PROJECT && (
                                                        <Caption color={SystemColors.SECONDARY_TEXT_COLOR}>{record?.spotify_release_note}</Caption>
                                                    )}
                                                </CardInfo>
                                            </CardSubContainer>
                                        </Card>

                                    )}
                                    {shownRecords.length === 0 && isPlaylistTab && (
                                        <Caption color={SystemColors.SECONDARY_TEXT_COLOR}>No playlists found for this link.</Caption>
                                    )}
                                </>)}
                        </StyledContentList>
                    </Fragment>
                )
            }

            // this is if a user searched with the name of a release
            if (searchResultsBySongName && isSearchBySongName) {
                const results = selectedTab === SINGLE
                    ? searchResultsBySongName.tracks
                    : selectedTab === PROJECT
                        ? searchResultsBySongName.albums
                        : searchResultsBySongName.playlists

                let isPlaylistTab = Boolean(selectedTab === PLAYLIST)
                // only show playlists in playlist tab if there is a search result
                let shownRecords = records
                if (isPlaylistTab) {
                    shownRecords = shownRecords.filter((o) => o.spotify_release_type === "Playlist")
                }
                return (
                    <StyledContentList height="300px">
                        {
                            results?.map((result) => {

                                function showSecondaryCaption() {
                                    let castedResult;
                                    if (selectedTab === SINGLE) {
                                        castedResult = result as Track
                                        let primary_artist = castedResult.artists
                                        return (
                                            <Caption color={SystemColors.SECONDARY_TEXT_COLOR}>
                                                {primary_artist} •  Released {dayjs(castedResult.release_date).format("MMMM DD, YYYY")}
                                            </Caption>
                                        )

                                    } else if (selectedTab === PROJECT) {
                                        castedResult = result as Album
                                    } else {
                                        castedResult = result as Playlist
                                        let primary_artist = castedResult.primary_artist
                                        let numberOfTracks = castedResult.track_count

                                        let trackCount = numberOfTracks && numberOfTracks > 0 ? `• ${numberOfTracks} Song${numberOfTracks === 1 ? "" : "s"}` : null
                                        return (
                                            <Caption color={SystemColors.SECONDARY_TEXT_COLOR}>
                                                {primary_artist} {trackCount} 
                                            </Caption>
                                        )

                                    }

                                }
                                return (
                                    <>
                                        <Card onClick={() => handleChangeRecord(result.spotify_url, result.id)} key={result.id}>
                                            <CardSubContainer>
                                                <ProjectImage src={result.thumbnail_url} />
                                                <CardInfo>
                                                    <ProjectName>{result.name}</ProjectName>
                                                    {showSecondaryCaption()}
                                                </CardInfo>
                                            </CardSubContainer>
                                        </Card>
                                    </>
                                )
                            })
                        }

                    </StyledContentList>
                )
            }

            let isPlaylistTab = Boolean(selectedTab === PLAYLIST)

            // only show playlists in playlist tab if there is a search result
            let shownRecords = records
            console.log("shownRecords", shownRecords)

            if (isPlaylistTab) {
                shownRecords = shownRecords.filter((o) => o.spotify_release_type === "Playlist")
            }

            console.log("shownRecords", shownRecords)

            return (
                <Fragment>
                    {!letUserSearchSpotify && renderSearchField()}
                    <StyledContentList height="300px">
                        {shownRecords.map((record, index) =>
                            <Card
                                key={`${record.spotify_id}-${index}`}
                                onClick={() => handleChangeRecord(inputValue, record.slug ? record.slug : record.spotify_id)}
                            >
                                <CardSubContainer>
                                    <ProjectImage src={record.thumbnail_url} />
                                    <CardInfo>
                                        <ProjectName>{record.name} {record.explicit && <ExplicitIcon />}</ProjectName>
                                        {selectedTab === SINGLE && (
                                            <Caption color={SystemColors.SECONDARY_TEXT_COLOR}>from "{record.parentName}"</Caption>
                                        )}
                                        {selectedTab === PROJECT && (
                                            <Caption color={SystemColors.SECONDARY_TEXT_COLOR}>"{record?.spotify_release_note}"</Caption>
                                        )}
                                        {selectedTab === PLAYLIST && (
                                            <Caption color={SystemColors.SECONDARY_TEXT_COLOR}>
                                                {record!.primary_artist}{record.tracks?.length ? (` • ${record.tracks?.length} Song${record.tracks?.length === 1 ? "" : "s"}`) : null}
                                            </Caption>
                                        )}
                                    </CardInfo>
                                </CardSubContainer>
                            </Card>
                        )}
                        {shownRecords.length === 0 && isPlaylistTab && (
                            <Caption color={SystemColors.SECONDARY_TEXT_COLOR}>Paste a Spotify playlist URL to automatically find it.</Caption>
                        )}
                    </StyledContentList>
                </Fragment>
            )
        }
    }

    const renderRefreshReleases = () => {
        return (
            <div className="px-5 lg:px-10 lg:pr-6 pb-4 justify-between flex flex-row items-center border-b">
                <p className="">Recent Releases</p>
                <button onClick={() => {
                    if (!refreshingReleases) refreshReleases()
                }} className="text-primary flex items-center"><img className="mr-1" alt="refresh icon" src={require('../../../../../../assets/images/ui/refresh.svg').default} /> Refresh</button>
            </div>
        )
    }

    return (
        <>
            {letUserRefreshReleases && !isPlaylistCampaign && renderRefreshReleases()}
            {letUserSearchSpotify && renderSearchField()}
            <InputContainer>
                <nav className="mt-1 flex border-b border-gray overflow-y-auto w-full">
                    {allowSingle && (<TabSelector
                        isActive={selectedTab === SINGLE}
                        onClick={handleChangeTab(SINGLE)}
                    >
                        Single
                    </TabSelector>)}
                    {allowProject && (
                        <TabSelector
                            isActive={selectedTab === PROJECT}
                            onClick={handleChangeTab(PROJECT)}
                        >
                            Project
                        </TabSelector>)}
                    {allowPlaylist && (
                        <TabSelector
                            isActive={selectedTab === PLAYLIST}
                            onClick={handleChangeTab(PLAYLIST)}
                        >
                            Playlist
                        </TabSelector>
                    )}
                </nav>
            </InputContainer>

            {renderRecords()}

            {loading && !error && (
                <StyledContentList>
                    <Loaderv2 />
                    {loadingReleases ? "Gathering records..." : `Gathering ${selectedTab} data...`}
                </StyledContentList>
            )}
            {error && !loading && (
                <ErrorBanner
                    showActionButton
                    errorText={`Something went wrong getting your ${errorReleases ? "recent releases" : "record details"}...`}
                    buttonLabel="Try Again"
                    onClickActionButton={handleTryAgain}
                />
            )}
        </>
    );
}
