import React, { useEffect, Dispatch, SetStateAction, useContext } from "react";
import { useLocation, } from "react-router-dom";
import { CurrentBrand, UserData } from "types/global";
import {
  DashIcon,
  MktgIcon,
  SettingsIcon,
  SupportIcon,
  WebsiteIcon,
  FansIcon,
  FansIconPath,
  DashboardIconPath,
  MarketingIconPath,
  WebsiteIconPath,
  SettingsIconPath,
  GiftIcon,
  GiftIconPath,
  SupportIconPath,
  LearnIcon,
  LearnIconPath
} from "../svg-icons"
import Axios from "helpers/Interceptor";
import useScreenQueryDimensions from "Hooks/useScreenQueryDimensions";
import MobileNavigation from "./MobileNavigation";
import DesktopNavigation from "./DesktopNavigation";
import { CAMPAIGN_WIZARD_ROUTES } from "../../constants";
import Intercom from "helpers/Intercom";
import { checkIfIsProTier } from "utils";
import { TeamPlanProvider } from "pages/post-auth/OrganizationPage/hooks/TeamMembersContext";
import { CurrentBrandContext } from "Hooks/CurrentBrandContext";

export function showIntercom() {
  // @ts-ignore
  Intercom.open();
}

export function showNewMessageIntercom() {
  // @ts-ignore
  Intercom.showNewMessage();
}

export interface NavigationItem {
  name: string;
  smallName?: string;
  iconComponent?: React.ComponentType<{ color?: string; height?: string; width?: string }>;
  onClick?: (e: any) => void;
  href?: string;
  alternateHrefs?: string[];
  iconPath?: React.ReactElement,
  hideOnMobile?: boolean;
  hideOnDesktop?: boolean;
  svgIconViewbox?: string;
  dropdownItems?: NavigationItem[]
}

export const NAV_LINKS: NavigationItem[] = [
  {
    name: "Dashboard",
    href: "/",
    alternateHrefs: ["/dashboard"],
    iconComponent: (props: { color?: string, height?: string, width?: string }) => <DashIcon {...props} />,
    iconPath: <DashboardIconPath />,
    svgIconViewbox: "0 0 18 18"
  },
  {
    name: "Marketing",
    href: "/marketing",
    alternateHrefs: [],
    iconComponent: (props: { color?: string, height?: string, width?: string }) => <MktgIcon {...props} />,
    iconPath: <MarketingIconPath />,
    svgIconViewbox: "0 0 18 18"
  },
  {
    name: "Website",
    href: "/website",
    alternateHrefs: [],
    iconComponent: (props: { color?: string, height?: string, width?: string }) => <WebsiteIcon {...props} />,
    iconPath: <WebsiteIconPath />,
    svgIconViewbox: "0 0 18 18"
  },
  {
    name: "Fans",
    href: "/fans",
    alternateHrefs: [],
    iconComponent: (props: { color?: string, height?: string, width?: string }) => <FansIcon {...props} />,
    iconPath: <FansIconPath />,
    svgIconViewbox: "0 0 18 18"
  },
  {
    hideOnMobile: true,
    name: "Learn",
    href: "/learn",
    alternateHrefs: [],
    iconComponent: (props: { color?: string, height?: string, width?: string }) => <LearnIcon {...props} />,
    iconPath: <LearnIconPath />,
    svgIconViewbox: "0 0 18 18",
    dropdownItems: [
      {
        name: "Videos",
        href: "/learn",
        alternateHrefs: [],
        iconComponent: (props: { color?: string, height?: string, width?: string }) => <FansIcon {...props} />,
        iconPath: <FansIconPath />,
        svgIconViewbox: "0 0 18 18",

      },
      {
        name: "Help Center",
        alternateHrefs: [],
        iconComponent: (props: { color?: string, height?: string, width?: string }) => <FansIcon {...props} />,
        iconPath: <FansIconPath />,
        svgIconViewbox: "0 0 18 18",
        onClick: (e: any) => {
          e.preventDefault()
          Intercom.showHelpCenter()
        },

      },
      {
        hideOnMobile: true,
        name: "Contact Support",
        iconComponent: (props: { color?: string, height?: string, width?: string }) => <SupportIcon {...props} />,
        iconPath: <SupportIconPath />,
        onClick: (e: any) => {
          e.preventDefault()
          Intercom.showMessages()
        },
        svgIconViewbox: "0 0 18 18"
      },
    ]
  },

  // only shown on mobile - additional options


  {
    hideOnMobile: true,
    hideOnDesktop: true,
    name: "Settings",
    href: '/settings',
    iconComponent: (props: { color?: string, height?: string, width?: string }) => <SettingsIcon {...props} />,
    iconPath: <SettingsIconPath />,
    svgIconViewbox: "0 0 22.54 22.59"
  },
  {
    hideOnMobile: true,
    hideOnDesktop: true,
    name: "Refer & Earn",
    smallName: "Refer",
    href: '/refer',
    alternateHrefs: [],
    iconComponent: (props: {}) => <GiftIcon {...props} />,
    iconPath: <GiftIconPath />,
    svgIconViewbox: "0 0 18 18"
  },
  // {
  //   hideOnMobile: true,
  //   hideOnDesktop: true,
  //   name: "Help Center",
  //   alternateHrefs: [],
  //   iconComponent: (props: { color?: string, height?: string, width?: string }) => <LearningIndicatorIcon {...props} />,
  //   iconPath: <LearningIndicatorIconPath />,
  //   svgIconViewbox: "0 0 18 18",
  //   onClick: (e: any) => {
  //     e.preventDefault()
  //     Intercom.showHelpCenter()
  //   },
  // },
  {
    hideOnMobile: true,
    hideOnDesktop: true,
    name: "Contact Support",
    iconComponent: (props: { color?: string, height?: string, width?: string }) => <SupportIcon {...props} />,
    iconPath: <SupportIconPath />,
    onClick: (e: any) => {
      e.preventDefault()
      showIntercom()
    },
    svgIconViewbox: "0 0 18 18"
  },
]


export interface NavProps {
  isHidden?: boolean
  loadedBrands: CurrentBrand[] | null
  user: UserData | null;
}

const Navbar = (props: NavProps) => {
  const location = useLocation();
  const { currentBrand, setCurrentBrand } = useContext(CurrentBrandContext) as { currentBrand: CurrentBrand, setCurrentBrand: Dispatch<SetStateAction<CurrentBrand>> }
  const { loadedBrands, isHidden = false, user } = props
  const brandId = currentBrand?.id

  const freeTrialOver = currentBrand?.freeTrialOver || false
  const { isMobile } = useScreenQueryDimensions();

  // validates if user is Pro
  const isProUser = checkIfIsProTier(currentBrand);


  const isAddArtist = location.pathname === '/add-artist'
  const notShowNavbar = (isAddArtist && isMobile) || isHidden
  const isCampaignWizard = CAMPAIGN_WIZARD_ROUTES.includes(location.pathname) || location.pathname.indexOf('/website/') > -1

  useEffect(() => {
    const id = brandId || Number(localStorage.getItem("selected-brand"))
    Axios.defaults.headers.common["brand-id"] = id
  }, [brandId])

  // if no nav bar shown, or if theres no current brand - return
  if (notShowNavbar || !currentBrand) return null

  var navLinks = [...NAV_LINKS]
  if (currentBrand && currentBrand.hideFansTab) {
    navLinks = navLinks.filter((navItem: NavigationItem) => navItem.name !== "Fans")
  }

  const hideMobileBar = location.pathname.indexOf('/campaigns/') > -1
  return (
    <>
      {!isCampaignWizard && <>
        <TeamPlanProvider>
          <DesktopNavigation
            user={user}
            currentBrand={currentBrand}
            navigationItems={navLinks}
            loadedBrands={loadedBrands}
            setCurrentBrand={setCurrentBrand} />
          {!hideMobileBar && (
            <MobileNavigation
              freeTrialOver={freeTrialOver}
              navigationItems={navLinks}
              isProUser={isProUser} />
          )}
        </TeamPlanProvider>
      </>}
    </>
  )
}

export default Navbar
